/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import ReactSelect, { createFilter } from "react-select";

class Select extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {};
        this.defaultFilterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: "any"
        };
    }
    render() {
        let currentValue = this.props.value;
        if ( !this.props.isMulti && currentValue && !currentValue.value ) {
            currentValue = this.props.options.find( tmp => tmp.value === this.props.value );
            if ( !currentValue || !currentValue.value ) {
                currentValue = null;
            }
        }
        if ( this.props.isMulti && this.props.options && currentValue ) {
            currentValue = currentValue.map( tmp => {
                if ( tmp.label ) {
                    return tmp;
                }
                let valueInProps = this.props.options.find( tmpOption => tmpOption.value === tmp.value );
                return valueInProps;
            } );
        }
        let className = "pw_select";
        if ( this.props.className ) {
            className = `pw_select ${ this.props.className }`;
        }
        return (
            <ReactSelect
                isMulti={ this.props.isMulti }
                isSearchable={ this.props.isSearchable }
                isDisabled={ this.props.isDisabled }
                isClearable={ this.props.isClearable }
                placeholder={ this.props.placeholder }
                className={ className }
                classNamePrefix="pw_select"
                filterOption={ createFilter( this.props.filterConfig ? this.props.filterConfig : this.defaultFilterConfig ) }
                noOptionsMessage={ () => <span>{ this.props.noOptionsMessage }</span> }
                value={ currentValue }
                onChange={ ( selectedOption ) => {
                    this.props.onChange( selectedOption );
                } }
                options={ this.props.options }
            />
        );
    }
}

export default Select;
