/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-danger */
import React from "react";
import { CardGroup, Spinner, Card, Container, Modal, Form, ButtonToolbar, Button, Alert, Badge, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavBarAccount from "./layout/NavBarAccount";
import Icon from "./widgets/Icon";
import * as SessionActions from "../actions/SessionActions";
import * as CheckoutActions from "../actions/CheckoutActions";
import * as DataUtils from "../utils/DataUtils";
import { getSaleBadges } from "../utils/SalesUtils";
import * as Pages from "../utils/Pages";
import WebContext from "../utils/WebContext";
import { loadUIContext } from "../actions/UIContextActions";

class GenericDashboard extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = {
            sendMessageModal: false, messageType: "question", messageProductId: "", messageItem: null, isEditing: false, isLoadingEditing: false, editing: {}
        };
        this.page = Pages.getPageByPath( props.match.path );
        this.isLoading = true;
        this.renderWebUserMessageSuccess = this.renderWebUserMessageSuccess.bind( this );
        this.renderWebUserMessageModal = this.renderWebUserMessageModal.bind( this );
        this.renderEditingModal = this.renderEditingModal.bind( this );
        this.renderLocationForm = this.renderLocationForm.bind( this );
        this.openSendMessage = this.openSendMessage.bind( this );
        this.openEditing = this.openEditing.bind( this );
        this.closeEditing = this.closeEditing.bind( this );
        this.saveEditing = this.saveEditing.bind( this );
        DataUtils.checkToken( props.history );
    }
    openEditing( modalType, item ) {
        this.setState( { isEditing: true, editing: Object.assign( {}, item, { modalType } ) } );
    }
    closeEditing() {
        this.setState( { isEditing: false, editing: {} } );
    }
    saveEditing() {
        if ( this.state.editing.modalType === "location" ) {
            this.setState( { isLoadingEditing: true } );
            this.props.locationUpdateRequest( this.state.editing.locationImageFile, Object.assign( {}, this.state.editing, { locationImageFile: null } ) )
                .then( () => this.props.sessionRehydrateFromServer() )
                .then( () => {
                    this.setState( { isLoadingEditing: false } );
                    this.closeEditing();
                } )
                .catch( () => {
                    this.setState( { isLoadingEditing: false } );
                } );
        }
    }
    openSendMessage( type, message, productId, item ) {
        this.setState( {
            sendMessageModal: true, messageType: type, messageProductId: productId, messageItem: item
        } );
        this.props.changeSessionAttr( "message", {
            type, text: message, productId, messageItem: item
        } );
    }
    renderLoading() {
        if ( !this.isLoading ) {
            return null;
        }
        return (
            <div className="pw_fullscreen_loading">
                <div className="pw_fullscreen_loading_content">
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
                    Descargando datos ...
                </div>
            </div>
        );
    }
    renderWebUserMessageSuccess() {
        if ( this.state.sendMessageModal ) {
            this.setState( { sendMessageModal: false } );
        }
        return (
            <Modal
                show={ this.props.pwSession.webUserMessageSent }
                onHide={ () => this.props.changeSessionAttr( "webUserMessageSent", false ) }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { this.state.messageType === "question" ? "Tu mensaje ha sido enviado" : null }
                        { this.state.messageType === "cancelation" ? "Hemos recibido tu solicitud de baja" : null }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="success">
                        <Alert.Heading>
                            { this.state.messageType === "question" ? "Hemos recibido tu mensaje y te responderemos a la brevedad" : null }
                            { this.state.messageType === "cancelation" ? "Hemos recibido tu solicitud y procederemos con la baja a la brevedad" : null }
                        </Alert.Heading>
                        <p>
                            { this.state.messageType === "question" ? "Nuestro tiempo medio de respuesta es de unas 4 horas, por lo que esperamos responderte a la brevedad." : null }
                            { this.state.messageType === "cancelation" ? "En caso de que haya sido un error puedes ponerte en contacto con nosotros dentro de las próximas 4 horas para evitar la baja." : null }
                        </p>
                    </Alert>
                </Modal.Body>
            </Modal>
        );
    }
    renderLocationForm() {
        if ( !this.state.editing.modalType === "location" ) {
            return null;
        }
        return (
            <Form>
                <Form.Group>
                    <Form.Label>Título:</Form.Label>
                    <Form.Control
                        value={ this.state.editing.locationTitle }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationTitle: e.target.value.substring( 0, 250 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Imagen principal:</Form.Label>
                    <Form.Control type="file" name="locationImage" label="Subir Imagen" onChange={ ( e ) => this.setState( { editing: Object.assign( {}, this.state.editing, { locationImageFile: e.target.files[ 0 ] } ) } ) } />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Texto corto (Español):</Form.Label>
                    <Form.Control
                        value={ this.state.editing.locationShortText }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationShortText: e.target.value.substring( 0, 3000 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Texto corto (English):</Form.Label>
                    <Form.Control
                        value={ this.state.editing.locationShortTextEn }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationShortTextEn: e.target.value.substring( 0, 3000 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Teléfono:</Form.Label>
                    <Form.Control
                        value={ this.state.editing.locationPhone }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationPhone: e.target.value.substring( 0, 50 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Dirección:</Form.Label>
                    <Form.Control
                        value={ this.state.editing.locationAddress }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationAddress: e.target.value.substring( 0, 250 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Link de sitio web:</Form.Label>
                    <Form.Text className="text-muted">
                        Ejemplo: https://www.mallorcagayspot.com
                    </Form.Text>
                    <Form.Control
                        value={ this.state.editing.locationWebsite }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationWebsite: e.target.value.substring( 0, 500 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Link de Instagram:</Form.Label>
                    <Form.Text className="text-muted">
                        Ejemplo: https://instagram.com/mallorcagayspot
                    </Form.Text>
                    <Form.Control
                        value={ this.state.editing.locationInstagramUrl }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationInstagramUrl: e.target.value.substring( 0, 500 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Link de Facebook:</Form.Label>
                    <Form.Text className="text-muted">
                        Ejemplo: https://facebook.com/mallorcagayspot
                    </Form.Text>
                    <Form.Control
                        value={ this.state.editing.locationFacebookUrl }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationFacebookUrl: e.target.value.substring( 0, 500 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Link de Twitter:</Form.Label>
                    <Form.Text className="text-muted">
                        Ejemplo: https://twitter.com/mallorcagayspot
                    </Form.Text>
                    <Form.Control
                        value={ this.state.editing.locationTwitterUrl }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationTwitterUrl: e.target.value.substring( 0, 500 ) } ) } );
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Link de WhatsApp:</Form.Label>
                    <Form.Text className="text-muted">
                        Ejemplo: https://api.whatsapp.com/send/?phone=34111111111
                    </Form.Text>
                    <Form.Control
                        value={ this.state.editing.locationWhatsAppUrl }
                        onChange={ ( e ) => {
                            this.setState( { editing: Object.assign( {}, this.state.editing, { locationWhatsAppUrl: e.target.value.substring( 0, 500 ) } ) } );
                        } }
                    />
                </Form.Group>
            </Form>
        );
    }
    renderEditingModal() {
        if ( !this.state.isEditing ) {
            return null;
        }
        return (
            <Modal
                show={ this.state.isEditing }
                onHide={ () => this.closeEditing() }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { this.state.editing.modalType === "location" ? "Editar lugar" : null }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.renderLocationForm() }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button variant="primary" onClick={ this.saveEditing } disabled={ this.state.isLoadingEditing }>
                            { this.state.isLoadingEditing ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : null }
                            Guardar cambios
                        </Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        );
    }
    renderWebUserMessageModal() {
        if ( this.props.pwSession.webUserMessageSent ) {
            return this.renderWebUserMessageSuccess();
        }
        const lang = this.context.language;
        return (
            <Modal
                show={ this.state.sendMessageModal }
                onHide={ () => this.setState( { sendMessageModal: false } ) }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { this.state.messageType === "question" ? "Enviar mensaje" : null }
                        { this.state.messageType === "cancelation" ? "¿Seguro que quieres darte de baja?" : null }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.props.pwSession.webUserMessageError ?
                        <Alert variant="danger">
                            { Pages.text( lang, this.props.pwSession.webUserMessageError ) }
                        </Alert> : null }
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                { this.state.messageType === "cancelation" ? "Cuéntanos el motivo por el que quieres darte de baja:" : null }
                                { this.state.messageType === "question" ? "Comentarios:" : null }
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={ 3 }
                                value={ this.props.pwSession.message.text }
                                onChange={ ( e ) => {
                                    this.props.changeSessionAttr( "message", {
                                        type: this.state.messageType, message: e.target.value, productId: this.state.messageProductId, messageItem: this.state.messageItem
                                    } );
                                } }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button variant={ this.state.messageType === "cancelation" ? "danger" : "primary" } onClick={ this.props.sendWebUserMessageRequest } disabled={ this.props.pwSession.isSendingWebUserMessage }>
                            { this.props.pwSession.isSendingWebUserMessage ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : null }
                            { this.state.messageType === "cancelation" ? "Sí, deseo solicitar la baja" : "Enviar mensaje" }
                        </Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        );
    }
    render() {
        if ( !this.props.UIContext.loaded || this.props.pwSession.isRehydrating ) {
            return this.renderLoading();
        }
        const lang = this.context.language;
        return (
            <div id="pw_dashboard">
                <NavBarAccount />
                <Container className="pw_dashboard_container">
                    <CardGroup>
                        { Pages.company.dashboardModules.includes( "welcome" ) ?
                            <Card className="pw-dashboard-card pw-shadow">
                                <Card.Header>{ Pages.text( lang, "dashboard.bienvenida.title" ) }</Card.Header>
                                <Card.Body>{ Pages.text( lang, "dashboard.bienvenida.text" ) }</Card.Body>
                            </Card> : null }
                        { Pages.company.dashboardModules.includes( "questions" ) ?
                            <Card className="pw-dashboard-card pw-shadow">
                                <Card.Header>{ Pages.text( lang, "dashboard.questions.title" ) }</Card.Header>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={ { __html: Pages.text( lang, "dashboard.questions.text", { companyEmail: `<a href="mailto:${ Pages.company.contactEmail }">${ Pages.company.contactEmail }</a>` } ) } } />
                                    <br />
                                    <p><Button variant="outline-secondary" size="sm" onClick={ () => this.openSendMessage( "question", "", null ) }>Enviar mensaje</Button></p>
                                </Card.Body>
                            </Card> : null }
                        { Pages.company.dashboardModules.includes( "myplan" ) ?
                            <Card className="pw-dashboard-card pw-shadow">
                                <Card.Header>{ Pages.text( lang, "dashboard.myplan.title" ) }</Card.Header>
                                <Card.Body>
                                    { this.props.pwSession.checkout.products.length === 0 ? Pages.text( lang, "dashboard.myplan.text.noproducts" ) : Pages.text( lang, "dashboard.myplan.text" ) }
                                    { this.props.pwSession.checkout.products.length === 0 ?
                                        <p>
                                            <br />
                                            <p><Button variant="outline-secondary" size="sm" href={ Pages.getPage( "checkout-plan" ).relativeUrl }>Contratar plan</Button></p>
                                        </p> : null }
                                    { this.props.pwSession.checkout.products.map( ( product ) => {
                                        const productDetails = Pages.stripe.products.find( tmpProductDetails => tmpProductDetails.id === product.webUserProductToken );
                                        const productVariant = productDetails.plans.find( tmpProductVariant => tmpProductVariant.id === product.webUserProductVariant );
                                        return (
                                            <div>
                                                <div className="d-flex align-items-start">
                                                    <img
                                                        width={ 64 }
                                                        height={ 64 }
                                                        className="mr-3"
                                                        src={ productDetails.imageSquare }
                                                        alt={ this.page.seoDefaultAlt }
                                                    />
                                                    <div>
                                                        <h5>{ productDetails.name } / { productVariant.name}</h5>
                                                        <p>{ productVariant.description }</p>
                                                        <p><Button variant="outline-secondary" size="sm" onClick={ () => this.openSendMessage( "cancelation", "", productDetails.id ) }>Solicitar baja</Button></p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    } ) }
                                </Card.Body>
                            </Card> : null }
                        { Pages.company.dashboardModules.includes( "locations" ) ?
                            <Card className="pw-dashboard-card pw-shadow">
                                <Card.Header>{ Pages.text( lang, "dashboard.locations.title" ) }</Card.Header>
                                <Card.Body>
                                    { this.props.pwSession.locations.items.length === 0 ? Pages.text( lang, "dashboard.locations.text.nolocations" ) : Pages.text( lang, "dashboard.locations.text" ) }
                                    { this.props.pwSession.locations.items.map( ( location ) => {
                                        if ( !location ) {
                                            return null;
                                        }
                                        return (
                                            <div>
                                                <div className="d-flex align-items-start">
                                                    <img
                                                        width={ 64 }
                                                        height={ 64 }
                                                        className="mr-3"
                                                        src={ DataUtils.getPublicImageUrl( location.locationImage ) }
                                                        alt={ this.page.seoDefaultAlt }
                                                    />
                                                    <div>
                                                        <h5>{ location.locationTitle }</h5>
                                                        <p>{ location.locationShortText }</p>
                                                        <p><Button variant="outline-primary" size="sm" onClick={ () => this.openEditing( "location", location ) }>Editar</Button></p>
                                                        <p><Button variant="outline-danger" size="sm" onClick={ () => this.openSendMessage( "cancelation", "", null, location ) }>Solicitar baja</Button></p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    } ) }
                                </Card.Body>
                            </Card> : null }
                    </CardGroup>
                    <CardGroup>
                        { Pages.company.dashboardModules.includes( "sales" ) ?
                            <Card className="pw-dashboard-card pw-shadow pw-dashboard-card-sales">
                                <Card.Header>Mis compras recientes</Card.Header>
                                <Card.Body>
                                    { this.props.pwSession.sales.items.length === 0 ? Pages.text( lang, "dashboard.sales.text.noresults" ) : null }
                                    { this.props.pwSession.sales.items.map( ( sale ) => {
                                        if ( !sale ) {
                                            return null;
                                        }
                                        let showSaleLink = true;
                                        if ( sale.saleOutsourced && sale.saleOutsourcedStatus === "pending" ) {
                                            showSaleLink = false;
                                        }
                                        return (
                                            <div className="pw-dashboard-small-card">
                                                <h5>{ getSaleBadges( sale, "web" ).map( badgeInfo => <span><Badge size="sm" bg={ badgeInfo.variant } text={ badgeInfo.textColor }>{ badgeInfo.text }</Badge> </span> ) } { DataUtils.formatDateTimeNiceShort( sale.saleDatetimeCreation ) }</h5>
                                                <p>
                                                    { sale.saleItems.map( ( item, itemIndex ) => <span> { !item.saleItemActive ? <Badge size="sm" bg="danger" text="white">Cancelado</Badge> : null } { item.saleItemQuantity } x <strong>{ item.saleItemTitle }</strong>{ itemIndex < sale.saleItems.length - 1 ? ", " : null }</span> ) }
                                                </p>
                                                <ButtonGroup>
                                                    { showSaleLink ? <Button size="sm" variant="pw-primary" href={ `${ Pages.company.baseURL }${ Pages.getPage( "salelink" ).relativeUrl }/${ sale.saleId }` }>Ver detalle <Icon name="nav-arrow-right" /></Button> : null }
                                                </ButtonGroup>
                                            </div>
                                        );
                                    } ) }
                                </Card.Body>
                            </Card> : null }
                    </CardGroup>
                    { this.renderWebUserMessageModal() }
                    { this.renderEditingModal() }
                </Container>
            </div>
        );
    }
}

GenericDashboard.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToProps = Object.assign( {}, SessionActions, CheckoutActions, loadUIContext );
export default connect( mapStateToProps, mapDispatchToProps )( withRouter( GenericDashboard ) );
