/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Helmet } from "react-helmet";
import { Container, Button, Row, Col, Form, Modal, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import NavBarAccount from "./layout/NavBarAccount";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import { shopStateData } from "../actions/ShopActions";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import * as DataValidations from "../utils/DataValidations";
import WebContext from "../utils/WebContext";

const recaptchaRef = React.createRef();

class ShopCartPurchase extends React.Component {
    constructor( props ) {
        super( props );
        this.page = Pages.getPage( "shop-purchase" );
        this.urlParams = {};
        const tmpParams = new URLSearchParams( props.location.search );
        const tmpParamsKeys = Array.from( tmpParams.keys() );
        this.useTransactionCodeOnly = false;
        this.useTransactionCodeOnlyDone = false;
        for ( let i = 0; i < tmpParamsKeys.length; i += 1 ) {
            this.urlParams[ tmpParamsKeys[ i ] ] = tmpParams.get( tmpParamsKeys[ i ] );
        }
        this.vatPercentages = [];
        this.renderPurchaseStatus = this.renderPurchaseStatus.bind( this );
    }
    componentDidMount() {
        this.props.loadUIContext();
        if ( this.urlParams.code ) {
            this.props.changeShopCartAttr( "purchaseCode", this.urlParams.code );
        }
        if ( this.urlParams.email ) {
            this.props.changeShopCartAttr( "purchaseEmail", this.urlParams.email );
        }
        if ( this.urlParams.provider ) {
            this.props.changeShopCartAttr( "redirectProvider", this.urlParams.provider );
            if ( this.urlParams.provider ) {
                if ( [ "pagantis", "stripe", "pw" ].includes( this.urlParams.provider ) ) {
                    this.useTransactionCodeOnly = true;
                }
            }
        }
    }
    validateForm() {
        let result = true;
        const {
            purchaseEmail, reCaptchaTokenNewClient, purchaseCode, loggedIn
        } = this.props.pwSession.shopAttributes;
        if ( ( purchaseEmail === "" && !this.useTransactionCodeOnly ) || purchaseCode === "" ) {
            this.props.changeShopCartAttr( "purchaseErrorMessage", "shop.validation.empty" );
            result = false;
        }
        if ( result && !this.useTransactionCodeOnly && !DataValidations.isValidEmail( purchaseEmail ) ) {
            this.props.changeShopCartAttr( "purchaseErrorMessage", "shop.validation.invalidemail" );
            result = false;
        }
        if ( result && ( !this.useTransactionCodeOnly && !loggedIn ) && !reCaptchaTokenNewClient ) {
            this.props.changeShopCartAttr( "purchaseErrorMessage", "shop.validation.recaptcha" );
            result = false;
        }
        return result;
    }
    renderCenteredModal() {
        if ( this.props.pwSession.shopAttributes.purchaseErrorMessage === "" ) {
            return null;
        }
        return (
            <Modal
                show
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                variant="danger"
                className="shop_cart_checkout_modal"
            >
                <Modal.Body>
                    { Pages.text( this.context.language, this.props.pwSession.shopAttributes.purchaseErrorMessage ) }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={ () => this.props.changeShopCartAttr( "purchaseErrorMessage", "" ) }>{ Pages.text( this.context.language, "shop.validation.close" ) }</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderItems() {
        if ( !this.props.pwSession.shopAttributes.purchaseCart.cartItems ) {
            return null;
        }
        this.vatPercentages = [];
        return this.props.pwSession.shopAttributes.purchaseCart.cartItems.map( cartItem => {
            const vatPercentageIndex = this.vatPercentages.findIndex( vat => vat.percentage === cartItem.cartItemVatPercentage );
            const vatTotalAmount = DataUtils.getVatIncludedInteger( cartItem.cartItemTotalAmount, cartItem.cartItemVatPercentage );
            if ( vatPercentageIndex > -1 ) {
                this.vatPercentages[ vatPercentageIndex ].total += vatTotalAmount;
            } else {
                this.vatPercentages.push( { percentage: cartItem.cartItemVatPercentage, total: vatTotalAmount } );
            }
            return (
                <div className="shop_cart_item">
                    <h5>{ cartItem.cartItemQuantity } x { cartItem.cartItemTitle }</h5>
                    <p className="shop_cart_item_codigo">{ Pages.text( this.context.language, "shop.item.code" ) }: { cartItem.cartItemCode }</p>
                    <div className="d-flex align-items-start">
                        <img width={ 128 } className="mr-3" src={ DataUtils.getPublicImageUrl( cartItem.cartItemImageTop ) } alt="Shopping Cart Item ..." />
                        <div>
                            <div className="shop_cart_item_panel">
                                <p className="shop_cart_item_label">{ Pages.text( this.context.language, "shop.item.unitprice" ) }:</p>
                                <span className="shop_cart_item_value">{ DataUtils.formatIntegerPrice( cartItem.cartItemPriceCurrency, cartItem.cartItemPriceAmount )}</span>
                            </div>
                            { cartItem.cartItemCourseClass ?
                                <div className="shop_cart_item_panel">
                                    <p className="shop_cart_item_label">Fecha de inicio del curso:</p>
                                    <span className="shop_cart_item_value">{ DataUtils.formatDateNice( cartItem.cartItemCourseClass.courseClassDatetimeStart ) }</span>
                                </div> : null }
                            <div className="shop_cart_item_panel">
                                <p className="shop_cart_item_label">{ Pages.text( this.context.language, "shop.item.totalprice" ) }:</p>
                                <span className="shop_cart_item_value">{ DataUtils.formatIntegerPrice( cartItem.cartItemPriceCurrency, ( cartItem.cartItemTotalAmount ) )}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } );
    }
    renderPurchaseStatus() {
        if ( this.props.pwSession.shopAttributes.purchaseCart && this.props.pwSession.shopAttributes.purchaseCart.cartStatus === "started" && this.props.pwSession.shopAttributes.purchaseCart.cartPaymentMethod === "banktransfer" ) {
            return (
                <div className="shop_cart_checkout_details">
                    <h2 id="checkout_customer_font">{ Pages.text( this.context.language, "shop.purchase.details.statustitle" ) }:</h2>
                    <Card bg="info" text="white">
                        <Card.Header>{ Pages.text( this.context.language, "shop.purchase.details.status.banktransfer.pending.title" ) }</Card.Header>
                        <Card.Body>
                            <Card.Title>{ Pages.text( this.context.language, "shop.purchase.details.status.banktransfer.pending.text" ) }</Card.Title>
                            <b>Banco: { this.props.shop.paymentMethods.bankTransfer.mainBankText }</b><br />
                            <b>IBAN: { this.props.shop.paymentMethods.bankTransfer.mainBankIBAN }</b><br />
                            <b>Total: { DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.pwSession.shopAttributes.purchaseCart.cartTotalAmount )}</b><br />
                            <p>{ Pages.text( this.context.language, "shop.purchase.details.status.banktransfer.pending.concept" ) }: <b>{ this.props.pwSession.shopAttributes.purchaseCart.cartEmail }</b></p>
                            <p><i>{ Pages.text( this.context.language, "shop.purchase.details.status.banktransfer.pending.footertext" ) }</i></p>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
        if ( this.props.pwSession.shopAttributes.purchaseCart && this.props.pwSession.shopAttributes.purchaseCart.cartStatus === "started" && this.props.pwSession.shopAttributes.purchaseCart.cartPaymentMethod === "pagantis" ) {
            return (
                <div className="shop_cart_checkout_details">
                    <h2 id="checkout_customer_font">{ Pages.text( this.context.language, "shop.purchase.details.statustitle" ) }:</h2>
                    <Card bg="info" text="white">
                        <Card.Header>{ Pages.text( this.context.language, "shop.purchase.details.status.pagantis.started.title" ) }</Card.Header>
                        <Card.Body>
                            <Card.Title>{ Pages.text( this.context.language, "shop.purchase.details.status.pagantis.started.text" ) }</Card.Title>
                            <p><i>{ Pages.text( this.context.language, "shop.purchase.details.status.pagantis.started.footertext" ) }</i></p>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
        if ( this.props.pwSession.shopAttributes.purchaseCart && this.props.pwSession.shopAttributes.purchaseCart.cartStatus === "confirmed" ) {
            return (
                <div className="shop_cart_checkout_details">
                    <h2 id="checkout_customer_font">{ Pages.text( this.context.language, "shop.purchase.details.statustitle" ) }:</h2>
                    <Card bg="success" text="white">
                        <Card.Header>{ Pages.text( this.context.language, "shop.purchase.details.status.confirmed.title" ) }</Card.Header>
                        <Card.Body>
                            <Card.Title>{ Pages.text( this.context.language, "shop.purchase.details.status.confirmed.text" ) }</Card.Title>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
        if ( this.props.pwSession.shopAttributes.purchaseCart && this.props.pwSession.shopAttributes.purchaseCart.cartStatus === "canceled" ) {
            return (
                <div className="shop_cart_checkout_details">
                    <h2 id="checkout_customer_font">{ Pages.text( this.context.language, "shop.purchase.details.statustitle" ) }:</h2>
                    <Card bg="secondary" text="white">
                        <Card.Header>{ Pages.text( this.context.language, "shop.purchase.details.status.canceled.title" ) }</Card.Header>
                        <Card.Body>
                            <Card.Title>{ Pages.text( this.context.language, "shop.purchase.details.status.canceled.text" ) }</Card.Title>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
        return null;
    }
    render() {
        const lang = this.context.language;
        if ( ( this.useTransactionCodeOnly || this.props.pwSession.loggedIn ) && !this.useTransactionCodeOnlyDone && ( this.props.pwSession.shopAttributes.redirectProvider === "pw" || this.props.pwSession.shopAttributes.redirectProvider === "pagantis" || this.props.pwSession.loggedIn ) && this.props.pwSession.shopAttributes.purchaseCode !== "" ) {
            this.props.purchaseDetailsRequest();
            if ( this.useTransactionCodeOnly ) {
                this.props.shopRemoveCart();
            }
            this.useTransactionCodeOnlyDone = true;
            this.useTransactionCodeOnly = true;
        }
        return (
            <div id="shop-purchase" className={ Pages.getPageClassNames( this.page ) }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                { this.props.pwSession.loggedIn ? <NavBarAccount /> : null }
                <Container>
                    <h2 className="pw_section_title">{ Pages.text( this.context.language, "shop.purchase.details.title" ) }</h2>
                    { !this.useTransactionCodeOnly ?
                        <div>
                            <h3 className="pw_section_subtitle">{ Pages.text( this.context.language, "shop.purchase.details.subtitle" ) }</h3>
                            <Card className="pw_shop_purchase_form">
                                <Row>
                                    <Col md="6">
                                        <Form.Label>{ Pages.text( this.context.language, "shop.purchase.details.field.code.label" ) }:</Form.Label>
                                        <Form.Control placeholder="Código de compra" value={ this.props.pwSession.shopAttributes.purchaseCode } onChange={ ( e ) => this.props.changeShopCartAttr( "purchaseCode", e.target.value ) } />
                                    </Col>
                                    <Col md="6">
                                        <Form.Label>{ Pages.text( this.context.language, "shop.purchase.details.field.email.label" ) }:</Form.Label>
                                        <Form.Control placeholder={ Pages.text( this.context.language, "shop.checkout.email" ) } value={ this.props.pwSession.shopAttributes.purchaseEmail } onChange={ ( e ) => this.props.changeShopCartAttr( "purchaseEmail", e.target.value ) } type="email" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ReCAPTCHA
                                            ref={ recaptchaRef }
                                            sitekey={ Pages.config.reCaptchaSiteKey }
                                            style={ { margin: "30px auto 20px" } }
                                            onChange={ ( token ) => this.props.changeShopCartAttr( "reCaptchaTokenNewClient", token ) }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            variant="pw-primary"
                                            onClick={ () => {
                                                if ( this.validateForm() ) {
                                                    this.props.purchaseDetailsRequest();
                                                }
                                            } }
                                        >
                                            { Pages.text( this.context.language, "shop.purchase.details.button" ) }
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </div> : []
                    }
                    { this.props.pwSession.shopAttributes.purchaseCart ?
                        <div>
                            { this.renderPurchaseStatus() }
                            <div className="shop_cart_checkout_customer">
                                <h2 id="checkout_customer_font">{ Pages.text( this.context.language, "shop.purchase.details.customertitle" ) }:</h2>
                                <Row>
                                    <Col md="6">
                                        { Pages.text( this.context.language, "shop.checkout.fullname" ) }: { this.props.pwSession.shopAttributes.purchaseCart.cartFullName }
                                    </Col>
                                    <Col md="6">
                                        { Pages.text( this.context.language, "shop.checkout.email" ) }: { this.props.pwSession.shopAttributes.purchaseCart.cartEmail }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        { Pages.text( this.context.language, "shop.checkout.mobile" ) }: { this.props.pwSession.shopAttributes.purchaseCart.cartMobile }
                                    </Col>
                                    <Col md="6">
                                        { Pages.text( this.context.language, "shop.checkout.address" ) }: { this.props.pwSession.shopAttributes.purchaseCart.cartAddress }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>{ Pages.text( this.context.language, "shop.checkout.comments" ) }</p>
                                        <p>{ this.props.pwSession.shopAttributes.purchaseCart.cartComments }</p>
                                    </Col>
                                </Row>
                            </div>
                            <div className="shop_cart_checkout_details">
                                <h2>{ Pages.text( this.context.language, "shop.purchase.details.itemstitle" ) }:</h2>
                                <div className="shop_cart_items_container">
                                    { this.renderItems() }
                                </div>
                                <div className="shop_cart_totals_bottom">
                                    <div className="shop_cart_totals_right_panel">
                                        <Container>
                                            <Row>
                                                <Col>{ Pages.text( this.context.language, "shop.checkout.reviewpurchase.subtotal" ) }</Col>
                                                <Col><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.pwSession.shopAttributes.purchaseCart.cartTotalAmount ) }</b></Col>
                                            </Row>
                                            { this.vatPercentages.map( vat => (
                                                <Row>
                                                    <Col>{ this.props.shop.vatName} ({ DataUtils.formatIntegerPercentage( vat.percentage ) } { Pages.text( this.context.language, "shop.checkout.included" ) })</Col>
                                                    <Col><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, vat.total ) }</b></Col>
                                                </Row>
                                            ) ) }

                                        </Container>
                                    </div>
                                    <hr />
                                    <div className="shop_cart_totals_right_panel">
                                        <Container>
                                            <Row>
                                                <Col><b className="shop_cart_total_price">{ Pages.text( this.context.language, "shop.checkout.reviewpurchase.total" ) }:</b></Col>
                                                <Col><b className="shop_cart_total_price">{ DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.pwSession.shopAttributes.purchaseCart.cartTotalAmount )}</b></Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </div>
                            <div className="shop_cart_checkout_payment_method">
                                <h2>{ Pages.text( this.context.language, "shop.purchase.details.paymentmethod" ) }</h2>
                                <div className="shop_cart_totals_bottom">
                                    <h5>{ Pages.text( this.context.language, "shop.purchase.details.paymentmethod" ) }: { Pages.text( this.context.language, `shop.checkout.${ this.props.pwSession.shopAttributes.purchaseCart.cartPaymentMethod }` ) }</h5>
                                    <hr />
                                    <div className="shop_cart_totals_right_panel">
                                        <Container>
                                            <Row>
                                                <Col><b className="shop_cart_total_price">{ Pages.text( this.context.language, "shop.checkout.totalfinal" ) }:</b></Col>
                                                <Col><b className="shop_cart_total_price">{ DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.pwSession.shopAttributes.purchaseCart.cartTotalAmount )}{ this.props.pwSession.shopAttributes.purchaseCart.cartPaymentMethod === "pagantis" ? "+ Intereses" : null }</b></Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null }
                    { this.renderCenteredModal() }
                </Container>
            </div>
        );
    }
}
ShopCartPurchase.serverFetch = shopStateData;
ShopCartPurchase.serverFetchType = { type: "data", module: "shop" };
ShopCartPurchase.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    shop: state.shop
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( ShopCartPurchase ) );
