/* eslint-disable no-loop-func */
/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { cartBookingResourcesToBookingResources, serviceResourcesAvailability, serviceResourcesToBookingResources } from "./CalendarUtils";
import { formatDateTimeNiceShort, isPastDateTime, now, dateTimeSubtract, stringReplacements, formatIntegerPriceTwoDecimals, getVatIncludedInteger, getVatExcludedInteger, formatDateNiceShort, formatIntegerPrice, formatDateTimeString, dateTimeAdd, today, randomString, uuid, getVatValues, sortArray, getPercentageAmount, formatInternationalMobile } from "./DataUtils";

// exchangeableItems are bono items that can be exchangeable for all services
export const isExchangeableItemsEnabled = () => false;
// calculates discount
export const getDiscountTotals = ( priceAmount, discountType, discountAmount ) => {
    let discountTotalAmount = 0;
    let dividePercentageBy = 10000;
    let discountPercentage = discountAmount;
    if ( priceAmount && discountAmount ) {
        switch ( discountType ) {
            case "%":
                if ( discountPercentage < 100 ) {
                    dividePercentageBy = 100;
                }
                if ( discountPercentage > 10000 ) {
                    discountPercentage = 10000;
                }
                discountTotalAmount = Math.round( priceAmount * ( discountPercentage / dividePercentageBy ) );
                break;
            case "€":
                discountTotalAmount = discountAmount;
                break;
            default:
                break;
        }
    }
    return { totalAfterDiscount: priceAmount - discountTotalAmount, discountTotalAmount };
};
export const getBookingTotalAmountForFee = ( sale, saleItem, saleItemBooking ) => {
    let discountTotals = 0;
    let bookingTotalAmount = saleItemBooking.bookingTotalAmount;
    // percentage or quantity 1 on sale item get directly discounted
    if ( saleItem.saleItemDiscountAmount && ( saleItem.saleItemQuantity === 1 || saleItem.saleItemDiscountType === "%" ) ) {
        discountTotals = getDiscountTotals( bookingTotalAmount, saleItem.saleItemDiscountType, saleItem.saleItemDiscountAmount );
    } else if ( sale.saleDiscountAmount && sale.saleDiscountType === "%" ) {
        discountTotals = getDiscountTotals( bookingTotalAmount, sale.saleDiscountType, sale.saleDiscountAmount );
    }
    if ( discountTotals ) {
        bookingTotalAmount = discountTotals.totalAfterDiscount;
    }
    // minus expenses
    bookingTotalAmount -= saleItemBooking.bookingTotalAmountExpenses;
    return bookingTotalAmount;
};
export const getItemPriceAndDiscountValues = ( argUnitPriceAmount, argUnitPriceVatIncluded, argUnitVatPercentageAmount, argQuantity, argDiscountType, argDiscountAmount ) => {
    let result = {};
    let unitPrice = 0;// unit price vat included
    let unitPriceNoVat = 0;// unit price no vat
    let unitVatAmount = 0;// unit vat amount

    let price = 0;// unitPrice * quantity
    let priceNoVat = 0;// unitPriceNoVat * quantity
    let vatTotalAmount = 0;// unitVatAmount * quantity

    let unitDiscountTotals = null;
    let unitDiscountAmount = 0;
    let unitSellPriceAmount = 0;
    let totalDiscountAmount = 0;
    if ( argUnitPriceVatIncluded ) {
        unitPrice = argUnitPriceAmount;
        unitSellPriceAmount = argUnitPriceAmount;
        unitVatAmount = getVatIncludedInteger( unitPrice, argUnitVatPercentageAmount );
        unitPriceNoVat = unitPrice - unitVatAmount;
    } else {
        unitPriceNoVat = argUnitPriceAmount;
        unitVatAmount = getVatExcludedInteger( unitPriceNoVat, argUnitVatPercentageAmount );
        unitPrice = unitPriceNoVat + unitVatAmount;
        unitSellPriceAmount = unitPriceNoVat + unitVatAmount;
    }
    price = unitPrice * argQuantity;
    // trying to fix rounding issues when multiplying already rounded vats
    vatTotalAmount = getVatIncludedInteger( price, argUnitVatPercentageAmount );
    priceNoVat = price - vatTotalAmount;
    result.totalAmountBeforeDiscount = price;

    if ( argDiscountAmount ) {
        if ( argDiscountType === "%" ) {
            unitDiscountTotals = getDiscountTotals( unitSellPriceAmount, argDiscountType, argDiscountAmount );
            unitVatAmount = getVatIncludedInteger( unitDiscountTotals.totalAfterDiscount, argUnitVatPercentageAmount );
            unitPrice = unitDiscountTotals.totalAfterDiscount;
            // we need to reduce the vat amount in discount here
            unitDiscountAmount = unitDiscountTotals.discountTotalAmount - getVatIncludedInteger( unitDiscountTotals.discountTotalAmount, argUnitVatPercentageAmount );
        } else {
            unitDiscountTotals = getDiscountTotals( unitSellPriceAmount, argDiscountType, argDiscountAmount );
            unitVatAmount = getVatIncludedInteger( unitDiscountTotals.totalAfterDiscount, argUnitVatPercentageAmount );
            unitPrice = unitDiscountTotals.totalAfterDiscount;
            unitDiscountAmount = unitDiscountTotals.discountTotalAmount - getVatIncludedInteger( unitDiscountTotals.discountTotalAmount, argUnitVatPercentageAmount );
        }
        price = unitPrice * argQuantity;
        // trying to fix rounding issues when multiplying already rounded vats
        vatTotalAmount = getVatIncludedInteger( price, argUnitVatPercentageAmount );
        totalDiscountAmount = unitDiscountAmount * argQuantity;
    }

    result.totalAmount = price;
    result.totalVatAmount = vatTotalAmount;
    result.totalAmountNoVat = priceNoVat;
    result.totalDiscountAmount = totalDiscountAmount;
    result.unitPriceNoVatAmount = unitPriceNoVat;
    result.unitPriceAmount = unitPrice;
    result.unitVatAmount = unitVatAmount;
    result.unitSellPriceAmount = unitSellPriceAmount;
    result.unitDiscountAmount = unitDiscountAmount;

    return result;
};
export const applySaleItemOverrides = ( sale, saleItem ) => {
    const newSaleItem = Object.assign( {}, saleItem );
    let saleFixedDiscountAppliedOnId = null;
    let tmpSaleFixedDiscountApplied = false;
    let saleItemPriceAmount = saleItem.saleItemPriceAmount;
    if ( !saleItem.saleItemVatIncluded ) {
        saleItemPriceAmount = saleItem.saleItemUnitPriceNoVatAmount;
        // nulls can come from before
        if ( saleItemPriceAmount === null ) {
            saleItemPriceAmount = saleItem.saleItemPriceAmount - getVatIncludedInteger( saleItem.saleItemPriceAmount, saleItem.saleItemVatPercentage );
        }
    }
    // get totals before discount for calculations
    let itemTotalValues = getItemPriceAndDiscountValues(
        saleItemPriceAmount,
        saleItem.saleItemVatIncluded,
        saleItem.saleItemVatPercentage,
        saleItem.saleItemQuantity,
        "%",
        0
    );
    // find the saleItemId for sale fixed discounts
    sale.saleItems.forEach( tmpSaleItem => {
        if ( sale.saleDiscountAmount && sale.saleDiscountType === "€" && itemTotalValues.totalAmount >= sale.saleDiscountAmount && !tmpSaleFixedDiscountApplied && tmpSaleItem.saleItemActive ) {
            tmpSaleFixedDiscountApplied = true;
            saleFixedDiscountAppliedOnId = tmpSaleItem.saleItemId;
        }
    } );
    newSaleItem.saleItemTotalAmountBeforeDiscount = itemTotalValues.totalAmountBeforeDiscount;
    newSaleItem.saleFixedDiscountApplied = saleFixedDiscountAppliedOnId === newSaleItem.saleItemId;

    // calculating the discountType and discountAmount to apply, considering a saleDiscountAmount
    let applyDiscountAmount = 0;
    let applyDiscountType = "%";
    if ( saleItem.saleItemDiscountAmount ) {
        applyDiscountAmount = saleItem.saleItemDiscountAmount;
        applyDiscountType = saleItem.saleItemDiscountType;
    } else {
        if ( sale.saleDiscountAmount && sale.saleDiscountType === "%" ) {
            applyDiscountAmount = sale.saleDiscountAmount;
            applyDiscountType = sale.saleDiscountType;
        }
        // checking case of sale discount using fixed amount
        if ( sale.saleDiscountAmount && sale.saleDiscountType === "€" && itemTotalValues.totalAmount >= sale.saleDiscountAmount && newSaleItem.saleFixedDiscountApplied ) {
            applyDiscountAmount = sale.saleDiscountAmount;
            applyDiscountType = sale.saleDiscountType;
        }
    }
    // saleItem discounts first
    if ( applyDiscountAmount ) {
        newSaleItem.saleItemDiscountTypeApplied = applyDiscountType;
        newSaleItem.saleItemDiscountAmountApplied = applyDiscountAmount;
        itemTotalValues = getItemPriceAndDiscountValues(
            saleItemPriceAmount,
            saleItem.saleItemVatIncluded,
            saleItem.saleItemVatPercentage,
            saleItem.saleItemQuantity,
            applyDiscountType,
            applyDiscountAmount
        );
    }
    newSaleItem.saleItemTotalAmount = itemTotalValues.totalAmount;
    newSaleItem.saleItemVatTotalAmount = itemTotalValues.totalVatAmount;
    newSaleItem.saleItemVatAmount = itemTotalValues.totalVatAmount;
    newSaleItem.saleItemOverridesDone = true;
    newSaleItem.saleItemPriceNoVatAmount = itemTotalValues.totalAmountNoVat;
    newSaleItem.saleItemUnitPriceNoVatAmount = itemTotalValues.unitPriceNoVatAmount;
    newSaleItem.saleItemUnitPriceAmount = itemTotalValues.unitPriceAmount;
    newSaleItem.saleItemUnitVatAmount = itemTotalValues.unitVatAmount;
    newSaleItem.saleItemUnitSellPriceAmount = itemTotalValues.unitSellPriceAmount;
    newSaleItem.saleItemUnitDiscountAmount = itemTotalValues.unitDiscountAmount;
    newSaleItem.saleItemTotalDiscountAmount = itemTotalValues.totalDiscountAmount;
    return newSaleItem;
};
export const getVATEquivalenceAmount = ( baseAmount, argVatPercentageAmount ) => {
    let vatPercentageAmount = argVatPercentageAmount;
    if ( vatPercentageAmount < 100 ) {
        vatPercentageAmount *= 100;
    }
    let vatEquivalencePercentageAmount = 0;
    switch ( vatPercentageAmount ) {
        case 2100:
            vatEquivalencePercentageAmount = 520;
            break;
        case 1000:
            vatEquivalencePercentageAmount = 140;
            break;
        case 400:
            vatEquivalencePercentageAmount = 50; // 0.5
            break;
        default:
            break;
    }
    return getPercentageAmount( baseAmount, vatEquivalencePercentageAmount, true );
};
export const getSaleTotals = ( sale ) => {
    let subtotal = 0;
    let total = 0;
    let totalPaid = 0;
    let totalBeforeDiscounts = 0;
    let totalDiscountAmount = 0;
    let totalVATEquivalence = 0;

    const vatPercentages = [];
    let vatTotalAmount = 0;
    if ( sale.salePayments ) {
        sale.salePayments.forEach( salePayment => {
            totalPaid += salePayment.salePaymentTotalAmount;
        } );
    }
    if ( sale.saleItems ) {
        let discountTotals = null;
        let saleFixedDiscountApplied = false;
        sale.saleItems.filter( tmpItem => tmpItem.saleItemActive ).forEach( argSaleItem => {
            const saleItem = applySaleItemOverrides( sale, argSaleItem );
            const vatPercentageIndex = vatPercentages.findIndex( vat => vat.percentage === saleItem.saleItemVatPercentage );
            const price = saleItem.saleItemTotalAmount;
            // applying discounts and recalculating vat
            totalBeforeDiscounts += saleItem.saleItemTotalAmountBeforeDiscount;
            vatTotalAmount = saleItem.saleItemVatTotalAmount;
            totalDiscountAmount += saleItem.saleItemTotalDiscountAmount;
            if ( vatPercentageIndex > -1 ) {
                vatPercentages[ vatPercentageIndex ].total += vatTotalAmount;
                vatPercentages[ vatPercentageIndex ].base += saleItem.saleItemTotalAmount - vatTotalAmount;
            } else {
                vatPercentages.push( { percentage: saleItem.saleItemVatPercentage, total: vatTotalAmount, base: saleItem.saleItemTotalAmount - vatTotalAmount } );
            }
            if ( saleItem.saleFixedDiscountApplied ) {
                saleFixedDiscountApplied = true;
            }
            subtotal += price - vatTotalAmount;
            total += price;
        } );
        if ( !saleFixedDiscountApplied && sale.saleDiscountAmount && sale.saleDiscountType === "€" ) {
            discountTotals = getDiscountTotals( total, sale.saleDiscountType, sale.saleDiscountAmount );
            totalDiscountAmount += discountTotals.discountTotalAmount;
            totalBeforeDiscounts = total;
            total = discountTotals.totalAfterDiscount;
            if ( vatPercentages.length === 1 ) {
                vatTotalAmount = getVatIncludedInteger( total, vatPercentages[ 0 ].percentage );
                vatPercentages[ 0 ].total = vatTotalAmount;
                vatPercentages[ 0 ].base = total - vatTotalAmount;
            }
        }
    }
    if ( sale.saleUseVATEquivalence ) {
        vatPercentages.forEach( vatPercentage => {
            totalVATEquivalence += getVATEquivalenceAmount( vatPercentage.base, vatPercentage.percentage );
        } );
        total += totalVATEquivalence;
        totalBeforeDiscounts += totalVATEquivalence;
    }
    const debthAmount = total - totalPaid;
    return {
        subtotal, total, vatPercentages, totalPaid, totalBeforeDiscounts, debthAmount, totalDiscountAmount, totalVATEquivalence
    };
};
export const updateSaleTotals = ( sale ) => {
    const updatedSale = Object.assign( {}, sale );
    const saleTotals = getSaleTotals( sale );
    updatedSale.saleTotalAmount = saleTotals.total;
    updatedSale.salePaidTotalAmount = saleTotals.totalPaid;
    return updatedSale;
};
export const getSaleItemAllBookingsDone = ( saleItem, bono ) => {
    let totalBookingsDone = 0;
    let totalBookings = 0;
    switch ( saleItem.saleItemType ) {
        case "service": {
            totalBookingsDone = saleItem.saleItemBookings.filter( tmp => tmp.bookingDatetime || tmp.bookingStatus === "canceled" ).length;
            totalBookings = saleItem.saleItemQuantity;
            break;
        }
        case "bono": {
            if ( !bono ) {
                return true;
            }
            totalBookingsDone = saleItem.saleItemBookings.filter( tmp => tmp.bookingDatetime || tmp.bookingStatus === "canceled" ).length;
            totalBookings = 0;
            bono.bonoServices.forEach( tmp => {
                totalBookings += tmp.bonoServiceTotalSessions;
            } );
            break;
        }
        default: {
            break;
        }
    }
    if ( totalBookingsDone < totalBookings ) {
        return false;
    }
    return true;
};

export const getAllSalePaymentMethods = ( filterType ) => {
    let result = [
        { id: "cash", name: "Efectivo" },
        { id: "wiretransfer", name: "Transferencia" },
        { id: "card", name: "Tarjeta" },
        { id: "stripe", name: "Stripe" },
        { id: "redsys", name: "Redsys" },
        { id: "bizum", name: "Bizum" },
        { id: "giftcard", name: "Tarjeta Regalo" },
        { id: "other", name: "Otro" },
        { id: "system", name: "Automático" }
    ];
    if ( filterType && filterType === "outgoings" ) {
        result = result.filter( tmp => ![ "giftcard", "system" ].includes( tmp.id ) );
    }
    if ( filterType && filterType === "incomes" ) {
        result = result.filter( tmp => ![ "giftcard", "system" ].includes( tmp.id ) );
    }
    return result;
};

export const getSalePaymentMethodDesc = ( id ) => {
    let result = "";
    const found = getAllSalePaymentMethods().find( tmp => tmp.id === id );
    if ( found && found.id ) {
        result = found.name;
    }
    return result;
};
export const getNewPayment = ( salePaymentTotalAmount, salePaymentPaymentMethod, exchangeGiftCard ) => {
    let salePaymentGiftCardSaleId = null;
    if ( exchangeGiftCard ) {
        salePaymentPaymentMethod = "giftcard";
        salePaymentTotalAmount = exchangeGiftCard.saleGiftCardAvailableAmount;
        salePaymentGiftCardSaleId = exchangeGiftCard.saleId;
    }
    return {
        salePaymentId: uuid(),
        salePaymentCurrency: "EUR",
        salePaymentPaymentMethod,
        salePaymentTotalAmount,
        salePaymentGiftCardSaleId,
        salePaymentDatetime: now(),
        salePaymentStatus: "active",
        salePaymentActive: 1
    };
};
export const getNewSaleItem = ( saleItemType, argSaleItemTitle, selectedService, selectedBono, saleItemPriceAmount, saleItemIsVatIncluded, saleItemQuantity, argVatPercentageAmount, saleItemDiscountAmount, saleItemDiscountType, saleItemExpensesAmount, bookings, shopItem ) => {
    // saleItemType: service, bono, other, giftcardcredit
    let saleItemTitle = argSaleItemTitle;
    const saleCurrency = "EUR";
    let vatPercentageAmount = argVatPercentageAmount;
    if ( saleItemType === "giftcardcredit" ) {
        saleItemTitle = "Saldo";
        vatPercentageAmount = 0;
    }
    if ( saleItemType === "service" ) {
        saleItemTitle = selectedService.serviceTitle;
    }
    const vatValues = getVatValues( saleItemPriceAmount, saleItemIsVatIncluded, saleItemQuantity, vatPercentageAmount, saleItemDiscountAmount, saleItemDiscountType );
    const saleItem = {
        saleItemIsNew: true,
        saleItemId: uuid(),
        saleItemType,
        saleItemBonoId: selectedBono ? selectedBono.bonoId : null,
        saleItemServiceId: selectedService ? selectedService.serviceId : null,
        saleItemTitle,
        saleItemQuantity,
        saleItemPriceAmount: vatValues.priceWithVatAmount,
        saleItemPriceCurrency: saleCurrency,
        saleItemUnitPriceNoVatAmount: vatValues.unitPriceNoVat,
        saleItemTotalAmount: vatValues.totalAmount,
        saleItemVatAmount: vatValues.totalVatAmount,
        saleItemVatIncluded: saleItemIsVatIncluded,
        saleItemVatPercentage: vatPercentageAmount,
        saleItemExpensesAmount,
        saleItemTeamMemberId: null,
        saleItembookingDatetime: null,
        saleItemDiscountType,
        saleItemDiscountAmount,
        saleItemShopItemId: shopItem ? shopItem.id : null,
        saleItemActive: 1,
        saleItemBookings: sortArray( bookings, { sortingKeys: [ { alias: "bookingBonoServiceId", direction: "asc" }, { alias: "bookingServiceId", direction: "asc" }, { alias: "bookingSessionNumber", direction: "asc" } ] } )
    };
    return saleItem;
};
export const getNewBooking = ( type, bookingTotalAmount, bookingTotalAmountExpenses, bookingBonoId, bookingBonoServiceId, bookingServiceId, bookingSessionNumber, bookingTeamMemberId, bookingDatetime, bookingDatetimeEnd, bookingPetId, bookingInternalNote, bookingStatus, bookingResources ) => {
    // types: bono, service, booking
    const defaultBooking = {
        tmpId: uuid(), bookingType: type, bookingPaid: 0, bookingActive: 1, bookingTotalTeamMembers: 1, bookingStatus: bookingStatus || "active", bookingCurrency: "EUR", bookingTotalAmount, bookingTotalAmountExpenses, bookingBonoId, bookingBonoServiceId, bookingServiceId, bookingSessionNumber, bookingTeamMemberId, bookingDatetime, bookingDatetimeEnd, bookingPetId, bookingInternalNote, bookingResources: bookingResources || []
    };
    return defaultBooking;
};

export const getNewSale = ( saleType, giftCardType, sellerTeamMemberId, saleItems, saleCustomerId, capabilities, saleDiscountAmount, customerReferredTotalRewarded, giftCardCreditAmount, saleStatus, promoCodeId, saleDiscountType, saleOutsourced, saleOutsourcedStatus, saleInStorePickUp ) => {
    // saleType: sale, giftcard
    // giftCardType: amount, services
    const sale = {
        saleDate: today(),
        saleActive: 1,
        saleStatus: saleStatus || "active",
        saleCurrency: "EUR",
        saleType,
        saleDiscountType: saleDiscountType || "%",
        saleDiscountAmount: saleDiscountAmount || 0,
        saleDiscountPrice: 0,
        saleIsGiftCard: 0,
        saleGiftCardCode: null,
        saleGiftCardSpentAmount: 0,
        saleSellerTeamMemberId: sellerTeamMemberId,
        saleGiftCardType: null,
        saleCustomerId: saleCustomerId || null,
        salePayments: [],
        saleDatetimeExpiration: null,
        salePromoCodeId: promoCodeId || null,
        customerReferredTotalRewarded: customerReferredTotalRewarded || 0,
        saleAutoPay: capabilities.saleAutoPayByDefault ? 1 : 0,
        saleOutsourced: saleOutsourced || 0,
        saleOutsourcedStatus: saleOutsourcedStatus || null,
        saleInStorePickUp: saleInStorePickUp || 0
    };
    if ( capabilities && capabilities.saleExpirationDays ) {
        sale.saleDatetimeExpiration = formatDateTimeString( dateTimeAdd( sale.saleDate, capabilities.saleExpirationDays, "days" ) );
    }
    if ( saleType === "giftcard" ) {
        sale.saleIsGiftCard = 1;
        sale.saleGiftCardCode = randomString( 8 ).toUpperCase();
        sale.saleGiftCardType = giftCardType;
    }
    if ( giftCardCreditAmount ) {
        const newSaleItem = getNewSaleItem(
            "giftcardcredit",
            "Saldo",
            null,
            null,
            giftCardCreditAmount,
            true,
            1,
            0,
            0,
            "%",
            0,
            [],
            null
        );
        sale.saleItems = [ newSaleItem ];
    }
    if ( saleItems ) {
        sale.saleItems = sale.saleItems || [];
        sale.saleItems = sale.saleItems.concat( saleItems );
    }
    return sale;
};
export const getInvoiceName = ( invoice, nameFormat ) => {
    let format = nameFormat || "default";
    let prefix = invoice.invoicePrefix || "";
    let typeDesc = "factura";
    let legalTypeDesc = "factura";

    if ( prefix !== "" && !prefix.endsWith( "/" ) ) {
        prefix = `${ prefix }-`;
    }
    if ( invoice.invoiceType === "ticket" ) {
        typeDesc = "ticket";
        legalTypeDesc = "factura simplificada";
    }
    if ( invoice.invoiceType === "credit" ) {
        typeDesc = "rectificativa";
        legalTypeDesc = "factura rectificativa";
    }
    let short = `${ prefix }${ invoice.invoiceNumber }`;
    if ( format === "short" ) {
        return short;
    }
    if ( format === "type_and_short" ) {
        return `${ typeDesc } ${ prefix }${ invoice.invoiceNumber }`;
    }
    if ( format === "legaltype_and_short" ) {
        return `${ legalTypeDesc } ${ prefix }${ invoice.invoiceNumber }`;
    }
    if ( format === "legaltype" ) {
        return legalTypeDesc;
    }
    if ( format === "type" ) {
        return typeDesc;
    }
    return `${ prefix }${ invoice.invoiceNumber } (${ formatIntegerPrice( "EUR", invoice.invoiceTotalAmount, true ) }, ${ invoice.invoiceToFullName }, ${ invoice.invoiceFromFullName }, ${ formatDateNiceShort( invoice.invoiceDate ) })`;
};
export const getCreditInvoice = ( invoiceToBeCredited ) => {
    let invoiceItems = invoiceToBeCredited.invoiceItems.map( tmpInvoiceItem => Object.assign( {}, tmpInvoiceItem, {
        invoiceItemId: uuid(),
        invoiceItemPriceAmount: -tmpInvoiceItem.invoiceItemPriceAmount,
        invoiceItemUnitPriceNoVat: -tmpInvoiceItem.invoiceItemUnitPriceNoVat,
        invoiceItemTotalAmount: -tmpInvoiceItem.invoiceItemTotalAmount,
        invoiceItemVatAmount: -tmpInvoiceItem.invoiceItemVatAmount,
        invoiceItemActive: 1
    } ) );
    let invoice = Object.assign( {}, invoiceToBeCredited, {
        isNew: true,
        invoiceId: uuid(),
        invoiceType: "credit",
        invoiceStatus: "active",
        invoiceLinkedToInvoiceId: invoiceToBeCredited.invoiceId,
        invoiceDate: today(),
        invoiceTotalAmount: -invoiceToBeCredited.invoiceTotalAmount,
        invoiceIsMainInvoice: 0,
        invoiceItems,
        invoicePrintableNote: `Rectifica la factura ${ getInvoiceName( invoiceToBeCredited, "short" ) }`,
        invoiceSalePayments: [],
        invoiceSalePrepayments: []
    } );
    invoice.invoicePrefix = null;
    invoice.invoiceNumber = null;
    return invoice;
};
export const getInvoiceFromSale = ( invoiceFrom, sale, invoiceConfig, invoiceType, invoiceFormat ) => {
    let invoiceFromSufix = "";
    if ( invoiceFrom.numbering === "secondary" ) {
        invoiceFromSufix = "Secondary";
    }
    let isNew = true;
    let saleTotals = getSaleTotals( sale );
    let customerPhone = sale.saleCustomer.customerPhone ? sale.saleCustomer.customerPhone : sale.saleCustomer.customerMobile;
    // we check if some payments must be linked to the new invoice
    let invoiceSalePrepayments = [];
    let invoiceSalePayments = [];
    let invoicePrepaymentInvoicesIds = [];
    let isFullInvoice = false;
    let isPartialInvoice = false;
    if ( sale.saleTotalAmount <= sale.salePaidTotalAmount ) {
        isFullInvoice = true;
    } else if ( sale.salePaidTotalAmount > 0 ) {
        isPartialInvoice = true;
    }
    if ( invoiceType === "invoice" ) {
        isPartialInvoice = false;
        isFullInvoice = true;
    }
    sale.salePayments.forEach( ( salePayment ) => {
        // active payments in sale
        if ( salePayment.salePaymentActive && salePayment.salePaymentStatus === "active" ) {
            // new payments to be linked
            if ( !salePayment.salePaymentInvoiceId ) {
                invoiceSalePayments.push( Object.assign( {}, salePayment ) );
            }
            // prepayments to be deducted
            if ( salePayment.salePaymentInvoiceId ) {
                invoiceSalePrepayments.push( Object.assign( {}, salePayment ) );
                // to be able to link prepayment invoices to this invoice later
                if ( !invoicePrepaymentInvoicesIds.includes( salePayment.salePaymentInvoiceId ) ) {
                    invoicePrepaymentInvoicesIds.push( salePayment.salePaymentInvoiceId );
                }
            }
        }
        return false;
    } );
    let invoiceItems = sale.saleItems.filter( tmpSaleItem => tmpSaleItem.saleItemActive ).map( tmpSaleItem => {
        let saleItem = applySaleItemOverrides( sale, tmpSaleItem );
        let invoiceDiscountType = saleItem.saleItemDiscountTypeApplied ? saleItem.saleItemDiscountTypeApplied : saleItem.saleItemDiscountType;
        let invoiceDiscountAmount = saleItem.saleItemDiscountAmountApplied ? saleItem.saleItemDiscountAmountApplied : saleItem.saleItemDiscountAmount;
        if ( invoiceDiscountType === "€" ) {
            invoiceDiscountType = "%";
            invoiceDiscountAmount = ( invoiceDiscountAmount / saleItem.saleItemUnitSellPriceAmount ) * 10000;
        }
        return Object.assign( {}, {
            invoiceItemId: uuid(),
            invoiceItemName: saleItem.saleItemTitle,
            invoiceItemQuantity: saleItem.saleItemQuantity,
            invoiceItemPriceAmount: saleItem.saleItemUnitPriceNoVatAmount,
            invoiceItemUnitPriceNoVat: saleItem.saleItemUnitPriceNoVatAmount,
            invoiceItemPriceCurrency: "EUR",
            invoiceItemTotalAmount: saleItem.saleItemTotalAmount,
            invoiceItemVatAmount: saleItem.saleItemVatAmount,
            invoiceItemVatPercentage: saleItem.saleItemVatPercentage,
            invoiceItemVatIncluded: 0,
            invoiceItemDiscountType: invoiceDiscountType,
            invoiceItemDiscountAmount: invoiceDiscountAmount,
            invoiceItemActive: 1
        } );
    } );
    let invoiceTotalAmount = saleTotals.total;
    let invoiceDiscountType = sale.saleDiscountType;
    let invoiceDiscountAmount = sale.saleDiscountAmount;
    if ( !isFullInvoice && isPartialInvoice ) {
        let totalPrepaymentAmount = 0;
        invoiceSalePayments.forEach( tmpSalePayment => {
            totalPrepaymentAmount += tmpSalePayment.salePaymentTotalAmount;
        } );
        invoiceTotalAmount = totalPrepaymentAmount;
        invoiceDiscountAmount = 0;
        let prepaymentVatAmount = getVatIncludedInteger( totalPrepaymentAmount, invoiceFrom.prepaymentItemVatAmount );
        invoiceItems = [
            {
                invoiceItemId: uuid(),
                invoiceItemName: `${ invoiceFrom.prepaymentItemDescription }`,
                invoiceItemQuantity: 1,
                invoiceItemPriceAmount: totalPrepaymentAmount,
                invoiceItemUnitPriceNoVat: totalPrepaymentAmount - prepaymentVatAmount,
                invoiceItemPriceCurrency: "EUR",
                invoiceItemTotalAmount: totalPrepaymentAmount,
                invoiceItemVatAmount: prepaymentVatAmount,
                invoiceItemVatPercentage: invoiceFrom.prepaymentItemVatAmount,
                invoiceItemVatIncluded: 0,
                invoiceItemDiscountType: "%",
                invoiceItemDiscountAmount: 0,
                invoiceItemActive: 1
            }
        ];
    }
    // if is paid or invoiced we check if there are prepayments to be deducted
    let invoiceIsMainInvoice = 0;
    if ( isFullInvoice ) {
        invoiceIsMainInvoice = 1;
        let prepaymentInvoiceIds = [];
        invoiceSalePrepayments.forEach( ( invoiceSalePrepayment ) => {
            if ( !prepaymentInvoiceIds.includes( invoiceSalePrepayment.salePaymentInvoiceId ) ) {
                prepaymentInvoiceIds.push( invoiceSalePrepayment.salePaymentInvoiceId );
                let prepaymentTotalAmount = invoiceSalePrepayment.salePaymentInvoice.invoiceTotalAmount;
                let prepaymentVatAmount = getVatIncludedInteger( prepaymentTotalAmount, invoiceFrom.prepaymentItemVatAmount );
                invoiceItems.push( Object.assign( {}, {
                    invoiceItemId: uuid(),
                    invoiceItemName: `${ invoiceFrom.prepaymentItemDescription } ${ getInvoiceName( invoiceSalePrepayment.salePaymentInvoice, "short" ) }`,
                    invoiceItemQuantity: 1,
                    invoiceItemPriceAmount: -prepaymentTotalAmount,
                    invoiceItemUnitPriceNoVat: -( prepaymentTotalAmount - prepaymentVatAmount ),
                    invoiceItemPriceCurrency: "EUR",
                    invoiceItemTotalAmount: -prepaymentTotalAmount,
                    invoiceItemVatAmount: -prepaymentVatAmount,
                    invoiceItemVatPercentage: invoiceFrom.prepaymentItemVatAmount,
                    invoiceItemVatIncluded: 0,
                    invoiceItemDiscountType: "%",
                    invoiceItemDiscountAmount: 0,
                    invoiceItemActive: 1
                } ) );
            }
        } );
    }
    let invoiceVATEquivalenceTaxAmount = saleTotals.totalVATEquivalence;
    let invoiceUseVATEquivalence = sale.saleUseVATEquivalence;
    if ( !isFullInvoice && isPartialInvoice ) {
        // prepayment ticket
        invoiceVATEquivalenceTaxAmount = 0;
        invoiceUseVATEquivalence = 0;
    }
    let invoice = {
        isNew,
        invoiceType,
        invoiceFormat,
        invoiceStatus: "active",
        invoiceDate: today(),
        invoiceFromId: invoiceFrom.id,
        invoiceFromFullName: invoiceFrom.fullName,
        invoiceFromAddress: invoiceFrom.address,
        invoiceFromCity: invoiceFrom.city,
        invoiceFromProvince: invoiceFrom.fullName,
        invoiceFromPostalCode: invoiceFrom.postalCode,
        invoiceFromFiscalId: invoiceFrom.fiscalId,
        invoiceFromPhone: invoiceFrom.phone,
        invoiceFromEmail: invoiceFrom.email,
        invoiceFromNumbering: invoiceFrom.numbering,
        invoiceCustomerId: sale.saleCustomer.customerId,
        invoiceToFullName: sale.saleCustomer.customerFullName,
        invoiceToAddress: sale.saleCustomer.customerAddress,
        invoiceToCity: sale.saleCustomer.customerCity,
        invoiceToProvince: sale.saleCustomer.customerProvince,
        invoiceToPostalCode: sale.saleCustomer.customerPostalCode,
        invoiceDiscountType,
        invoiceDiscountAmount,
        invoiceToPhone: customerPhone,
        invoiceToEmail: sale.saleCustomer.customerEmail,
        invoiceCurrency: sale.saleCustomer.customerCurrency,
        invoiceToFiscalId: sale.saleCustomer.customerFiscalId,
        invoiceVATEquivalenceTaxAmount,
        invoiceUseVATEquivalence,
        invoiceTotalAmount,
        invoiceSaleId: sale.saleId,
        invoiceIsMainInvoice,
        invoiceItems,
        invoiceSalePayments,
        invoiceSalePrepayments,
        invoicePrepaymentInvoicesIds
    };
    if ( invoiceConfig.globalNumbering ) {
        invoice.invoicePrefix = null;
        invoice.invoiceNumber = null;
    } else {
        invoice.invoicePrefix = sale.saleCustomer[ `customerInvoicePrefix${ invoiceFromSufix }` ];
        invoice.invoiceNumber = sale.saleCustomer[ `customerInvoiceLastNumber${ invoiceFromSufix }` ] + 1;
    }
    return invoice;
};
export const addSalePayment = ( sale, salePaymentPaymentMethod, salePaymentTotalAmount, salePaymentGiftCardSaleId, salePaymentExternalId ) => {
    const newSale = Object.assign( {}, sale );
    newSale.salePayments.push( {
        salePaymentId: uuid(),
        salePaymentCurrency: "EUR",
        salePaymentPaymentMethod,
        salePaymentTotalAmount,
        salePaymentGiftCardSaleId: salePaymentGiftCardSaleId || null,
        salePaymentExternalId: salePaymentExternalId || null,
        salePaymentDatetime: now(),
        salePaymentStatus: "active",
        salePaymentActive: 1
    } );
    return newSale;
};
export const updateSalePaidItems = ( sale ) => {
    const editingSaleTotals = getSaleTotals( sale );
    let saleTotalPaid = false;
    let noPayments = false;
    if ( !sale.saleItems ) {
        return sale;
    }
    const newSale = Object.assign( {}, sale );
    if ( editingSaleTotals.totalPaid === 0 && editingSaleTotals.total === 0 && sale.saleItems && sale.saleItems.length > 0 ) {
        // all paid
        saleTotalPaid = true;
    } else if ( editingSaleTotals.totalPaid > 0 && editingSaleTotals.total > 0 && editingSaleTotals.totalPaid >= editingSaleTotals.total ) {
        // all paid
        saleTotalPaid = true;
    } else if ( sale.saleDiscountType === "%" && sale.saleDiscountAmount >= 10000 ) {
        // 100% discount
        saleTotalPaid = true;
    }
    if ( editingSaleTotals.totalPaid === 0 ) {
        // no payments done
        noPayments = true;
    }
    const sortedBookings = [];
    const nonSortedBookings = [];
    const newSaleItems = sale.saleItems.map( tmpSaleItem => {
        let saleItemPaid = 0;
        if ( saleTotalPaid || tmpSaleItem.saleItemType === "link" || !tmpSaleItem.saleItemActive ) {
            saleItemPaid = 1;
        } else if ( noPayments ) {
            saleItemPaid = 0;
        }
        const updatedSaleItem = Object.assign( {}, tmpSaleItem, { saleItemPaid } );
        if ( updatedSaleItem.saleItemBookings && ( saleTotalPaid || noPayments ) ) {
            // if all sale is paid or no payments, all bookings has the same bookingPaid value
            updatedSaleItem.saleItemBookings = updatedSaleItem.saleItemBookings.map( tmp => Object.assign( tmp, { bookingPaid: saleItemPaid } ) );
        } else if ( updatedSaleItem.saleItemBookings ) {
            // let's sort all bookings to distribute the payments
            updatedSaleItem.saleItemBookings.forEach( tmpBooking => {
                if ( tmpBooking.bookingDatetime ) {
                    sortedBookings.push( tmpBooking );
                } else {
                    nonSortedBookings.push( tmpBooking );
                }
            } );
        }
        return updatedSaleItem;
    } );
    newSale.saleItems = newSaleItems;
    // no need to continue if total was paid or if there is no payments in this sale
    if ( saleTotalPaid || noPayments ) {
        return newSale;
    }
    sortArray( sortedBookings, { sortingKeys: [ { direction: "asc", alias: "bookingDatetime" } ] } );
    nonSortedBookings.forEach( tmpNonSortedBooking => {
        tmpNonSortedBooking.bookingPaid = 0;
        // we place at the end, all bookings with no scheduled datetime
        sortedBookings.push( tmpNonSortedBooking );
    } );
    let totalPaidUsed = 0;
    sortedBookings.forEach( tmp => {
        const available = editingSaleTotals.totalPaid - totalPaidUsed;
        tmp.bookingPaid = 0;
        if ( tmp.bookingTotalAmount <= available ) {
            tmp.bookingPaid = 1;
            totalPaidUsed += tmp.bookingTotalAmount;
        }
    } );
    // finally we recheck saleItems in order to mark it as paid if all bookings are paid or saleItemTotalAmount is 0.
    // sale items update
    newSale.saleItems.filter( tmpSaleItem => tmpSaleItem.saleItemActive ).forEach( tmpSaleItem => {
        tmpSaleItem.saleItemPaid = 0;
        const saleItemWithOverrides = applySaleItemOverrides( newSale, tmpSaleItem );
        // saleItems "other" or if it's the item that we used to apply a sale fixed discount
        if ( tmpSaleItem.saleItemType === "other" || tmpSaleItem.saleItemType === "product" || saleItemWithOverrides.saleItemTotalAmountBeforeDiscount !== saleItemWithOverrides.saleItemTotalAmount ) {
            const available = editingSaleTotals.totalPaid - totalPaidUsed;
            if ( saleItemWithOverrides.saleItemTotalAmount <= available ) {
                tmpSaleItem.saleItemPaid = 1;
                totalPaidUsed += saleItemWithOverrides.saleItemTotalAmount;
            }
        } else {
            let allBookingsPaid = 1;
            tmpSaleItem.saleItemBookings.forEach( tmpBooking => {
                if ( !tmpBooking.bookingPaid ) {
                    allBookingsPaid = 0;
                }
            } );
            if ( allBookingsPaid ) {
                tmpSaleItem.saleItemPaid = 1;
            }
        }
    } );
    return newSale;
};
export const applyNewBookingValues = ( oldBooking, newBooking ) => {
    const mergedBooking = Object.assign( {}, oldBooking );
    if ( newBooking ) {
        mergedBooking.tmpId = newBooking.tmpId || null;
        mergedBooking.bookingDatetime = newBooking.bookingDatetime;
        mergedBooking.bookingDatetimeEnd = newBooking.bookingDatetimeEnd;
        mergedBooking.bookingTeamMemberId = newBooking.bookingTeamMemberId;
        mergedBooking.bookingPetId = newBooking.bookingPetId;
        mergedBooking.bookingInternalNote = newBooking.bookingInternalNote;
        mergedBooking.bookingTeamMember = newBooking.bookingTeamMember || null;
        mergedBooking.bookingService = newBooking.bookingService || null;
        mergedBooking.bookingCustomerPet = newBooking.bookingCustomerPet || null;
    }
    return mergedBooking;
};
export const isDiscountableBooking = ( booking, customerPendingBooking, alreadyAddedBookings ) => {
    let result = false;
    // if the customerPendingBooking is already used when editing
    const customerPendingBookingAlreadyUsed = alreadyAddedBookings && alreadyAddedBookings.find( tmpAlreadyAdded => {
        // the customerPendingBooking was already applied in alreadyAddedBookings
        if ( tmpAlreadyAdded.selectedPendingBooking && customerPendingBooking.bookingId === tmpAlreadyAdded.selectedPendingBooking.bookingId ) {
            return true;
        }
        // the customerPendingBooking is already added as an item
        if ( customerPendingBooking.bookingId === tmpAlreadyAdded.bookingId ) {
            return true;
        }
        return false;
    } );
    // is discountable only if ...
    if ( !booking.selectedPendingBooking
        && !customerPendingBookingAlreadyUsed
        && booking
        && customerPendingBooking
        && customerPendingBooking.bookingType === "bono"
        && booking.bookingServiceId === customerPendingBooking.bookingServiceId ) {
        result = true;
    }
    return result;
};
export const isExchangeableBooking = ( booking, customerPendingBooking, alreadyAddedBookings ) => {
    if ( !isExchangeableItemsEnabled() ) {
        return false;
    }
    let result = false;
    // if the customerPendingBooking is already used when editing
    const customerPendingBookingAlreadyUsed = alreadyAddedBookings && alreadyAddedBookings.find( tmpAlreadyAdded => {
        // the customerPendingBooking was already applied in alreadyAddedBookings
        if ( tmpAlreadyAdded.selectedPendingBooking && customerPendingBooking.bookingId === tmpAlreadyAdded.selectedPendingBooking.bookingId ) {
            return true;
        }
        // the customerPendingBooking is already added as an item
        if ( customerPendingBooking.bookingId === tmpAlreadyAdded.bookingId ) {
            return true;
        }
        return false;
    } );
    // is exchangeable only if ...
    if ( !booking.selectedPendingBooking && !customerPendingBookingAlreadyUsed && customerPendingBooking && customerPendingBooking.bookingType === "bono" && !customerPendingBooking.bookingDatetime ) {
        result = true;
    }
    return result;
};

export const getSaleBookings = ( sale ) => {
    let result = [];
    sale.saleItems.forEach( saleItem => {
        if ( saleItem && saleItem.saleItemBookings ) {
            result = result.concat( saleItem.saleItemBookings );
        }
    } );
    return result;
};

export const updateDiscountableSalesItems = ( sale, customerPendingBookings, saleGiftCard ) => {
    const tmpSale = Object.assign( {}, sale );
    // let's reset discountable info
    tmpSale.saleItems = tmpSale.saleItems ? tmpSale.saleItems.map( tmpItem => tmpItem ) : [];
    const usedPendingBookingsIds = [];
    let saleGiftCardPendingBookings = [];
    if ( saleGiftCard ) {
        saleGiftCardPendingBookings = getSaleBookings( saleGiftCard ).map( tmp => Object.assign( {}, tmp, { isSaleGiftCardBooking: true } ) );
        saleGiftCardPendingBookings = saleGiftCardPendingBookings.filter( tmp => ( !tmp.bookingDatetime || !tmp.bookingTeamMemberId ) && tmp.bookingStatus === "active" );
    }
    // already used bookings in saleItems
    tmpSale.saleItems.forEach( tmpSaleItem => {
        if ( tmpSaleItem.saleItemType === "link" && tmpSaleItem.saleItemLinkBookingId ) {
            usedPendingBookingsIds.push( tmpSaleItem.saleItemLinkBookingId );
        }
    } );
    if ( tmpSale.saleItems.length > 0 ) {
        for ( let tmpSaleItemI = 0; tmpSaleItemI < tmpSale.saleItems.length; tmpSaleItemI += 1 ) {
            const tmpSaleItem = tmpSale.saleItems[ tmpSaleItemI ];
            tmpSale.saleItems[ tmpSaleItemI ].discountableSaleItem = null;
            tmpSale.saleItems[ tmpSaleItemI ].exchangeableSaleItems = null;
            // Can only be applied to services
            // Can't be discounted in gift cards
            if ( tmpSaleItem.saleItemActive && tmpSaleItem.saleItemQuantity === 1 && tmpSaleItem.saleItemType === "service" && tmpSale.saleType === "sale" && !tmpSaleItem.saleItemPaid ) {
                const tmpDiscountableSaleItem = saleGiftCardPendingBookings.concat( customerPendingBookings ).find( tmpPendingBooking => {
                    // saleItems can't be discounted from same sale
                    if ( tmpPendingBooking.bookingSaleId === tmpSale.saleId ) {
                        return false;
                    }
                    // gift card booking?
                    if ( tmpPendingBooking.isSaleGiftCardBooking ) {
                        if ( tmpPendingBooking.bookingServiceId === tmpSaleItem.saleItemServiceId && !usedPendingBookingsIds.includes( tmpPendingBooking.bookingId ) ) {
                            return true;
                        }
                    } else {
                        // not from gift card
                        // other gift cards doesn't discount (only the selected one)
                        if ( tmpPendingBooking.bookingSale.saleType !== "sale" ) {
                            return false;
                        }
                        // only bono type bookings discount
                        if ( tmpPendingBooking.bookingType !== "bono" ) {
                            return false;
                        }
                        // must be same service and not already discounted in current sale used
                        if ( tmpPendingBooking.bookingServiceId === tmpSaleItem.saleItemServiceId && !usedPendingBookingsIds.includes( tmpPendingBooking.bookingId ) ) {
                            return true;
                        }
                    }
                    return false;
                } );
                const exchangeableSaleItems = customerPendingBookings.filter( tmpPendingBooking => {
                    // saleItems can't be discounted from same sale
                    if ( !usedPendingBookingsIds.includes( tmpPendingBooking.bookingId ) && tmpPendingBooking.bookingSaleItemId !== tmpSale.saleItems[ tmpSaleItemI ].saleItemId && tmpPendingBooking.bookingType === "bono" && !tmpPendingBooking.bookingDatetime ) {
                        return true;
                    }
                    return false;
                } );
                if ( tmpDiscountableSaleItem ) {
                    usedPendingBookingsIds.push( tmpDiscountableSaleItem.bookingId );
                    tmpSale.saleItems[ tmpSaleItemI ].discountableSaleItem = tmpDiscountableSaleItem;
                } else if ( isExchangeableItemsEnabled() && exchangeableSaleItems && exchangeableSaleItems.length > 0 ) {
                    tmpSale.saleItems[ tmpSaleItemI ].exchangeableSaleItems = exchangeableSaleItems;
                }
            }
        }
    }
    return tmpSale;
};

export const applyDiscountableSaleItem = ( sale, saleItem, customerPendingBookings, exchangeableBooking, saleGiftCard ) => {
    const tmpSale = updateDiscountableSalesItems( sale, customerPendingBookings, saleGiftCard );
    const saleItemBookingMutations = { bookingSessionNumber: 1 };
    if ( saleItem.saleItemBookings && saleItem.saleItemBookings.length === 1 ) {
        if ( exchangeableBooking ) {
            saleItemBookingMutations.bookingServiceId = saleItem.saleItemBookings[ 0 ].bookingServiceId;
        }
        saleItemBookingMutations.bookingDatetime = saleItem.saleItemBookings[ 0 ].bookingDatetime;
        saleItemBookingMutations.bookingDatetimeEnd = saleItem.saleItemBookings[ 0 ].bookingDatetimeEnd;
        saleItemBookingMutations.bookingTeamMemberId = saleItem.saleItemBookings[ 0 ].bookingTeamMemberId;
    }
    tmpSale.saleItems = tmpSale.saleItems ? tmpSale.saleItems.map( tmpItem => {
        if ( exchangeableBooking && exchangeableBooking.bookingSaleId === tmpSale.saleId && exchangeableBooking.bookingSaleItemId === tmpItem.saleItemId ) {
            tmpItem.saleItemBookings = tmpItem.saleItemBookings.map( tmpSaleItemBooking => {
                if ( tmpSaleItemBooking.bookingId === exchangeableBooking.bookingId ) {
                    return Object.assign( {}, tmpSaleItemBooking, {
                        bookingDatetime: saleItem.saleItemBookings[ 0 ].bookingDatetime,
                        bookingDatetimeEnd: saleItem.saleItemBookings[ 0 ].bookingDatetimeEnd,
                        bookingServiceId: saleItem.saleItemBookings[ 0 ].bookingServiceId,
                        bookingTeamMemberId: saleItem.saleItemBookings[ 0 ].bookingTeamMemberId
                    } );
                }
                return tmpSaleItemBooking;
            } );
            return tmpItem;
        }
        if ( tmpItem.saleItemId === saleItem.saleItemId && ( saleItem.discountableSaleItem || exchangeableBooking ) ) {
            tmpItem.saleItemType = "link";
            tmpItem.saleItemLinkType = saleItem.saleItemType;
            if ( exchangeableBooking ) {
                tmpItem.saleItemLinkBookingId = exchangeableBooking.bookingId;
                tmpItem.saleItemLinkSaleItemId = exchangeableBooking.bookingSaleItemId;
                tmpItem.saleItemLinkSaleId = exchangeableBooking.bookingSaleId;
                tmpItem.discountedSaleItem = Object.assign( {}, exchangeableBooking );
            } else {
                tmpItem.saleItemLinkBookingId = saleItem.discountableSaleItem.bookingId;
                tmpItem.saleItemLinkSaleItemId = saleItem.discountableSaleItem.bookingSaleItemId;
                tmpItem.saleItemLinkSaleId = saleItem.discountableSaleItem.bookingSaleId;
                tmpItem.discountedSaleItem = Object.assign( {}, saleItem.discountableSaleItem );
            }
            tmpItem.discountableSaleItem = null;
            tmpItem.saleItemBookings = [ Object.assign( {}, tmpItem.discountedSaleItem, saleItemBookingMutations ) ];
            tmpItem.saleItemQuantity = 1;
            tmpItem.saleItemDiscountAmount = 10000;
            tmpItem.saleItemDiscountType = "%";
            tmpItem.showIcons = [ "ruby" ];
        }
        return tmpItem;
    } ) : [];
    return tmpSale;
};

export const getSaleItemBookings = ( type, fullService, bonoService, quantity, currentBookings, saleItemPriceAmount, saleItemIsVatIncluded, saleItemVatPercentage ) => {
    if ( !fullService ) {
        return [];
    }
    const bookings = [];
    let totalSessions = 0;
    let bookingTotalAmount = 0;
    let bookingTotalAmountExpenses = 0;
    let bookingTotals = 0;
    if ( type === "bono" ) {
        totalSessions = bonoService.bonoServiceTotalSessions;
    } else if ( type === "service" ) {
        totalSessions = quantity;
    }
    for ( let index = 1; index <= totalSessions; index += 1 ) {
        let tmpCurrentBooking = null;
        let defaultBooking = null;
        if ( type === "bono" ) {
            tmpCurrentBooking = currentBookings.find( tmpBooking => tmpBooking.bookingBonoServiceId === bonoService.bonoServiceId && tmpBooking.bookingSessionNumber === index );
            bookingTotals = getVatValues( bonoService.bonoServicePriceAmount, bonoService.bonoServiceVatIncluded, 1, bonoService.bonoServiceVatPercentage );
            bookingTotalAmount = bookingTotals.priceWithVatAmount;
            bookingTotalAmountExpenses = bonoService.bonoServiceTeamFeeExpenses;
            // defaultBooking = {
            //     tmpId: uuid(), bookingType: type, bookingPaid: 0, bookingActive: 1, bookingTotalTeamMembers: 1, bookingStatus: "active", bookingCurrency: "EUR", bookingTotalAmount, bookingTotalAmountExpenses, bookingBonoId: argService.bonoServiceBonoId, bookingBonoServiceId: argService.bonoServiceId, bookingServiceId: fullService.serviceId, bookingSessionNumber: index, bookingTeamMemberId: null, bookingDatetime: null, bookingDatetimeEnd: null
            // };
            defaultBooking = getNewBooking( type, bookingTotalAmount, bookingTotalAmountExpenses, bonoService.bonoServiceBonoId, bonoService.bonoServiceId, fullService.serviceId, index, null, null, null, null, null, "active", [] );
        } else if ( type === "service" ) {
            tmpCurrentBooking = currentBookings.find( tmpBooking => tmpBooking.bookingServiceId === fullService.serviceId && tmpBooking.bookingSessionNumber === index );
            bookingTotals = getVatValues( saleItemPriceAmount, saleItemIsVatIncluded, 1, saleItemVatPercentage );
            bookingTotalAmount = bookingTotals.priceWithVatAmount;
            bookingTotalAmountExpenses = fullService.serviceTeamFeeExpenses;
            // defaultBooking = {
            //     tmpId: uuid(), bookingType: type, bookingPaid: 0, bookingActive: 1, bookingTotalTeamMembers: 1, bookingStatus: "active", bookingCurrency: "EUR", bookingTotalAmount, bookingTotalAmountExpenses, bookingServiceId: fullService.serviceId, bookingSessionNumber: index, bookingTeamMemberId: null, bookingDatetime: null, bookingDatetimeEnd: null
            // };
            defaultBooking = getNewBooking( type, bookingTotalAmount, bookingTotalAmountExpenses, null, null, fullService.serviceId, index, null, null, null, null, null, "active", [] );
        }
        if ( tmpCurrentBooking ) {
            let updateBookingServiceId = true;
            if ( type === "bono" && tmpCurrentBooking.bookingServiceId && tmpCurrentBooking.bookingServiceId !== fullService.serviceId ) {
                // bono bookings can be changed to a different service than the bono, so we don't reset that here
                updateBookingServiceId = false;
            }
            if ( updateBookingServiceId ) {
                tmpCurrentBooking.bookingServiceId = fullService.serviceId;
            }
            tmpCurrentBooking.bookingType = type;
            if ( tmpCurrentBooking.bookingDatetime && !tmpCurrentBooking.bookingDatetimeEnd ) {
                tmpCurrentBooking.bookingDatetimeEnd = dateTimeAdd( tmpCurrentBooking.bookingDatetime, fullService.serviceDuration, "minutes" ).format( "YYYY-MM-DD HH:mm" );
            }
            bookings.push( tmpCurrentBooking );
        } else {
            bookings.push( defaultBooking );
        }
    }
    return bookings;
};
export const getNewSaleItemFromCartItem = ( cart, cartItem, discountType, discountAmount ) => {
    let saleItemType = cartItem.cartItemType || "product";
    const bookingType = "service";
    if ( cartItem.cartItemCourseId ) {
        saleItemType = "other";
    }
    if ( cartItem.cartItemCourseId ) {
        saleItemType = "other";
    }
    if ( cartItem.cartItemType === "service" ) {
        saleItemType = "service";
    }
    let bookings = [];
    if ( cartItem.cartItemBookings && cartItem.cartItemBookings.length > 0 ) {
        cartItem.cartItemBookings.forEach( cartItemBooking => {
            bookings.push( getNewBooking(
                bookingType,
                cartItemBooking.cartBookingTotalAmount,
                cartItemBooking.cartBookingTotalAmountExpenses,
                cartItemBooking.cartBookingBonoId,
                cartItemBooking.cartBookingBonoServiceId,
                cartItemBooking.cartBookingServiceId,
                cartItemBooking.cartBookingSessionNumber,
                cartItemBooking.cartBookingTeamMemberId,
                cartItemBooking.cartBookingDatetime,
                cartItemBooking.cartBookingDatetimeEnd,
                cartItemBooking.cartBookingPetId,
                null,
                "active",
                cartBookingResourcesToBookingResources( cartItemBooking.cartBookingResources )
            ) );
        } );
    } else if ( cartItem.cartItemService && [ "service", "bono" ].includes( saleItemType ) ) {
        bookings = getSaleItemBookings( saleItemType, cartItem.cartItemService, null, cartItem.cartItemQuantity, bookings, cartItem.cartItemService.servicePriceAmount, cartItem.cartItemService.serviceVatIncluded, cartItem.cartItemService.serviceVatPercentage );
    }
    return getNewSaleItem(
        saleItemType,
        cartItem.cartItemTitle,
        cartItem.cartItemService || null,
        cartItem.cartItemBono || null,
        cartItem.cartItemPriceAmount,
        cartItem.cartItemPriceVatIncluded || true,
        cartItem.cartItemQuantity,
        cartItem.cartItemVatPercentage,
        discountAmount || 0,
        discountType || "%",
        0,
        bookings,
        cartItem.cartItemShopItem
    );
};
export const getNewSaleItemFromShopItem = ( shopItem, quantity, discountAmount, discountType ) => getNewSaleItem(
    "product",
    shopItem.title,
    null,
    null,
    shopItem.priceAmount,
    shopItem.priceVatIncluded,
    quantity,
    shopItem.priceVatPercentage,
    discountAmount,
    discountType,
    0,
    [],
    shopItem
);
export const getNewSaleItemFromBono = ( bono, quantity, discountAmount, discountType, services ) => {
    let bookings = [];
    bono.bonoServices.forEach( bonoService => {
        const fullService = services.find( tmp => tmp.serviceId === bonoService.bonoServiceServiceId );
        bookings = bookings.concat( getSaleItemBookings( "bono", fullService, bonoService, bonoService.bonoServiceTotalSessions, [], bonoService.bonoServicePriceAmount, bonoService.bonoServiceVatIncluded, bonoService.bonoServiceVatPercentage ) );
    } );

    return getNewSaleItem(
        "bono",
        bono.bonoTitle,
        null,
        bono,
        bono.bonoPriceAmount,
        bono.bonoVatIncluded,
        quantity,
        bono.bonoVatPercentage,
        discountAmount,
        discountType,
        0,
        bookings,
        null
    );
};
export const saleHasDebt = ( sale ) => {
    if ( sale.saleOutsourced ) {
        // We don't manage external sales debt
        return false;
    }
    if ( sale.saleStatus === "active" && sale.saleTotalAmount && sale.salePaidTotalAmount !== null && sale.saleTotalAmount > sale.salePaidTotalAmount ) {
        if ( sale.saleItems ) {
            for ( let saleItemIndex = 0; saleItemIndex < sale.saleItems.length; saleItemIndex += 1 ) {
                const saleItem = sale.saleItems[ saleItemIndex ];
                if ( saleItem.saleItemActive && saleItem.saleItemTotalAmount > 0 ) {
                    if ( saleItem.saleItemBookings && saleItem.saleItemBookings.length > 0 ) {
                        for ( let bookingIndex = 0; bookingIndex < saleItem.saleItemBookings.length; bookingIndex += 1 ) {
                            const booking = saleItem.saleItemBookings[ bookingIndex ];
                            if ( booking.bookingType === "bono" ) {
                                if ( !booking.bookingPaid ) {
                                    // is bono past booking not paid
                                    return true;
                                }
                            } else {
                                if ( !booking.bookingDatetime ) {
                                    // no booking datetime in a sale with debt, so has debt
                                    return true;
                                }
                                // if ( booking.bookingDatetime && isSameDay( booking.bookingDatetime, now() ) ) {
                                //     // is today booking in a sale with debt, so has debt
                                //     return true;
                                // }
                                if ( booking.bookingDatetime && isPastDateTime( booking.bookingDatetime, `${ today() } 00:00` ) && !booking.bookingPaid ) {
                                    // is past booking in a sale with debt, so has debt
                                    return true;
                                }
                            }
                        }
                        // sale item with future bookings in sale with debt, NO debt
                    } else {
                        // sale item with no bookings in a sale with debt, so has debt
                        return true;
                    }
                }
            }
        }
    }
    // no debt
    return false;
};
export const saleItemBonoTotals = ( saleItem, bono ) => {
    let bonoTotalBookings = 0;
    let bonoTotalUsed = 0;
    let bonoTotalAvailable = 0;
    const bonoServicesTotals = [];
    bono.bonoServices.forEach( tmpBonoService => {
        const serviceTotals = {
            serviceId: tmpBonoService.bonoServiceService.serviceId,
            serviceTitle: tmpBonoService.bonoServiceService.serviceTitle,
            serviceTotalSessions: tmpBonoService.bonoServiceTotalSessions,
            serviceTotalAvailable: 0,
            serviceTotalUsed: 0
        };
        for ( let index = 1; index <= tmpBonoService.bonoServiceTotalSessions; index += 1 ) {
            const sessionBookings = saleItem.saleItemBookings.filter( tmpBooking => tmpBooking.bookingBonoServiceId === tmpBonoService.bonoServiceId && tmpBooking.bookingSessionNumber === index );
            if ( sessionBookings.length > 0 ) {
                sessionBookings.forEach( tmp => {
                    if ( !tmp.bookingDatetime && tmp.bookingStatus === "active" ) {
                        serviceTotals.serviceTotalAvailable += 1;
                    } else {
                        serviceTotals.serviceTotalUsed += 1;
                    }
                } );
            } else {
                serviceTotals.serviceTotalAvailable += 1;
            }
        }
        bonoServicesTotals.push( serviceTotals );
        bonoTotalBookings += serviceTotals.serviceTotalSessions;
        bonoTotalAvailable += serviceTotals.serviceTotalAvailable;
        bonoTotalUsed += serviceTotals.serviceTotalUsed;
    } );
    return {
        bonoTotalBookings, bonoTotalUsed, bonoTotalAvailable, bonoServicesTotals
    };
};
export const updateSaleBookingService = ( sale, oldBooking, newService, allbookings ) => {
    if ( !sale || !sale.saleItems ) {
        return sale;
    }
    let newSale = Object.assign( {}, sale );
    const newSaleItems = sale.saleItems.map( tmpSaleItem => {
        if ( tmpSaleItem.saleItemType === "service" ) {
            if ( tmpSaleItem.saleItemQuantity === 1 && tmpSaleItem.saleItemBookings ) {
                const isBookingInSaleItem = tmpSaleItem.saleItemBookings.find( tmpBooking => tmpBooking.bookingId === oldBooking.bookingId );
                if ( isBookingInSaleItem ) {
                    // we can simply replace the saleItem with a newOne
                    const updatedBooking = Object.assign( {}, oldBooking, {
                        bookingTotalAmount: newService.servicePriceAmount,
                        bookingTotalAmountExpenses: newService.serviceTeamFeeExpenses,
                        bookingServiceId: newService.serviceId,
                        bookingDatetimeEnd: formatDateTimeString( dateTimeAdd( oldBooking.bookingDatetime, newService.serviceDuration, "minutes" ).toDate() )
                    } );
                    const serviceAvailability = serviceResourcesAvailability( updatedBooking.bookingDatetime, updatedBooking.bookingDatetimeEnd, newService, allbookings );
                    if ( serviceAvailability.available ) {
                        updatedBooking.bookingResources = serviceResourcesToBookingResources( serviceAvailability.resources );
                    } else {
                        updatedBooking.bookingResources = serviceResourcesToBookingResources( serviceAvailability.defaultResources );
                    }
                    const newSaleItemBookings = [
                        updatedBooking
                    ];
                    const newSaleItem = getNewSaleItem( tmpSaleItem.saleItemType, newService.serviceTitle, newService, null, newService.servicePriceAmount, newService.serviceVatIncluded, 1, newService.serviceVatPercentage, tmpSaleItem.saleItemDiscountAmount, tmpSaleItem.saleItemDiscountType, newService.serviceTeamFeeExpenses, newSaleItemBookings, null );
                    return newSaleItem;
                }
            }
        }
        if ( tmpSaleItem.saleItemType === "bono" ) {
            const isBookingInSaleItem = tmpSaleItem.saleItemBookings.find( tmpBooking => tmpBooking.bookingId === oldBooking.bookingId );
            if ( isBookingInSaleItem ) {
                // we can simply replace the saleItem with a newOne
                const updatedBooking = Object.assign( {}, oldBooking, {
                    bookingServiceId: newService.serviceId,
                    bookingDatetimeEnd: formatDateTimeString( dateTimeAdd( oldBooking.bookingDatetime, newService.serviceDuration, "minutes" ).toDate() )
                } );
                const serviceAvailability = serviceResourcesAvailability( updatedBooking.bookingDatetime, updatedBooking.bookingDatetimeEnd, newService, allbookings );
                if ( serviceAvailability.available ) {
                    updatedBooking.bookingResources = serviceResourcesToBookingResources( serviceAvailability.resources );
                } else {
                    updatedBooking.bookingResources = serviceResourcesToBookingResources( serviceAvailability.defaultResources );
                }
                const updatedSaleItem = Object.assign( {}, tmpSaleItem, {
                    saleItemBookings: tmpSaleItem.saleItemBookings.map( ( tmpBooking ) => {
                        if ( tmpBooking.bookingId === oldBooking.bookingId ) {
                            return updatedBooking;
                        }
                        return tmpBooking;
                    } )
                } );
                return updatedSaleItem;
            }
        }
        return tmpSaleItem;
    } );
    newSale.saleItems = newSaleItems;
    newSale = updateSalePaidItems( newSale );
    newSale = updateSaleTotals( newSale );
    return newSale;
};
export const getOutgoingTypes = () => {
    const result = [
        { id: "supplier", name: "Pago a proveedor" },
        { id: "team", name: "Pago al personal" },
        { id: "services", name: "Pago de servicios" },
        { id: "taxes", name: "Pago de impuestos" },
        { id: "other", name: "Otros pagos" }
    ];
    return result;
};
export const getPaymentLinkTypes = ( managerCapabilities ) => {
    const result = [
        { id: "24hs_prepayment", name: "Pago de Reserva en 24 horas" },
        { id: "24hs_total", name: "Pago Total Anticipado en 24 horas" },
        { id: "24hsbefore_prepayment", name: "Pago de Reserva (Expira 24 horas antes de la cita)" },
        { id: "24hsbefore_total", name: "Pago Total Anticipado (Expira 24 horas antes de la cita)" },
        { id: "7daysbefore_prepayment", name: "Pago de Reserva (Expira 7 días antes de la cita)" }
    ];
    return result.filter( tmp => !managerCapabilities || !managerCapabilities.paymentLinkTypes || managerCapabilities.paymentLinkTypes.includes( tmp.id ) );
};
export const getOutgoingTypeDesc = ( id ) => {
    let result = "";
    const found = getOutgoingTypes().find( tmp => tmp.id === id );
    if ( found && found.id ) {
        result = found.name;
    }
    return result;
};
export const getIncomeTypes = () => {
    const result = [
        { id: "customer", name: "Ingreso de cliente" },
        { id: "other", name: "Otros ingresos" }
    ];
    return result;
};
export const getIncomeTypeDesc = ( id ) => {
    let result = "";
    const found = getIncomeTypes().find( tmp => tmp.id === id );
    if ( found && found.id ) {
        result = found.name;
    }
    return result;
};

export const getSaleNextBooking = ( sale, skipPaid ) => {
    let nextBooking = null;
    sale.saleItems.forEach( saleItem => {
        if ( saleItem.saleItemBookings ) {
            saleItem.saleItemBookings.forEach( saleItemBooking => {
                if ( saleItemBooking.bookingActive && saleItemBooking.bookingStatus === "active"
                    && ( !nextBooking || saleItemBooking.bookingDatetime < nextBooking.bookingDatetime ) ) {
                    if ( skipPaid && saleItemBooking.bookingPaid ) {
                        // used to select the first booking for reservation
                    } else {
                        nextBooking = Object.assign( {}, saleItemBooking );
                    }
                }
            } );
        }
    } );
    return nextBooking;
};

export const updateBookingsInSale = ( sale, saleBookings ) => {
    let updatedSale = Object.assign( {}, sale );
    updatedSale.saleItems = sale.saleItems.map( saleItem => {
        let updatedSaleItem = Object.assign( {}, saleItem );
        if ( saleItem.saleItemBookings ) {
            updatedSaleItem.saleItemBookings = saleItem.saleItemBookings.map( saleItemBooking => {
                let updatedBooking = saleBookings.find( tmp => tmp.bookingId === saleItemBooking.bookingId );
                if ( updatedBooking ) {
                    return updatedBooking;
                }
                return saleItemBooking;
            } );
        }
        return updatedSaleItem;
    } );
    return updatedSale;
};

export const updateSaleFromAction = ( sale, action, actionValues ) => {
    const saleMutations = {};
    let updatedSale = Object.assign( {}, sale, saleMutations );
    switch ( action ) {
        case "payment_link_activation": {
            // actionValues: currentSale
            const currentSale = actionValues.currentSale;
            if ( currentSale.saleStatus === "canceled" ) {
                updatedSale.saleStatus = "active";
            }
            saleMutations.saleItems = sale.saleItems.map( saleItem => {
                const updatedSaleItem = Object.assign( {}, saleItem );
                if ( saleItem.saleItemBookings ) {
                    updatedSaleItem.saleItemBookings = saleItem.saleItemBookings.map( saleItemBooking => {
                        const updatedSaleItemBooking = Object.assign( {}, saleItemBooking );
                        if ( saleItemBooking.bookingStatus === "canceled" && saleItemBooking.bookingStatusInfo === "canceled_by_payment_link" ) {
                            updatedSaleItemBooking.bookingStatus = "active";
                            updatedSaleItemBooking.bookingStatusInfo = "reactivated_by_payment_link";
                            updatedSaleItem.saleItemActive = 1;
                        }
                        return updatedSaleItemBooking;
                    } );
                }
                return updatedSaleItem;
            } );
            updatedSale = Object.assign( updatedSale, saleMutations );
            break;
        }
        case "credit_invoice": {
            // actionValues: currentSale
            saleMutations.salePayments = sale.salePayments.map( tmpPayment => {
                if ( tmpPayment.salePaymentInvoiceId === actionValues.saleInvoice.invoiceId ) {
                    return Object.assign( {}, tmpPayment, { salePaymentInvoiceId: null } );
                }
                return tmpPayment;
            } );
            saleMutations.saleInvoices = sale.saleInvoices.map( tmpInvoice => {
                if ( tmpInvoice.invoiceId === actionValues.saleInvoice.invoiceId ) {
                    // we need to change the status of the current main invoice
                    return Object.assign( {}, tmpInvoice, { invoiceStatus: "credited", invoiceCreditId: actionValues.creditInvoice.invoiceId } );
                }
                return tmpInvoice;
            } );
            updatedSale = Object.assign( updatedSale, saleMutations );
            break;
        }
        case "sale_item_move": {
            let newSaleItem = Object.assign( {}, actionValues.saleItem );
            newSaleItem.saleItemSaleId = sale.saleId;
            if ( newSaleItem.saleItemBookings ) {
                // updating bookingSaleId
                newSaleItem.saleItemBookings = newSaleItem.saleItemBookings.map( tmpBooking => Object.assign( {}, tmpBooking, { bookingSaleId: sale.saleId } ) );
            }
            updatedSale.saleItems.push( newSaleItem );
            break;
        }
        default: {
            break;
        }
    }
    return updatedSale;
};
export const getNewPaymentLink = ( type, sale, paymentLinkBooking, customer, creatorTeamMemberId, managerCapabilities ) => {
    const saleTotalToPayAmount = sale.saleTotalAmount - sale.salePaidTotalAmount;
    let paymentLinkMaxAmount = 5000;
    let paymentLinkAmount = 2500;
    if ( managerCapabilities && managerCapabilities.paymentLinkPrepaymentAmount ) {
        paymentLinkAmount = managerCapabilities.paymentLinkPrepaymentAmount;
    }
    if ( managerCapabilities && managerCapabilities.paymentLinkPrepaymentMaxAmount ) {
        paymentLinkMaxAmount = managerCapabilities.paymentLinkPrepaymentMaxAmount;
    }
    let paymentLinkExpiration = null;
    let isTotalPayment = false;
    if ( [ "24hs_total", "24hsbefore_total" ].includes( type ) ) {
        isTotalPayment = true;
    }
    const bookingDatetimeNice = paymentLinkBooking && paymentLinkBooking.bookingDatetime ? formatDateTimeNiceShort( paymentLinkBooking.bookingDatetime ) : "";
    let expirationText = "";
    switch ( type ) {
        case "24hs_total":
        case "24hs_prepayment":
            paymentLinkExpiration = formatDateTimeString( dateTimeAdd( now(), 24, "hours" ) );
            expirationText = "en las próximas 24 horas";
            break;
        case "24hsbefore_total":
        case "24hsbefore_prepayment":
            if ( paymentLinkBooking && paymentLinkBooking.bookingDatetime ) {
                paymentLinkExpiration = formatDateTimeString( dateTimeSubtract( paymentLinkBooking.bookingDatetime, 24, "hours" ) );
                expirationText = "24 horas antes de la cita";
            } else {
                paymentLinkExpiration = formatDateTimeString( dateTimeAdd( now(), 48, "hours" ) );
                expirationText = "en 48 horas";
            }
            break;
        case "7daysbefore_prepayment":
            if ( paymentLinkBooking && paymentLinkBooking.bookingDatetime ) {
                paymentLinkExpiration = formatDateTimeString( dateTimeSubtract( paymentLinkBooking.bookingDatetime, 7, "days" ) );
                expirationText = "1 semana antes de la cita";
            } else {
                paymentLinkExpiration = formatDateTimeString( dateTimeAdd( now(), 48, "hours" ) );
                expirationText = "en 48 horas";
            }
            break;
        default:
            break;
    }
    const paymentLinkHtmlDescription = stringReplacements( managerCapabilities.paymentLinkDescription, {
        customerFullName: customer ? customer.customerFullName : "",
        bookingDatetime: bookingDatetimeNice,
        expirationText
    } );
    if ( saleTotalToPayAmount < paymentLinkAmount ) {
        paymentLinkAmount = saleTotalToPayAmount;
    } else if ( saleTotalToPayAmount < paymentLinkMaxAmount ) {
        paymentLinkAmount = saleTotalToPayAmount;
    }
    if ( isTotalPayment ) {
        paymentLinkAmount = saleTotalToPayAmount;
    }
    const newPaymentLink = {
        isNew: true,
        paymentLinkTitle: `Pago por cita del ${ bookingDatetimeNice }`,
        paymentLinkHtmlDescription,
        paymentLinkCustomerId: sale.saleCustomerId,
        paymentLinkCreatorTeamMemberId: creatorTeamMemberId,
        paymentLinkLinkedType: "sale",
        paymentLinkLinkedId: sale.saleId,
        paymentLinkCurrency: "EUR",
        paymentLinkTotalAmount: paymentLinkAmount,
        paymentLinkExpirationDatetime: paymentLinkExpiration,
        paymentLinkStatus: "active",
        paymentLinkPaymentMethod: managerCapabilities.paymentLinksPaymentMethod,
        paymentLinkBookingId: paymentLinkBooking ? paymentLinkBooking.bookingId : null,
        paymentLinkCancelSale: 1,
        paymentLinkPaid: 0,
        paymentLinkActive: 1
    };
    return newPaymentLink;
};
export const getSaleChanges = ( sale, oldSale ) => {
    let html = "";
    let saleBookings = getSaleBookings( sale );
    let oldSaleBookings = getSaleBookings( oldSale );
    if ( sale.salePaidTotalAmount && sale.salePaidTotalAmount > oldSale.salePaidTotalAmount ) {
        html += "<p class=\"pw_sale_change_changed\">Se ha registrado un pago en la venta</p>";
    }
    saleBookings.forEach( booking => {
        let oldBooking = oldSaleBookings.find( tmp => tmp.bookingId === booking.bookingId );
        if ( oldBooking && booking.bookingStatus !== oldBooking.bookingStatus ) {
            if ( booking.bookingStatus === "canceled" ) {
                html += `<p class="pw_sale_change_canceled">La cita ${ booking.bookingSessionNumber } de ${ booking.bookingService.serviceTitle }${ booking.bookingType === "bono" ? " (Bono)" : "" } ha sido <b>cancelada</b></p>`;
            }
        }
        if ( oldBooking && booking.bookingDatetime !== oldBooking.bookingDatetime ) {
            html += `<p class="pw_sale_change_changed">La cita ${ booking.bookingSessionNumber } de ${ booking.bookingService.serviceTitle }${ booking.bookingType === "bono" ? " (Bono)" : "" } ha cambiado de fecha de <span class="pw_sale_change_old_value">${ oldBooking.bookingDatetime ? formatDateTimeNiceShort( oldBooking.bookingDatetime ) : "sin cita" }</span> a <span class="pw_sale_change_new_value">${ booking.bookingDatetime ? formatDateTimeNiceShort( booking.bookingDatetime ) : "sin cita" }</span></p>`;
        }
        if ( oldBooking && booking.bookingConfirmed && !oldBooking.bookingConfirmed ) {
            html += `<p class="pw_sale_change_changed">Se ha confirmado la cita ${ booking.bookingSessionNumber } de ${ booking.bookingService.serviceTitle }${ booking.bookingType === "bono" ? " (Bono)" : "" } de la fecha <span class="pw_sale_change_new_value">${ booking.bookingDatetime ? formatDateTimeNiceShort( booking.bookingDatetime ) : "sin cita" }</span></p>`;
        }
    } );
    return { html };
};
export const updateSaleStatus = ( sale ) => {
    let updatedSale = Object.assign( {}, sale );
    // saleStatus (planning, active, canceled, closed), saleStatusInvoice (partially_invoiced, invoiced) and saleStatusPayment (partially_paid, paid)
    updatedSale.saleStatusPayment = "notpaid";
    updatedSale.saleStatusInvoice = "notinvoiced";
    if ( sale.salePaidTotalAmount > 0 ) {
        // saleStatusPayment
        if ( sale.saleTotalAmount <= sale.salePaidTotalAmount ) {
            updatedSale.saleStatusPayment = "paid";
        } else if ( sale.salePaidTotalAmount > 0 ) {
            updatedSale.saleStatusPayment = "partially_paid";
        }
    }
    // saleStatusInvoice
    if ( sale.saleInvoices ) {
        for ( let index = 0; index < sale.saleInvoices.length; index += 1 ) {
            let tmpSaleInvoice = sale.saleInvoices[ index ];
            // totally invoiced
            if ( tmpSaleInvoice.invoiceType !== "credit" && tmpSaleInvoice.invoiceStatus === "active" && tmpSaleInvoice.invoiceIsMainInvoice && tmpSaleInvoice.invoiceActive ) {
                updatedSale.saleStatusInvoice = "invoiced";
                break;
            }
            // partially invoiced
            if ( tmpSaleInvoice.invoiceType !== "credit" && tmpSaleInvoice.invoiceStatus === "active" && tmpSaleInvoice.invoiceActive ) {
                updatedSale.saleStatusInvoice = "partially_invoiced";
                break;
            }
        }
    }
    if ( sale.saleStatus === "active" && updatedSale.saleStatusInvoice === "invoiced" && updatedSale.saleStatusPayment === "paid" ) {
        updatedSale.saleStatus = "closed";
    }
    if ( sale.saleStatus === "closed" && ( updatedSale.saleStatusInvoice === "notinvoiced" || updatedSale.saleStatusPayment !== "paid" ) ) {
        updatedSale.saleStatus = "active";
    }
    return updatedSale;
};

export const getNewPromoCode = ( type, code, limitUseCustomer, limitUseTotal, datetimeStart, datetimeEnd, discountType, discountAmount, codeRules ) => {
    // type: global, customer, multiple_customers, service_category, shopitem_category, custom
    const result = {
        tmpId: uuid(),
        promoCodeType: type,
        promoCodeCode: code,
        promoCodeTitle: null,
        promoCodeDescription: null,
        promoCodeLimitUseCustomer: limitUseCustomer,
        promoCodeLimitUseTotal: limitUseTotal,
        promoCodeTotalUsed: 0,
        promoCodeInternalNote: null,
        promoCodeDatetimeStart: datetimeStart || now(),
        promoCodeDatetimeEnd: datetimeEnd || null,
        promoCodeDiscountType: discountType || "%",
        promoCodeDiscountAmount: discountAmount || 0,
        promoCodeStatus: "active",
        promoCodeWebFeatured: 0,
        promoCodeActive: 1,
        promoCodeRules: codeRules || []
    };
    return result;
};

export const getNewPromoCodeRule = ( type, linkedId ) => {
    // types: customer, service_category, shopitem_category
    const defaultPromoCodeRule = {
        tmpId: uuid(), promoCodeRuleType: type, promoCodeRuleLinkedId: linkedId, promoCodeRuleActive: 1
    };
    return defaultPromoCodeRule;
};

export const isPrepaymentAvailable = ( sale, prepaymentAmount ) => {
    if ( !prepaymentAmount ) {
        return false;
    }
    if ( sale.saleTotalAmount < prepaymentAmount ) {
        return false;
    }
    if ( sale.salePaidTotalAmount ) {
        return false;
    }
    let totalToPay = sale.saleTotalAmount - sale.salePaidTotalAmount;
    if ( totalToPay <= prepaymentAmount + 5 ) {
        return false;
    }
    let hasServices = sale.saleItems.find( argItem => {
        if ( argItem.saleItemType === "bono" ) {
            return true;
        }
        if ( argItem.saleItemType === "service" ) {
            return true;
        }
        return false;
    } );
    if ( !hasServices ) {
        return false;
    }
    return true;
};

export const geOutsourcedSaleValues = ( sale, services, bonos ) => {
    let result = {
        outsourced: false,
        hasOutsourcedItems: false,
        hasOwnItems: false
    };
    if ( sale.saleDate && sale.saleDate < "2023-03-01" ) {
        return result;
    }
    let outsourcedServices = services.filter( tmp => tmp.serviceOutsourced );
    if ( sale.saleItems ) {
        sale.saleItems.forEach( saleItem => {
            switch ( saleItem.saleItemType ) {
                case "service": {
                    let outsourcedFound = outsourcedServices.find( tmpService => tmpService.serviceId === saleItem.saleItemServiceId );
                    if ( outsourcedFound ) {
                        result.hasOutsourcedItems = true;
                    } else {
                        result.hasOwnItems = true;
                    }
                    break;
                }
                case "bono": {
                    let bono = bonos.find( tmpBono => tmpBono.bonoId === saleItem.saleItemBonoId );
                    let outsourcedFound = outsourcedServices.find( tmpService => bono.bonoServices.find( tmpBonoService => tmpBonoService.bonoServiceServiceId === tmpService.serviceId ) );
                    let ownItemsFound = bono.bonoServices.find( tmpBonoService => !outsourcedServices.find( tmpService => tmpBonoService.bonoServiceServiceId === tmpService.serviceId ) );
                    if ( outsourcedFound ) {
                        result.hasOutsourcedItems = true;
                    }
                    if ( ownItemsFound ) {
                        result.hasOwnItems = true;
                    }
                    break;
                }
                case "product": {
                    result.hasOwnItems = true;
                    break;
                }
                default: {
                    break;
                }
            }
        } );
    }
    result.outsourced = result.hasOutsourcedItems;
    return result;
};
export const getSaleName = ( sale ) => `${ formatDateNiceShort( sale.saleDate ) } (${ formatIntegerPrice( "EUR", sale.saleTotalAmount, true ) })`;

export const getSaleBadges = ( sale, from ) => {
    let badges = [];
    if ( sale.saleOutsourced ) {
        if ( from === "manager" ) {
            badges.push( { variant: "danger", text: "Externo" } );
        }
        if ( sale.saleStatus === "canceled" ) {
            badges.push( { variant: "danger", text: "Anulada" } );
        } else {
            if ( sale.saleOutsourcedStatus === "pending" ) {
                badges.push( { variant: "warning", text: "En revisión", textColor: "dark" } );
            }
            if ( from === "manager" ) {
                if ( sale.saleOutsourcedStatus === "active" ) {
                    badges.push( { variant: "primary", text: "Activa" } );
                }
                if ( sale.saleOutsourcedStatus === "paid" ) {
                    badges.push( { variant: "success", text: "Pagada" } );
                }
            }
        }
    } else {
        // standard sale
        switch ( sale.saleStatus ) {
            case "closed": {
                if ( from === "manager" ) {
                    badges.push( { variant: "success", text: "Cerrada" } );
                }
                break;
            }
            case "active": {
                if ( from === "manager" ) {
                    badges.push( { variant: "info", text: "Iniciada" } );
                }
                break;
            }
            case "queued": {
                badges.push( { variant: "secondary", text: "En Espera" } );
                break;
            }
            case "canceled": {
                badges.push( { variant: "danger", text: "Anulada" } );
                break;
            }
            default:
                if ( sale.saleStatus ) {
                    badges.push( { variant: "light", textColor: "dark", text: sale.saleStatus } );
                }
        }
        if ( from === "manager" ) {
            switch ( sale.saleStatusInvoice ) {
                case "notinvoiced": {
                    badges.push( { variant: "secondary", text: "Sin facturar" } );
                    break;
                }
                case "partially_invoiced": {
                    badges.push( { variant: "warning", text: "Factura parcial" } );
                    break;
                }
                case "invoiced": {
                    badges.push( { variant: "success", text: "Facturada" } );
                    break;
                }
                default:
                    if ( sale.saleStatusInvoice ) {
                        badges.push( { variant: "light", textColor: "dark", text: sale.saleStatusInvoice } );
                    }
            }
        }
        switch ( sale.saleStatusPayment ) {
            case "notpaid": {
                badges.push( { variant: "secondary", text: "Sin Pagos" } );
                break;
            }
            case "partially_paid": {
                badges.push( { variant: "warning", text: "Pago Parcial" } );
                break;
            }
            case "paid": {
                badges.push( { variant: "success", text: "Pagada" } );
                break;
            }
            default:
                if ( sale.saleStatusPayment ) {
                    badges.push( { variant: "light", textColor: "dark", text: sale.saleStatusPayment } );
                }
        }
    }
    if ( from === "manager" ) {
        if ( sale.saleShopCartId ) {
            badges.push( { variant: "info", text: "WEB" } );
        }
    }
    if ( sale.saleInStorePickUp ) {
        badges.push( { variant: "info", text: "Recogida en tienda" } );
    }
    let saleDiscountText = "";
    if ( sale.saleDiscountAmount ) {
        saleDiscountText = `${ sale.saleDiscountType } ${ formatIntegerPriceTwoDecimals( sale.saleDiscountAmount, true ) }`;
    }
    if ( saleDiscountText ) {
        badges.push( { variant: "info", text: saleDiscountText } );
    }
    return badges;
};

export const getCustomerDuplicates = ( customer, allCustomers ) => {
    let duplicates = [];
    if ( customer.customerCameFrom === "web" && customer.customerEmail && customer.customerEmailVerified ) {
        // only from customers created from web (with a verified email) so we merge others into this one to keep existing user credentials
        let tmpCustomerMobile = formatInternationalMobile( customer.customerMobile );
        for ( let index = 0; index < allCustomers.length; index += 1 ) {
            let candidate = allCustomers[ index ];
            let isDuplicate = false;
            let duplicateType = null;
            let duplicateTypeInfo = null;
            // if same customer, ignore it
            if ( candidate.customerId !== customer.customerId && candidate.customerActive ) {
                // same mobile
                if ( candidate.customerMobile && customer.customerMobile && formatInternationalMobile( candidate.customerMobile ) === tmpCustomerMobile ) {
                    isDuplicate = true;
                    duplicateType = "mobile";
                }
                // same email
                if ( candidate.customerEmail && customer.customerEmail && candidate.customerEmail === customer.customerEmail ) {
                    isDuplicate = true;
                    duplicateType = "email";
                }
                // in case of the user creating 2 different accounts
                if ( isDuplicate && candidate.customerCameFrom === "web" ) {
                    let latestSale = null;
                    isDuplicate = false;
                    duplicateType = null;
                    customer.customerSales.forEach( tmpSale => {
                        if ( !latestSale ) {
                            latestSale = tmpSale;
                        } else if ( tmpSale.saleDatetimeCreation > latestSale.saleDatetimeCreation ) {
                            latestSale = tmpSale;
                        }
                    } );
                    // the user has created 2 different accounts, so we can check what's the latest web sale and we use that account as the main one
                    let latestCandidateSale = null;
                    candidate.customerSales.forEach( tmpCandidateSale => {
                        if ( !latestCandidateSale ) {
                            latestCandidateSale = tmpCandidateSale;
                        } else if ( tmpCandidateSale.saleDatetimeCreation > latestCandidateSale.saleDatetimeCreation ) {
                            latestCandidateSale = tmpCandidateSale;
                        }
                    } );
                    if ( latestSale && latestCandidateSale && latestSale.saleDatetimeCreation > latestCandidateSale.saleDatetimeCreation ) {
                        // the candidate is web, and has sales like the customer, but the main customer item has the latest sale, so we can merge into it
                        isDuplicate = true;
                        duplicateType = "duplicateaccount_latestsales";
                        duplicateTypeInfo = `main: ${ latestSale.saleDatetimeCreation } candidate: ${ latestCandidateSale.saleDatetimeCreation }`;
                    } else if ( !latestCandidateSale ) {
                        // the candidate doesn't have sales, so we can merge into the main customer
                        isDuplicate = true;
                        duplicateType = "duplicateaccount_nosales";
                    }
                }
                if ( isDuplicate ) {
                    duplicates.push( Object.assign( {}, candidate, { duplicateType, duplicateTypeInfo } ) );
                }
            }
        }
    }
    return duplicates;
};
