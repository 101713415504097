import { apiFetchShopCategoryItems, apiFetchShopItems } from "../api";

export const shopStateData = ( argData ) => ( dispatch ) => {
    dispatch( { type: "SHOP_DATA_FETCH_SUCCESS", data: argData } );
};
export const getShopCategoryItems = ( categoryId ) => ( dispatch ) => {
    dispatch( { type: "SHOP_CATEGORY_ITEMS_ATTEMPT" } );
    apiFetchShopCategoryItems( categoryId )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SHOP_CATEGORY_ITEMS_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "SHOP_CATEGORY_ITEMS_ERROR" } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SHOP_CATEGORY_ITEMS_ERROR" } );
        } );
};
export const getShopItems = ( categoryId, searchText, brandId ) => ( dispatch ) => {
    dispatch( { type: "SHOP_CATEGORY_ITEMS_ATTEMPT" } );
    apiFetchShopItems( categoryId, searchText, brandId )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SHOP_CATEGORY_ITEMS_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "SHOP_CATEGORY_ITEMS_ERROR" } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SHOP_CATEGORY_ITEMS_ERROR" } );
        } );
};
