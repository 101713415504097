import React from "react";
import { Table, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavBarAccount from "./layout/NavBarAccount";
import * as SessionActions from "../actions/SessionActions";
import * as CheckoutActions from "../actions/CheckoutActions";
import { shopStateData } from "../actions/ShopActions";
import * as DataUtils from "../utils/DataUtils";
import * as Pages from "../utils/Pages";
import WebContext from "../utils/WebContext";
import { loadUIContext } from "../actions/UIContextActions";

class GenericMyShopCarts extends React.Component {
    constructor ( props ) {
        super( props );
        this.page = Pages.getPageByPath( props.match.path );
        this.isLoading = true;
        DataUtils.checkToken( props.history );
    }
    renderLoading() {
        if ( !this.isLoading ) {
            return null;
        }
        return (
            <div className="pw-dashboard-loading">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Obteniendo datos...</span>
                </Spinner>
            </div>
        );
    }
    render() {
        if ( !this.props.UIContext.loaded || this.props.pwSession.isRehydrating ) {
            return this.renderLoading();
        }
        return (
            <div id="pw_my_shop_carts">
                <NavBarAccount />
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Fecha</th>
                            <th>Total</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        { this.props.pwSession.carts.map( userCart => (
                            <tr>
                                <td>{ userCart.cartCode }</td>
                                <td>{ userCart.cartDatetimeCreation }</td>
                                <td>{ DataUtils.formatIntegerPrice( this.props.shop.currency, userCart.cartTotalAmount ) }</td>
                                <td><a href={ `${ Pages.getPage( "shop-purchase" ).relativeUrl }?code=${ userCart.cartCode }&email=${ userCart.cartEmail }&provider=pw` }>Ver detalle</a></td>
                            </tr>
                        ) ) }
                    </tbody>
                </Table>
            </div>
        );
    }
}

GenericMyShopCarts.contextType = WebContext;
GenericMyShopCarts.serverFetch = shopStateData;
GenericMyShopCarts.serverFetchType = { type: "data", module: "shop" };

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    shop: state.shop
} );

const mapDispatchToProps = Object.assign( {}, SessionActions, CheckoutActions, loadUIContext );
export default connect( mapStateToProps, mapDispatchToProps )( withRouter( GenericMyShopCarts ) );
