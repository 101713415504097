/* eslint-disable class-methods-use-this */
/* eslint-disable complexity */
/* eslint-disable prefer-destructuring */
import React from "react";
import { Form, Offcanvas, Button, ButtonGroup, InputGroup, FormControl, Row, Alert, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "./widgets/Select";
import Icon from "./widgets/Icon";
import ExpandCollapse from "./widgets/ExpandCollapse";
import GenericDynamicContent from "./GenericDynamicContent";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import * as ContentActions from "../actions/ContentActions";
import { shopStateData } from "../actions/ShopActions";
import * as DataUtils from "../utils/DataUtils";
import * as ShopUtils from "../utils/ShopUtils";
import * as CalendarUtils from "../utils/CalendarUtils";
import { getSaleItemBookings } from "../utils/SalesUtils";
import { getPage, applyShopOverides, company } from "../utils/Pages";
import WebContext from "../utils/WebContext";

class ShopCartOffcanvasBooking extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            showAvailableHoursForSession: null
        };
        this.bookingMinTime = new Date();
        this.bookingMinTime.setHours( 9 );
        this.bookingMinTime.setMinutes( 0 );
        this.bookingMaxTime = new Date();
        this.bookingMaxTime.setHours( 22 );
        this.bookingMaxTime.setMinutes( 0 );
        this.getCartItemBookings = this.getCartItemBookings.bind( this );
        this.renderAvailableDays = this.renderAvailableDays.bind( this );
        this.onSelectedAvailableDatetime = this.onSelectedAvailableDatetime.bind( this );
        this.filterDateOrTime = this.filterDateOrTime.bind( this );
        this.getAllBookings = this.getAllBookings.bind( this );
        this.continueNextStep = this.continueNextStep.bind( this );
        this.renderShopSuggestionsStep = this.renderShopSuggestionsStep.bind( this );
        this.renderBookingStep = this.renderBookingStep.bind( this );
        this.renderServicesStep = this.renderServicesStep.bind( this );
        this.getAvailableDays = this.getAvailableDays.bind( this );
        this.renderLinkedServices = this.renderLinkedServices.bind( this );
        this.renderAvailabilityLoading = this.renderAvailabilityLoading.bind( this );
        this.availableDaysCache = [];
    }

    componentDidMount() {
        this.props.loadUIContext();
    }
    componentDidUpdate( prevProps ) {
        if ( prevProps.pwSession.shopAttributes.isEditingShopBookingEditingId !== this.props.pwSession.shopAttributes.isEditingShopBookingEditingId ) {
            this.onOpeningBookingStep();
        }
    }
    onOpeningBookingStep() {
        console.log( "OPENING BOOKING STEP" );
    }
    onSelectedAvailableDatetime( cartItem, argBooking, date, saleItem ) {
        let tmpDatetimeStart = null;
        let tmpDatetimeEnd = null;
        let teamMembers = [];
        const booking = Object.assign( {}, argBooking );
        let bookingTeamMember = null;
        if ( booking.bookingTeamMemberId ) {
            bookingTeamMember = this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === booking.bookingTeamMemberId );
        }
        let fullService = cartItem ? cartItem.service : null;
        let linkedServices = [];
        let preferredTeamMemberId = cartItem ? cartItem.teamMemberId : null;
        if ( saleItem ) {
            if ( saleItem.saleItemType === "bono" ) {
                fullService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === argBooking.bookingServiceId );
            } else {
                fullService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === saleItem.saleItemServiceId );
            }
            preferredTeamMemberId = this.props.pwSession.shopAttributes.editingShopBooking ? this.props.pwSession.shopAttributes.editingShopBooking.teamMemberId : null;
        }
        if ( ( !bookingTeamMember && fullService.serviceTeamMembers ) || ( bookingTeamMember.teamMemberId !== preferredTeamMemberId ) ) {
            teamMembers = fullService.serviceTeamMembers.filter( serviceTeamMember => serviceTeamMember.serviceTeamMemberActive === 1 && serviceTeamMember.teamMember && serviceTeamMember.teamMember.teamMemberActive && serviceTeamMember.teamMember.teamMemberCalendarActive ).map( tmpServiceTeamMember => tmpServiceTeamMember.teamMember );
            teamMembers = teamMembers.map( tmpTeamMember => this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === tmpTeamMember.teamMemberId ) );
            if ( !preferredTeamMemberId ) {
                teamMembers = DataUtils.shuffleArray( teamMembers );
            }
            teamMembers.forEach( tmpMember => {
                if ( tmpMember && ( tmpMember.teamMemberId === preferredTeamMemberId || !preferredTeamMemberId ) ) {
                    if ( this.filterDateOrTime( date, false, fullService, booking, tmpMember.teamMemberId ) ) {
                        booking.bookingTeamMemberId = tmpMember.teamMemberId;
                        bookingTeamMember = tmpMember;
                    }
                }
            } );
        }
        if ( ( date && bookingTeamMember ) || DataUtils.formatTimeString( date ) === "00:00" ) {
            tmpDatetimeStart = DataUtils.formatDateTimeString( date );
            tmpDatetimeEnd = DataUtils.formatDateTimeString( DataUtils.dateTimeAdd( tmpDatetimeStart, fullService.serviceDuration || 0, "minutes" ) );
        }
        if ( !date ) {
            booking.bookingTeamMemberId = null;
        }
        const newBooking = Object.assign( {}, booking, { bookingDatetime: tmpDatetimeStart, bookingDatetimeEnd: tmpDatetimeEnd } );
        let newBookings = [];
        // now removing the current booking using filter to add it below
        if ( cartItem ) {
            newBookings = this.props.pwSession.shop.cartBookings.filter( tmpArgBooking => tmpArgBooking.bookingServiceId !== newBooking.bookingServiceId || tmpArgBooking.bookingSessionNumber !== newBooking.bookingSessionNumber );
        }
        if ( saleItem ) {
            newBookings = this.props.content.sales.saleBookings.filter( tmpArgBooking => tmpArgBooking.bookingId !== newBooking.bookingId );
        }
        if ( newBooking.bookingDatetime ) {
            const serviceAvailability = CalendarUtils.serviceResourcesAvailability( newBooking.bookingDatetime, newBooking.bookingDatetimeEnd, fullService, this.getAllBookings( null, cartItem, saleItem ) );
            newBooking.bookingResources = CalendarUtils.serviceResourcesToBookingResources( serviceAvailability.available ? serviceAvailability.resources : serviceAvailability.defaultResources, newBooking.bookingDatetime, newBooking.bookingDatetimeEnd, null );
        }
        newBookings.push( newBooking );
        if ( cartItem ) {
            this.props.shopUpdateBookings( newBookings );
        }
        if ( saleItem ) {
            this.props.changeContentSalesAttr( "saleBookings", newBookings );
        }
        if ( fullService && fullService.serviceLinkedServices ) {
            linkedServices = fullService.serviceLinkedServices;
        }
        this.setState( { showAvailableHoursForSession: null, linkedServices } );
    }
    getAllBookings( currentBooking, cartItem, saleItem ) {
        let otherCartBookings = [];
        if ( cartItem ) {
            otherCartBookings = this.props.pwSession.shop.cartBookings.filter( tmpBooking => tmpBooking !== currentBooking );
        }
        if ( saleItem ) {
            otherCartBookings = this.props.content.sales.saleBookings.filter( tmpBooking => tmpBooking !== currentBooking );
        }
        const editingBookings = otherCartBookings.filter( tmp => tmp.bookingDatetime && tmp.bookingDatetimeEnd );
        return this.props.pwSession.shopAttributes.bookedSlots.concat( editingBookings );
    }
    getCartItemBookings( cartItem, saleItem ) {
        let fullService = cartItem ? cartItem.service : null;
        let itemType = cartItem ? cartItem.type : null;
        let itemQuantity = cartItem ? cartItem.quantity : null;
        let itemPriceAmount = cartItem ? cartItem.priceAmount : null;
        let itemPriceVatIncluded = cartItem ? cartItem.priceVatIncluded : null;
        let itemVatPercentage = cartItem ? cartItem.priceVatPercentage : null;
        if ( saleItem ) {
            fullService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === saleItem.saleItemServiceId );
            itemType = saleItem.saleItemType;
            itemQuantity = saleItem.saleItemQuantity;
            itemPriceAmount = saleItem.saleItemPriceAmount;
            itemPriceVatIncluded = saleItem.saleItemVatIncluded;
            itemVatPercentage = saleItem.saleItemVatPercentage;
        }
        let bonoService = null;
        if ( itemType === "bono" ) {
            // TODO: add support for bonos from cartItems and saleItems
            if ( cartItem ) {
                bonoService = cartItem.service;
                fullService = bonoService.bonoServiceService;
            }
        }
        let bookings = cartItem ? this.props.pwSession.shop.cartBookings : [];
        let result = [];
        if ( saleItem ) {
            bookings = this.props.content.sales.saleBookings;
            const saleItemBookings = saleItem.saleItemBookings.map( tmp => {
                const edited = this.props.content.sales.saleBookings.find( tmpEdited => tmpEdited.bookingId === tmp.bookingId );
                if ( edited ) {
                    return edited;
                }
                return tmp;
            } );
            result = saleItemBookings;
        } else {
            result = getSaleItemBookings( itemType, fullService, bonoService, itemQuantity, bookings, itemPriceAmount, itemPriceVatIncluded, itemVatPercentage );
        }
        if ( this.props.pwSession.shopAttributes.editingShopBooking.showOnlyBooking ) {
            result = result.filter( argBooking => argBooking.bookingId === this.props.pwSession.shopAttributes.editingShopBooking.showOnlyBooking.bookingId );
        }
        return result.map( tmpBooking => Object.assign( {}, tmpBooking, { availableDaysData: this.getAvailableDays( cartItem, tmpBooking, saleItem ) } ) );
    }
    getAvailableDays ( cartItem, booking, saleItem ) {
        let cacheKey = { cartItem, booking, saleItem };
        let cacheFound = this.availableDaysCache.find( tmp => DataUtils.isSameObject( tmp.key, cacheKey ) );
        if ( cacheFound ) {
            return cacheFound.result;
        }
        let fullService = cartItem ? cartItem.service : null;
        let teamMemberId = cartItem ? cartItem.teamMemberId : null;
        if ( saleItem ) {
            if ( saleItem.saleItemType === "bono" ) {
                fullService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === booking.bookingServiceId );
            } else {
                fullService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === saleItem.saleItemServiceId );
            }
            teamMemberId = this.props.pwSession.shopAttributes.editingShopBooking ? this.props.pwSession.shopAttributes.editingShopBooking.teamMemberId : null;
        }
        // all conflicting bookings
        let editingBookings = this.props.pwSession.shop.cartBookings;
        if ( this.props.content.sales.saleBookings ) {
            editingBookings = editingBookings.concat( this.props.content.sales.saleBookings );
        }
        const allConflictingBookings = CalendarUtils.getAllConflictingBookings( booking, editingBookings, this.props.pwSession.shopAttributes.bookedSlots );
        // availableDays
        let availableDaysData = CalendarUtils.getServiceAvailableDays( fullService, this.props.pwSession.shopAttributes.teamMembers, teamMemberId, allConflictingBookings );
        // updating cache
        this.availableDaysCache.push( { key: cacheKey, result: availableDaysData } );
        return availableDaysData;
    }
    filterDateOrTime( date, isDay, fullService, booking, teamMemberId ) {
        const tomorrow = DataUtils.dateTimeAdd( DataUtils.now(), 1, "days" );
        if ( DataUtils.isPastDateTime( date, tomorrow ) ) {
            return false;
        }
        if ( !fullService || !fullService.serviceDuration ) {
            return false;
        }
        if ( !fullService.serviceTeamMembers || fullService.serviceTeamMembers.length === 0 ) {
            return false;
        }
        if ( this.props.pwSession.shopAttributes.isLoadingBookedSlots ) {
            return false;
        }

        let editingBookings = this.props.pwSession.shop.cartBookings;
        if ( this.props.content.sales.saleBookings ) {
            editingBookings = editingBookings.concat( this.props.content.sales.saleBookings );
        }
        const allConflictingBookings = CalendarUtils.getAllConflictingBookings( booking, editingBookings, this.props.pwSession.shopAttributes.bookedSlots );
        return CalendarUtils.isDateAvailableForService( date, isDay, fullService, this.props.pwSession.shopAttributes.teamMembers, teamMemberId, allConflictingBookings );
    }
    continueNextStep() {
        const calledFrom = this.props.calledFrom;
        switch ( calledFrom ) {
            case "shopcart":
            case "salelink": {
                this.props.closeShopBookingOffcanvas();
                break;
            }
            case "shop": {
                if ( this.props.pwSession.shopAttributes.isEditingShopBookingStep === "shopSuggestions" ) {
                    window.location.href = getPage( "shop-cart-checkout" ).relativeUrl;
                } else {
                    this.props.changeShopCartAttr( "isEditingShopBookingStep", "shopSuggestions" );
                }
                break;
            }
            default: {
                this.props.closeShopBookingOffcanvas();
                break;
            }
        }
    }
    renderStepsInfo( current, maxStepDone ) {
        return (
            <ol className="pw_wizard_steps steps-3">
                <li className={ `${ current === 1 ? "current" : "" } ${ maxStepDone === 1 ? "done" : "" }` }>Servicio</li>
                <li className={ `${ current === 2 ? "current" : "" } ${ maxStepDone === 2 ? "done" : "" }` }>Fecha/Hora</li>
                <li className={ `${ current === 3 ? "current" : "" } ${ maxStepDone === 3 ? "done" : "" }` }>Confirmación</li>
            </ol>
        );
    }
    renderServicesStep() {
        if ( this.props.pwSession.shopAttributes.isEditingShopBookingStep !== "services" ) {
            return null;
        }
        const categories = []; // in sync
        const categoriesLookupTable = [];
        const searchText = DataUtils.removeAccents( this.props.pwSession.shopAttributes.isEditingShopBookingServiceSearchText.trim() );
        this.props.content.shop.categories.filter( tmp => tmp.type === "services" ).forEach( tmp => {
            const category = applyShopOverides( tmp );
            category.items = [];
            categories.push( category );
            categoriesLookupTable[ category.id ] = category;
        } );
        const searchRegExp = new RegExp( searchText, "i" );
        let expandAll = false;
        if ( searchText !== "" ) {
            expandAll = true;
        }
        this.props.shop.items.forEach( shopItem => {
            if ( categoriesLookupTable[ shopItem.categoryId ] ) {
                let ok = false;
                if ( searchText !== "" ) {
                    if ( shopItem.title && DataUtils.removeAccents( shopItem.title ).match( searchRegExp ) ) {
                        ok = true;
                    }
                    if ( shopItem.shortText && DataUtils.removeAccents( shopItem.shortText ).match( searchRegExp ) ) {
                        ok = true;
                    }
                } else {
                    // empty search text
                    ok = true;
                }
                if ( ok ) {
                    categoriesLookupTable[ shopItem.categoryId ].items.push( shopItem );
                }
            }
        } );
        return (
            <Offcanvas.Body>
                { this.renderStepsInfo( 1, 0 ) }
                <ButtonGroup className="pw_fullwidth">
                    <Button variant="secondary" onClick={ () => this.props.closeShopBookingOffcanvas() }>Cerrar</Button>
                </ButtonGroup>
                <h4>Seleccionar servicio según su categoría:</h4>
                <div className="pw_offcanvas_topform">
                    <div className="pw_offcanvas_topform_filters">
                        <Form>
                            <Row>
                                <Form.Control
                                    ref={ ( input ) => {
                                        this.searchInput = input;
                                    } }
                                    placeholder="Buscar ..."
                                    onChange={ ( e ) => this.props.changeShopCartAttr( "isEditingShopBookingServiceSearchText", e.target.value ) }
                                />
                            </Row>
                        </Form>
                    </div>
                </div>
                <div>
                    { categories.filter( tmp => tmp.items.length > 0 ).map( tmp => {
                        if ( !tmp ) {
                            console.log( "ERR: empty shop item" );
                        }
                        return (
                            <ExpandCollapse
                                key={ tmp.id }
                                title={ `${ tmp.name } (${ tmp.items.length } servicios)` }
                                expandIcon={ <Icon name="nav-arrow-down" /> }
                                collapseIcon={ <Icon name="nav-arrow-up" /> }
                                expanded={ expandAll }
                            >
                                { tmp.items.map( tmpShopItem => {
                                    const previousPriceAmount = ShopUtils.getShopItemPreviousPrice( tmpShopItem );
                                    return (
                                        <div className="pw-dashboard-small-card">
                                            <h5>{ tmpShopItem.title }</h5>
                                            <p> { tmpShopItem.shortText }</p>
                                            <ButtonGroup>
                                                <span className="pw-buttongroup-lbl">{ previousPriceAmount ? <span className="pw_shop_card_previous_price">{ DataUtils.formatIntegerPrice( tmpShopItem.priceCurrency, previousPriceAmount ) }</span> : null } { DataUtils.formatIntegerPrice( tmpShopItem.priceCurrency, tmpShopItem.priceAmount ) }</span>
                                                <Button
                                                    size="sm"
                                                    disabled={ this.props.pwSession.shopAttributes.isAddingShopItemId === tmpShopItem.id }
                                                    variant="success"
                                                    onClick={ () => {
                                                        this.props.shopAddItemServiceToCart( tmpShopItem );
                                                        if ( tmpShopItem.service && tmpShopItem.service.serviceLinkedServices ) {
                                                            this.setState( { linkedServices: tmpShopItem.service.serviceLinkedServices } );
                                                        }
                                                    } }
                                                >
                                                    { this.props.pwSession.shopAttributes.isAddingShopItemId === tmpShopItem.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null } Reservar <Icon name="nav-arrow-right" color="white" />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    );
                                } ) }
                            </ExpandCollapse>
                        );
                    } ) }
                </div>
            </Offcanvas.Body>
        );
    }
    renderLinkedServices() {
        if ( !this.state.linkedServices || this.state.linkedServices.length === 0 || this.props.calledFrom !== "shop" ) {
            return null;
        }
        let linkedShopItems = this.props.shop.items.filter( shopItem => {
            if ( !shopItem.service ) {
                return false;
            }
            let found = this.state.linkedServices.find( tmp => tmp.serviceLinkedServiceLinkedServiceId === shopItem.service.serviceId );
            return found;
        } );
        return (
            <div className="pw_linked_services">
                <h4>O tal vez te interese ...</h4>
                <GenericDynamicContent items={
                    [
                        {
                            type: "shop_cards", value: linkedShopItems, cardDeck: 3, shopCardOptions: { hideViewCart: true }
                        }
                    ] }
                />
            </div>
        );
    }
    renderShopItemNotAvailable() {
        if ( this.props.pwSession.shopAttributes.isEditingShopBookingStep !== "shopItemNotAvailable" || !this.props.pwSession.shopAttributes.editingShopBooking ) {
            return null;
        }
        let selectableTeamMembers = [];
        let cartItem = this.props.pwSession.shopAttributes.editingShopBooking.shopItem;
        let fullService = cartItem ? cartItem.service : null;
        let cartItemTitle = "";
        if ( fullService ) {
            cartItemTitle = fullService.serviceTitle;
        }
        if ( fullService && fullService.serviceTeamMembers ) {
            selectableTeamMembers = fullService.serviceTeamMembers.filter( tmpMember => tmpMember.serviceTeamMemberActive && tmpMember.teamMember.teamMemberActive && tmpMember.teamMember.teamMemberCalendarActive );
        }
        let preferredTeamMemberId = cartItem ? cartItem.teamMemberId : null;
        let preferredTeamMember = null;
        let foundPreferredTeamMember = null;
        let preferredTeamMemberImage = null;
        if ( preferredTeamMemberId && fullService && fullService.serviceTeamMembers ) {
            foundPreferredTeamMember = fullService.serviceTeamMembers.find( tmp => tmp.teamMember.teamMemberId === preferredTeamMemberId );
        } else if ( selectableTeamMembers.length === 1 ) {
            foundPreferredTeamMember = selectableTeamMembers[ 0 ];
        }
        if ( foundPreferredTeamMember && foundPreferredTeamMember.teamMember ) {
            preferredTeamMember = foundPreferredTeamMember.teamMember;
            if ( preferredTeamMember.teamMemberImage ) {
                preferredTeamMemberImage = `/content/public/${ preferredTeamMember.teamMemberImage }`;
            }
        }
        return (
            <Offcanvas.Body>
                <div className="pw_shop_booking_topinfo pw_sticky">
                    { this.renderStepsInfo( 2, 1 ) }
                    <h4><b>{ cartItemTitle }</b></h4>
                    { preferredTeamMemberImage ?
                        <div>
                            <div className="pw-dashboard-small-card d-flex align-items-start">
                                <div className="pw_avatar">
                                    <img src={ preferredTeamMemberImage } alt="team" />
                                </div>
                                <div className="pw-dashboard-small-card-content">
                                    <h5>{ preferredTeamMember.teamMemberFullName }</h5>
                                    <p>{ preferredTeamMember.teamMemberRole }</p>
                                </div>
                            </div>
                        </div> : null }
                    <Alert variant="danger">
                        <span>Lo sentimos, pero no tenemos tenemos disponibilidad para este servicio. Déjanos un mensaje y te avisaremos de nuevas horas disponibles.</span>
                        { company.whatsappContactURL ?
                            <Button variant="link" className="pw_inline_whatsapp_btnicon" href={ company.whatsappContactURL } target="_blank">
                                <img
                                    src="/static/social-icons-whatsapp.png"
                                    alt="Whatsapp"
                                />
                            </Button> : null }
                    </Alert>
                </div>
            </Offcanvas.Body>
        );
    }
    renderShopSuggestionsStep() {
        if ( this.props.pwSession.shopAttributes.isEditingShopBookingStep !== "shopSuggestions" ) {
            return null;
        }
        return (
            <Offcanvas.Body>
                { this.renderStepsInfo( 0, 2 ) }
                <ButtonGroup className="pw_fullwidth pw_vertical_btn_group">
                    <Button variant="pw-primary" onClick={ () => this.props.changeShopCartAttr( "isEditingShopBookingStep", "services" ) }><Icon name="plus" /> Agregar otro servicio</Button>
                </ButtonGroup>
                <ButtonGroup className="pw_fullwidth pw_vertical_btn_group">
                    <Button variant="pw-primary" onClick={ () => this.props.closeShopBookingOffcanvas() }>Seguir navegando la web</Button>
                </ButtonGroup>
                <ButtonGroup className="pw_fullwidth">
                    <Button variant="success" onClick={ this.continueNextStep }>Continuar <Icon name="nav-arrow-right" color="white" /></Button>
                </ButtonGroup>
                { this.renderLinkedServices() }
            </Offcanvas.Body>
        );
    }
    renderBookingStep() {
        if ( this.props.pwSession.shopAttributes.isEditingShopBookingStep !== "booking" ) {
            return null;
        }
        let cartItem = null;
        const saleItem = this.props.pwSession.shopAttributes.editingShopBooking.saleItem || null;
        if ( this.props.pwSession.shopAttributes.editingShopBooking.shopItem ) {
            cartItem = this.props.pwSession.shop.cartItems.find( tmpCartItem => tmpCartItem.id === this.props.pwSession.shopAttributes.editingShopBooking.shopItem.id );
        }
        if ( ( !cartItem || cartItem.type !== "service" ) && ( !saleItem || ![ "service", "bono" ].includes( saleItem.saleItemType ) ) ) {
            return null;
        }
        let bookingTitle = cartItem ? cartItem.title : null;
        let fullService = cartItem ? cartItem.service : null;
        let preferredTeamMemberId = cartItem ? cartItem.teamMemberId : null;
        let preferredTeamMember = null;
        if ( saleItem ) {
            bookingTitle = saleItem.saleItemTitle;
            if ( saleItem.saleItemType === "bono" ) {
                if ( this.props.pwSession.shopAttributes.editingShopBooking.showOnlyBooking ) {
                    fullService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === this.props.pwSession.shopAttributes.editingShopBooking.showOnlyBooking.bookingServiceId );
                }
            } else {
                fullService = this.props.pwSession.shopAttributes.services.find( tmpService => tmpService.serviceId === saleItem.saleItemServiceId );
            }
            preferredTeamMemberId = this.props.pwSession.shopAttributes.editingShopBooking ? this.props.pwSession.shopAttributes.editingShopBooking.teamMemberId : null;
        }
        const bookings = this.getCartItemBookings( cartItem, saleItem );
        const calledFrom = this.props.calledFrom;
        let showEditQuantity = calledFrom !== "salelink";
        let allBookingsDone = !bookings.find( tmp => !tmp.bookingDatetime );
        let showContinue = allBookingsDone;
        if ( saleItem && saleItem.saleItemType === "bono" ) {
            // when editing one single booking in a bono
            showContinue = true;
            allBookingsDone = true;
        }
        const emptyAvailability = bookings.find( booking => booking.availableDaysData.length === 0 );
        let showWizardSteps = false;
        if ( calledFrom === "shop" ) {
            showWizardSteps = true;
        }
        let preferredTeamMemberImage = null;
        let selectableTeamMembers = [];
        if ( fullService && fullService.serviceTeamMembers ) {
            selectableTeamMembers = fullService.serviceTeamMembers.filter( tmpMember => tmpMember.serviceTeamMemberActive && tmpMember.teamMember.teamMemberActive && tmpMember.teamMember.teamMemberCalendarActive );
        }
        let foundPreferredTeamMember = null;
        if ( preferredTeamMemberId && fullService && fullService.serviceTeamMembers ) {
            foundPreferredTeamMember = fullService.serviceTeamMembers.find( tmp => tmp.teamMember.teamMemberId === preferredTeamMemberId );
        } else if ( selectableTeamMembers.length === 1 ) {
            foundPreferredTeamMember = selectableTeamMembers[ 0 ];
        }
        if ( foundPreferredTeamMember && foundPreferredTeamMember.teamMember ) {
            preferredTeamMember = foundPreferredTeamMember.teamMember;
            if ( preferredTeamMember.teamMemberImage ) {
                preferredTeamMemberImage = `/content/public/${ preferredTeamMember.teamMemberImage }`;
            }
        }
        let showItemBookings = true;
        if ( emptyAvailability ) {
            showContinue = true;
        }
        if ( company.stopBookingUnavailable && bookings && bookings.length > 0 && !allBookingsDone && emptyAvailability ) {
            // empty availability for this service
            showEditQuantity = false;
            showItemBookings = false;
            showContinue = false;
        }
        return (
            <Offcanvas.Body>
                <div className="pw_shop_booking_topinfo pw_sticky">
                    { showWizardSteps ? this.renderStepsInfo( 2, 1 ) : null }
                    { !allBookingsDone && !emptyAvailability ? <Alert variant="warning">Seleccionar fecha y hora para poder continuar ...</Alert> : null }
                </div>
                <h4>Fecha y hora para <b>{ bookingTitle }{ bookings && bookings.length > 1 ? ` (${ bookings.length } citas)` : null }</b></h4>
                { showEditQuantity ?
                    <div className="shop_cart_item_quantity">
                        <InputGroup className="mb-3">
                            <span>
                                <Button variant="outline-secondary" onClick={ () => this.props.shopRemoveItemQuantity( cartItem ) }><Icon name="minus" /></Button>
                            </span>
                            <FormControl value={ cartItem.quantity } id="shop-cart-quantity" aria-describedby="basic-addon1" />
                            <span>
                                <Button variant="outline-secondary" onClick={ () => this.props.shopAddItemQuantity( cartItem ) }><Icon name="plus" /></Button>
                            </span>
                        </InputGroup>
                    </div> : null }
                { selectableTeamMembers.length === 0 || selectableTeamMembers.length > 1 ?
                    <Form.Group>
                        <Form.Label><b>Profesional</b>:</Form.Label>
                        <Select
                            isClearable
                            placeholder="Cualquier profesional"
                            noOptionsMessage="No hay más opciones"
                            value={ preferredTeamMemberId }
                            onChange={ ( selectedOption ) => {
                                if ( cartItem ) {
                                    this.props.shopUpdateCartItemAttr( cartItem, "teamMemberId", selectedOption ? selectedOption.value : null );
                                }
                                if ( saleItem ) {
                                    this.props.changeShopCartAttr( "editingShopBooking", Object.assign( {}, this.props.pwSession.shopAttributes.editingShopBooking, { teamMemberId: selectedOption ? selectedOption.value : null } ) );
                                }
                            } }
                            options={ fullService && fullService.serviceTeamMembers ? [ { value: "", label: "Cualquier profesional" } ].concat( selectableTeamMembers.map( tmpMember => Object.assign( {}, { value: tmpMember.teamMember.teamMemberId, label: tmpMember.teamMember.teamMemberFullName } ) ) ) : [] }
                        />
                    </Form.Group> : null }
                { preferredTeamMemberImage ?
                    <div>
                        <div className="pw-dashboard-small-card d-flex align-items-start">
                            <div className="pw_avatar">
                                <img src={ preferredTeamMemberImage } alt="team" />
                            </div>
                            <div className="pw-dashboard-small-card-content">
                                <h5>{ preferredTeamMember.teamMemberFullName }</h5>
                                <p>{ preferredTeamMember.teamMemberRole }</p>
                            </div>
                        </div>
                    </div> : null }
                { showItemBookings && this.renderCartItemBookings( bookings, cartItem, saleItem ) }
                { showContinue ?
                    <ButtonGroup className="pw_shop_booking_continue pw_fullwidth">
                        <Button variant="success" onClick={ this.continueNextStep }>Continuar<Icon name="nav-arrow-right" color="white" /></Button>
                    </ButtonGroup> : null }
            </Offcanvas.Body>
        );
    }
    renderAvailableDays ( availableDaysData, cartItem, booking, saleItem ) {
        if ( booking.bookingDatetime && this.state.showAvailableHoursForSession !== booking.bookingSessionNumber ) {
            return null;
        }
        return (
            <div className="pw_shop_booking_days">
                { availableDaysData.map( dayData => (
                    <div className="pw_shop_booking_day">
                        <h4>{ DataUtils.formatDateNiceShort( dayData.dayString ) }</h4>
                        <h7>Horas disponibles</h7>
                        <div className="pw_shop_booking_hours">
                            { dayData.availableDateTimes.map( availableDateTime => (
                                <Button
                                    variant="pw-primary"
                                    onClick={ () => {
                                        this.onSelectedAvailableDatetime( cartItem, booking, availableDateTime, saleItem );
                                    } }
                                >
                                    { DataUtils.formatTimeString( availableDateTime ) }
                                </Button>
                            ) ) }
                        </div>
                    </div>
                ) ) }
            </div>
        );
    }
    renderCartItemBookings( bookings, cartItem, saleItem ) {
        return bookings.map( argBooking => {
            if ( this.props.pwSession.shopAttributes.editingShopBooking.showOnlyBooking && argBooking.bookingId !== this.props.pwSession.shopAttributes.editingShopBooking.showOnlyBooking.bookingId ) {
                return null;
            }
            const bookingWithAvailabilityDays = argBooking;
            const booking = Object.assign( {}, argBooking, { availableDaysData: null } );
            booking.bookingCartItemUniqueId = cartItem ? cartItem.uuid : null;
            let bookingTeamMember = null;
            let bookingTeamMemberName = "";
            if ( booking.bookingTeamMemberId ) {
                bookingTeamMember = this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === booking.bookingTeamMemberId );
                if ( bookingTeamMember ) {
                    bookingTeamMemberName = bookingTeamMember.teamMemberFullName;
                }
            }
            return (
                <div className="pw_shop_cart_item_booking_container">
                    <div className="pw_shop_cart_item_booking_number">
                        { saleItem && saleItem.saleItemType === "bono" ? <h5>Cita { booking.bookingSessionNumber } ({ booking.bookingService.serviceTitle }): { booking.bookingDatetime ? <span>{ DataUtils.formatDateTimeNiceShort( booking.bookingDatetime ) } con { bookingTeamMemberName }</span> : <i>No se ha seleccionado hora</i> }</h5> : null }
                        { !saleItem || saleItem.saleItemType !== "bono" ? <h5>Cita { booking.bookingSessionNumber }: { booking.bookingDatetime ? <span>{ DataUtils.formatDateTimeNiceShort( booking.bookingDatetime ) } con { bookingTeamMemberName }</span> : <i>No se ha seleccionado hora</i> }</h5> : null }
                        { booking.bookingDatetime ?
                            <ButtonGroup>
                                <Button
                                    variant="pw-primary"
                                    size="sm"
                                    onClick={ () => this.setState( { showAvailableHoursForSession: this.state.showAvailableHoursForSession === booking.bookingSessionNumber ? null : booking.bookingSessionNumber } ) }
                                >
                                    Modificar fecha/hora
                                </Button>
                                { !saleItem ?
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={ () => {
                                            this.onSelectedAvailableDatetime( cartItem, Object.assign( {}, argBooking, { availableDaysData: null } ), null, saleItem );
                                            this.setState( { showAvailableHoursForSession: null } );
                                        } }
                                    >
                                        Borrar
                                    </Button> : null }
                            </ButtonGroup> : null }
                    </div>
                    <div className="pw_shop_booking_days">{ this.renderAvailableDays( bookingWithAvailabilityDays.availableDaysData, cartItem, booking, saleItem ) }</div>
                </div>
            );
        } );
    }
    renderAvailabilityLoading() {
        return (
            <div className="pw_fullscreen_loading">
                <div className="pw_fullscreen_loading_content">
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" size="sm" />
                    Comprobando disponibilidad, por favor espere ...
                </div>
            </div>
        );
    }
    render() {
        if ( this.props.pwSession.shopAttributes.isAddingShopItemId ) {
            return this.renderAvailabilityLoading();
        }
        if ( !this.props.pwSession.shopAttributes.editingShopBooking ) {
            return null;
        }
        // TODO: P1: check rendering performance and improve
        return (
            <Offcanvas placement="end" className="pw_shop_offcanvas_addtocart" show={ this.props.pwSession.shopAttributes.isEditingShopBooking } onHide={ () => this.props.closeShopBookingOffcanvas() }>
                { this.renderBookingStep() }
                { this.renderShopSuggestionsStep() }
                { this.renderServicesStep() }
                { this.renderShopItemNotAvailable() }
            </Offcanvas>
        );
    }
}

ShopCartOffcanvasBooking.contextType = WebContext;
ShopCartOffcanvasBooking.serverFetch = shopStateData;
ShopCartOffcanvasBooking.serverFetchType = { type: "data", module: "shop" };

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    shop: state.shop,
    content: state.content
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions, ContentActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( ShopCartOffcanvasBooking ) );
