/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-danger */
import React from "react";
import Gallery from "react-photo-gallery";
import { Card, Ratio, Modal, ButtonGroup, Button } from "react-bootstrap";
import { IoMdExpand, IoIosClose } from "react-icons/io";
import { CommonStyles } from "./styles/Global";
import Galleries from "../domain/palmawebs.com/Galleries";

class CustomImage extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = { selected: props.selected };
    }
    render () {
        return (
            <Card onClick={ () => this.props.onSelectedCallback( this.props.data ) } alt={ this.props.data.photo.alt } className={ `${ this.state.selected ? "pw_gallery_item_selected " : "" }pw_gallery_item` } style={ this.props.containerStyles }>
                <Card.Img src={ this.props.data.photo.src } />
                { this.props.data.photo.overlay && this.props.data.photo.overlay.title ?
                    <Card.ImgOverlay>
                        { this.props.data.photo.overlay.title ? <Card.Title>{ this.props.data.photo.overlay.title }</Card.Title> : null }
                    </Card.ImgOverlay> : null }
                { this.props.data.photo.overlay && this.props.data.photo.overlay.html ?
                    <div className="pw_gallery_img_overlay">
                        <IoMdExpand />
                    </div>
                    : null }
            </Card>
        );
    }
}

class GenericGallery extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = { ssrDone: false, selected: null, displayModal: false };
        this.renderImage = this.renderImage.bind( this );
        this.renderSocialHeader = this.renderSocialHeader.bind( this );
        this.onSelectedCallback = this.onSelectedCallback.bind( this );
        this.getGallery = this.getGallery.bind( this );
    }
    componentDidMount() {
        this.ssrDone();
    }
    onSelectedCallback( data ) {
        this.setState( { displayModal: true, selected: data } );
    }
    getGallery() {
        let gallery = this.props.gallery || null;
        let { id } = this.props;
        if ( id ) {
            gallery = Galleries[ id ];
        }
        return gallery;
    }
    ssrDone() {
        this.setState( { ssrDone: true } );
    }
    renderInstagramPost( data ) {
        const gallery = this.getGallery();
        if ( !data || !gallery.custom ) {
            return null;
        }
        const containerStyles = {
            overflow: "hidden",
            position: "relative",
            width: data.photo.width,
            height: data.photo.height
        };
        if ( data.direction === "column" ) {
            containerStyles.position = "absolute";
            containerStyles.left = data.left;
            containerStyles.top = data.top;
        }
        return (
            <Card className="pw_gallery_item" style={ containerStyles }>
                { data.photo.displayOverlay ? <div className="pw_instagram_item_overlay" /> : [] }
                <Ratio aspectRatio="1x1">
                    <div className="pw_instagram_item">
                        { gallery.displayInstagramIcon ?
                            <a href={ gallery.instagramURL } rel="noopener noreferrer" target="_blank">
                                <img alt={ data.photo.alt } className="pw_instagram_item_overlay_sourceicon" src="/static/social-icons-instagram.png" />
                            </a> : []
                        }
                        <iframe title="Generic Gallery Item" src={ data.photo.src } width="100%" height="100%" frameBorder="0" scrolling="no" allowTransparency="true" />
                    </div>
                </Ratio>
            </Card> );
    }
    renderImage( data ) {
        if ( data.photo.type && data.photo.type === "instagram" ) {
            return this.renderInstagramPost( data );
        }
        const gallery = this.getGallery();
        if ( !data || !gallery.custom ) {
            return null;
        }
        const containerStyles = {
            overflow: "hidden",
            position: "relative",
            width: data.photo.width,
            height: data.photo.height
        };
        if ( data.direction === "column" ) {
            containerStyles.position = "absolute";
            containerStyles.left = data.left;
            containerStyles.top = data.top;
        }
        return ( <CustomImage
            data={ data }
            containerStyles={ containerStyles }
            onSelectedCallback={ this.onSelectedCallback }
            selected={ this.state.selected === data.photo.src }
        /> );
    }
    renderSocialHeader() {
        const gallery = Galleries[ this.props.id ];
        return (
            <div className="pw_gallery_social_header">
                <a href={ gallery.instagramURL } className="pw_gallery_social_header_instagram_link" rel="noopener noreferrer" target="_blank">
                    <img alt="Instagram Header" className="pw_gallery_social_header_instagram_icon" src="/static/social-icons-instagram.png" /> Ver más en Instagram
                </a>
            </div>
        );
    }
    render() {
        let gallery = this.getGallery();
        if ( !gallery || !this.state.ssrDone ) {
            return [];
        }
        const customProps = {};
        if ( gallery.custom ) {
            customProps.renderImage = this.renderImage;
            customProps.onClick = ( object ) => console.log( object );
        }
        return (
            <div id={ gallery.id } className="pw_gallery_container">
                { gallery.title && gallery.showTitle ? <h2 style={ CommonStyles.SectionTitle }>{ gallery.title }</h2> : [] }
                { gallery.subTitle ? <h3 style={ CommonStyles.SectionSubTitle }>{gallery.subTitle }</h3> : [] }
                { gallery.socialHeader ? this.renderSocialHeader() : [] }
                {gallery.photos ? <Gallery { ...gallery } { ...customProps } /> : null }
                { this.state.selected && this.state.selected.photo.overlay && this.state.selected.photo.overlay.html ?
                    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={ this.state.displayModal } onHide={ () => this.setState( { displayModal: false } ) }>
                        <Modal.Body>
                            <img src={ this.state.selected.photo.src } alt={ this.state.selected.photo.alt } className="img-fluid" />
                            <div dangerouslySetInnerHTML={ { __html: this.state.selected.photo.overlay.html } } />
                            <IoIosClose onClick={ () => this.setState( { displayModal: false } ) } className="pw_gallery_modal_close_icon" />
                        </Modal.Body>
                    </Modal> : null }
                { gallery.viewMoreUrl ?
                    <div className="pw_gallery_buttons">
                        <ButtonGroup>
                            <Button variant="pw-primary" href={ gallery.viewMoreUrl }>{ gallery.viewMoreText ? gallery.viewMoreText : "Ver más" }</Button>
                        </ButtonGroup>
                    </div>
                    : null }
            </div>
        );
    }
}

export default GenericGallery;
