/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-loop-func */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Helmet } from "react-helmet";
import { Container, InputGroup, ButtonGroup, Button, FormControl, Row, Col, Form, Alert, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import Icon from "./widgets/Icon";
import GenericShopOffcanvasBooking from "./GenericShopOffcanvasBooking";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import { shopStateData } from "../actions/ShopActions";
import { contentStateData } from "../actions/ContentActions";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import * as ShopUtils from "../utils/ShopUtils";
import * as CalendarUtils from "../utils/CalendarUtils";
import { getSaleItemBookings } from "../utils/SalesUtils";
import WebContext from "../utils/WebContext";
import PromoCodeCard from "./layout/PromoCodeCard";

class ShopCart extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {};
        this.page = Pages.getPage( "shop-cart" );
        this.renderItems = this.renderItems.bind( this );
        this.bookingMinTime = new Date();
        this.bookingMinTime.setHours( 9 );
        this.bookingMinTime.setMinutes( 0 );
        this.bookingMaxTime = new Date();
        this.bookingMaxTime.setHours( 22 );
        this.bookingMaxTime.setMinutes( 0 );
        this.getCartItemBookings = this.getCartItemBookings.bind( this );
        this.filterDateOrTime = this.filterDateOrTime.bind( this );
        this.renderPromoCode = this.renderPromoCode.bind( this );
    }

    componentDidMount() {
        this.props.loadUIContext();
        let servicesIds = [];
        if ( this.props.pwSession.shop.cartItems ) {
            this.props.pwSession.shop.cartItems.forEach( cartItem => {
                if ( cartItem.serviceId && !servicesIds.includes( cartItem.serviceId ) ) {
                    servicesIds.push( cartItem.serviceId );
                }
            } );
            if ( servicesIds.length > 0 ) {
                this.props.getCalendarBookedSlots();
                this.props.getCalendarTeamMembers();
            }
        }
    }
    componentDidUpdate() {
        let promoCodeAvailable = ShopUtils.getPromoCodeAvailable( this.props.content, this.props.pwSession );
        let promoCodeEnabled = this.props.pwSession.shopAttributes.promoCode;
        if ( !this.props.pwSession.isRehydrating && promoCodeAvailable && !promoCodeEnabled ) {
            this.props.changeShopCartAttr( "promoCodeText", promoCodeAvailable.promoCodeCode );
            this.props.changeShopCartAttr( "promoCode", promoCodeAvailable );
        }
    }
    getCartItemBookings( cartItem ) {
        let fullService = cartItem.service;
        let bonoService = null;
        if ( cartItem.type === "bono" ) {
            bonoService = cartItem.service;
            fullService = bonoService.bonoServiceService;
        }
        return getSaleItemBookings( cartItem.type, fullService, bonoService, cartItem.quantity, this.props.pwSession.shop.cartBookings || [], cartItem.priceAmount, cartItem.priceVatIncluded, cartItem.priceVatPercentage );
    }
    filterDateOrTime( date, isDay, fullService, booking, teamMemberId ) {
        let teamMembers = [];
        let otherCartBookings = this.props.pwSession.shop.cartBookings.filter( tmpBooking => tmpBooking !== booking );
        let tomorrow = DataUtils.dateTimeAdd( DataUtils.now(), 1, "days" );
        if ( DataUtils.isPastDateTime( date, tomorrow ) ) {
            return false;
        }
        if ( !fullService || !fullService.serviceDuration ) {
            return false;
        }
        if ( fullService.serviceTeamMembers ) {
            teamMembers = fullService.serviceTeamMembers.filter( serviceTeamMember => serviceTeamMember.serviceTeamMemberActive === 1 && serviceTeamMember.teamMember && serviceTeamMember.teamMember.teamMemberActive && serviceTeamMember.teamMember.teamMemberCalendarActive ).map( tmpServiceTeamMember => tmpServiceTeamMember.teamMember );
            teamMembers = teamMembers.map( tmpTeamMember => this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === tmpTeamMember.teamMemberId ) );
        }
        if ( teamMembers.length === 0 ) {
            return false;
        }
        if ( this.props.pwSession.shopAttributes.isLoadingBookedSlots ) {
            return false;
        }
        const editingBookings = otherCartBookings.filter( tmp => tmp.bookingDatetime && tmp.bookingDatetimeEnd );
        const allbookings = this.props.pwSession.shopAttributes.bookedSlots.concat( editingBookings );
        // checking team member availability
        let isTeamMemberAvailable = false;
        let isResourcesAvailable = true;
        // we use walk trough 20 slots if 30 minutes to check availability
        let tmpSlots = 1;
        if ( isDay ) {
            tmpSlots = 20;
        }
        let newDate = date;
        for ( let index = 1; index <= tmpSlots; index += 1 ) {
            if ( isDay ) {
                newDate = DataUtils.dateTimeAdd( date, 8, "hours" );
                newDate = DataUtils.dateTimeAdd( newDate, index * 30, "minutes" );
            }
            // checking team member
            let tmpTeamMember = null;
            if ( teamMemberId ) {
                tmpTeamMember = teamMembers.find( tmp => tmp.teamMemberId === teamMemberId );
                isTeamMemberAvailable = CalendarUtils.isTimeSlotInResourceAvailability( newDate, DataUtils.dateTimeAdd( newDate, fullService.serviceDuration, "minutes" ).toDate(), "teamMember", tmpTeamMember, allbookings );
            } else {
                for ( let indexTeamMember = 0; indexTeamMember < teamMembers.length; indexTeamMember += 1 ) {
                    tmpTeamMember = teamMembers[ indexTeamMember ];
                    if ( !isTeamMemberAvailable ) {
                        isTeamMemberAvailable = CalendarUtils.isTimeSlotInResourceAvailability( newDate, DataUtils.dateTimeAdd( newDate, fullService.serviceDuration, "minutes" ).toDate(), "teamMember", tmpTeamMember, allbookings );
                    }
                    if ( isTeamMemberAvailable ) {
                        break;
                    }
                }
            }
            // checking service resources availability
            if ( fullService.serviceResources ) {
                let serviceAvailability = CalendarUtils.serviceResourcesAvailability( newDate, DataUtils.dateTimeAdd( newDate, fullService.serviceDuration, "minutes" ).toDate(), fullService, allbookings );
                isResourcesAvailable = serviceAvailability.available;
            }
            if ( isTeamMemberAvailable && isResourcesAvailable ) {
                return true;
            }
        }
        return false;
    }
    renderPromoCode() {
        let promoCode = this.props.pwSession.shopAttributes.promoCode;
        return (
            <Alert variant="success">
                <p>¡Super! Tienes activado el código <b>{ promoCode.promoCodeCode }</b></p>
                <PromoCodeCard promoCode={ promoCode } categories={ this.props.shop ? this.props.shop.categories : [] } />
            </Alert> );
    }
    renderCartItemBookings( cartItem ) {
        let bookings = [];
        bookings = this.getCartItemBookings( cartItem );
        return bookings.map( booking => {
            booking.bookingCartItemUniqueId = cartItem.uuid;
            let bookingTeamMember = null;
            if ( booking.bookingTeamMemberId ) {
                bookingTeamMember = this.props.pwSession.shopAttributes.teamMembers.find( tmp => tmp.teamMemberId === booking.bookingTeamMemberId );
            }
            return (
                <div className="shop_cart_item_booking_container">
                    <h5>Cita { booking.bookingSessionNumber }: { booking.bookingDatetime ? <span>{ DataUtils.formatDateTimeNiceShort( booking.bookingDatetime ) } { bookingTeamMember ? <span>con <b>{ bookingTeamMember.teamMemberFullName }</b></span> : null }</span> : <i>No se ha seleccionado hora. Agendar luego por teléfono o en persona.</i> }</h5>
                </div>
            );
        } );
    }
    renderItems() {
        let promoCode = this.props.pwSession.shopAttributes.promoCode;
        return this.props.pwSession.shop.cartItems.map( tmpCartItem => {
            let cartItem = ShopUtils.applyCartItemOverrides( this.props.pwSession.shop.cartItems, tmpCartItem, promoCode );
            let showQuantity = true;
            let showUnitPrice = true;
            if ( cartItem.isGiftCardAmount ) {
                showQuantity = false;
                showUnitPrice = false;
            }
            let showIsGiftButton = false;
            if ( this.props.shop.giftCardServicesActive && cartItem.type === "service" ) {
                showIsGiftButton = true;
                if ( ShopUtils.isOutsourcedCartItem( cartItem ) ) {
                    showIsGiftButton = false;
                }
            }
            return (
                <div className="shop_cart_item" key={ `cart-item-${ cartItem.id }` }>
                    <h5>{ cartItem.title }</h5>
                    <div className="d-flex align-items-start">
                        <img style={ { width: "144px", height: "auto" } } className="mr-3" src={ DataUtils.getPublicImageUrl( cartItem.imageTop ) } alt="Shopping Cart Item ..." />
                        <div className="shop_cart_item_options">
                            { showUnitPrice ?
                                <div className="shop_cart_item_panel">
                                    <p className="shop_cart_item_label">{ Pages.text( this.context.language, "shop.cart.unitprice" ) }:</p>
                                    <span className="shop_cart_item_value">{ !cartItem.cartItemDiscountAmountApplied ? DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.priceAmount ) : <span><span style={ { textDecoration: "line-through" } }>{ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.priceAmount ) }</span><b>{ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.cartItemUnitPriceAmount ) }</b></span> }</span>
                                </div> : null }
                            <div className="shop_cart_item_panel">
                                { showQuantity ? <p className="shop_cart_item_label">{ Pages.text( this.context.language, "shop.cart.quantity" ) }:</p> : null }
                                { showQuantity ?
                                    <div className="shop_cart_item_quantity">
                                        <InputGroup className="mb-3">
                                            <span>
                                                <Button variant="info" onClick={ () => this.props.shopRemoveItemQuantity( cartItem ) }><FaMinusCircle /></Button>
                                            </span>
                                            <FormControl value={ cartItem.quantity } onChange={ () => console.log( "" ) }id="shop-cart-quantity" aria-describedby="basic-addon1" />
                                            <span>
                                                <Button variant="info" onClick={ () => this.props.shopAddItemQuantity( cartItem ) }><FaPlusCircle /></Button>
                                            </span>
                                        </InputGroup>
                                    </div> : null }
                                { cartItem.type === "service" ? this.renderCartItemBookings( cartItem ) : null }
                                { cartItem.type === "service" ?
                                    <ButtonGroup>
                                        <Button
                                            variant="pw-primary"
                                            onClick={ () => {
                                                this.setState( { isLoadingItemIdBookings: cartItem.id } );
                                                this.props.getCalendarBookedSlots()
                                                    .then( () => this.props.getCalendarTeamMembers() )
                                                    .then( () => {
                                                        this.props.openShopBookingOffcanvas( "booking", { shopItem: cartItem } );
                                                        this.setState( { isLoadingItemIdBookings: null } );
                                                    } )
                                                    .catch( () => this.setState( { isLoadingItemIdBookings: null } ) );
                                            } }
                                        >
                                            { this.state.isLoadingItemIdBookings === cartItem.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null } Modificar citas agendadas
                                        </Button>
                                    </ButtonGroup> : null }
                                { cartItem.isGiftCardAmount ? <p className="shop_cart_item_label shop_cart_item_giftcard_label">Saldo de tarjeta { cartItem.minPriceAmount ? `(Saldo Mínimo ${ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.minPriceAmount ) })` : null }:</p> : null }
                                { cartItem.isGiftCardAmount ?
                                    <div className="shop_cart_item_giftcard_amount">
                                        <InputGroup>
                                            <span>
                                                <Button
                                                    variant="info"
                                                    onClick={ () => {
                                                        if ( ( cartItem.minPriceAmount && cartItem.priceAmount <= cartItem.minPriceAmount ) || cartItem.priceAmount === 0 ) {
                                                            return;
                                                        }
                                                        this.props.shopUpdateItemPriceAmount( cartItem, -1000 );
                                                    } }
                                                ><FaMinusCircle />
                                                </Button>
                                            </span>
                                            <FormControl value={ DataUtils.formatIntegerPriceTwoDecimals( cartItem.priceAmount ) } onChange={ () => console.log( "" ) } id="shop-cart-giftcard-amount" aria-describedby="basic-addon1" />
                                            <span>
                                                <Button
                                                    variant="info"
                                                    onClick={ () => {
                                                        if ( ( cartItem.maxPriceAmount && cartItem.priceAmount >= cartItem.maxPriceAmount ) ) {
                                                            return;
                                                        }
                                                        this.props.shopUpdateItemPriceAmount( cartItem, 1000 );
                                                    } }
                                                ><FaPlusCircle />
                                                </Button>
                                            </span>
                                        </InputGroup>
                                    </div> : null }
                            </div>
                            { cartItem.courseClasses && cartItem.courseClasses.length > 0 ?
                                <div className="shop_cart_item_panel">
                                    <p className="shop_cart_item_label">Fecha de inicio del curso:</p>
                                    <Form.Group controlId="kindOfStand" style={ { fontWeight: "bold" } }>
                                        { cartItem.courseClasses.map( tmpCourseClass => (
                                            <Form.Check
                                                label={ tmpCourseClass.courseClassText }
                                                type="radio"
                                                name={ `courseClassRadio${ cartItem.id }` }
                                                key={ tmpCourseClass.courseClassId }
                                                id={ tmpCourseClass.courseClassId }
                                                checked={ tmpCourseClass.courseClassId === cartItem.courseClassIdSelected }
                                                onChange={ () => {
                                                    this.props.shopChangeItemSelectedCourseClassId( cartItem, tmpCourseClass.courseClassId );
                                                } }
                                            />
                                        ) ) }
                                    </Form.Group>
                                </div> : null }
                            <div className="shop_cart_item_panel">
                                <p className="shop_cart_item_label">{ Pages.text( this.context.language, "shop.cart.totalprice" ) }:</p>
                                <span className="shop_cart_item_value">{ !cartItem.cartItemDiscountAmountApplied ? DataUtils.formatIntegerPrice( cartItem.priceCurrency, ( cartItem.priceAmount * cartItem.quantity ) ) : <span><span style={ { textDecoration: "line-through" } }>{ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.cartItemTotalAmountBeforeDiscount ) }</span><b>{ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.cartItemTotalAmount ) }</b></span> }</span>
                            </div>
                            { cartItem.isGift ?
                                <Alert variant="pw-purple">
                                    <p><b><Icon name="gift" /> Este item ha sido marcado como regalo</b>. { Pages.text( this.context.language, "shop.cart.giftcardservices.message" ) }</p>
                                    <ButtonGroup>
                                        <Button
                                            onClick={ () => {
                                                this.props.shopUpdateCartItemAttr( cartItem, "isGift", !cartItem.isGift );
                                            } }
                                            variant="pw-purple"
                                        >
                                            <Icon name="cancelcircled" /> { Pages.text( this.context.language, "shop.cart.unmarkasgift" ) }
                                        </Button>
                                    </ButtonGroup>
                                </Alert> : null }
                            <ButtonGroup>
                                { showIsGiftButton && !cartItem.isGift ?
                                    <Button
                                        onClick={ () => {
                                            this.props.shopUpdateCartItemAttr( cartItem, "isGift", !cartItem.isGift );
                                        } }
                                        variant="pw-purple"
                                    >
                                        <Icon name="gift" /> { Pages.text( this.context.language, "shop.cart.markasgift" ) }
                                    </Button> : null }
                                <Button onClick={ () => this.props.shopRemoveItem( cartItem ) } variant="danger" className="pw_remove_item">{ Pages.text( this.context.language, "shop.cart.removefromcart" ) }</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            );
        } );
    }
    render() {
        const lang = this.context.language;
        let promoCode = this.props.pwSession.shopAttributes.promoCode;
        let inStorePickUp = this.props.pwSession.shop.inStorePickUp;
        const cartTotals = ShopUtils.getCartTotals( this.props.pwSession.shop, this.props.shop.freeShippingAmount, this.props.shop.freeShippingBeforeVat, this.props.shop.prepaymentAmountForService, promoCode, inStorePickUp );
        const isOutsourcedCart = ShopUtils.isOutsourcedCart( this.props.pwSession.shop.cartItems );
        let showPrepaymentOptions = false;
        if ( !isOutsourcedCart ) {
            if ( cartTotals.total > 0 ) {
                this.props.pwSession.shop.cartItems.forEach( cartItem => {
                    if ( cartItem.type === "service" || cartItem.type === "bono" ) {
                        showPrepaymentOptions = true;
                    }
                } );
            }
        }
        if ( isOutsourcedCart ) {
            // prepayment not allowed in this case
            if ( this.props.pwSession.shop.prepaymentActive ) {
                this.props.changeShopAttr( "prepaymentActive", false );
            }
        }
        return (
            <div id="shop-cart" className={ Pages.getPageClassNames( this.page ) }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                <Container>
                    <h2 className="pw_section_title">{ Pages.text( this.context.language, "shop.cart.contenttitle" ) } ({ cartTotals.totalItems })</h2>
                    <div className="shop_cart_buttons_top">
                        <ButtonGroup className="pw_fullwidth">
                            <Button href={ Pages.getPage( "shop" ).relativeUrl } variant="info"><IoIosArrowBack /> { Pages.text( this.context.language, "shop.continue.buying" ) }</Button>
                            { this.props.pwSession.shop.cartItems.length > 0 ? <Button onClick={ () => this.props.shopRemoveCart() } variant="danger"><Icon name="trash" color="white" /> { Pages.text( this.context.language, "shop.cart.emptybutton" ) }</Button> : null }
                            { this.props.pwSession.shop.cartItems.length > 0 ? <Button onClick={ () => this.props.shopCartCheckout() } variant="success" className="right">{ Pages.text( this.context.language, "shop.checkout.finalstep" ) }  <Icon name="nav-arrow-right" color="white" /></Button> : null }
                        </ButtonGroup>
                    </div>
                    <div className="shop_cart_items_container">
                        { this.props.pwSession.shop.cartItems.length === 0 ? <h4 className="pw_section_subtitle">{ Pages.text( this.context.language, "shop.cart.yourcartisempty" ) }.</h4> : null }
                        { this.renderItems() }
                    </div>
                    <div className="shop_cart_totals_bottom">
                        <div className="shop_cart_totals_right_panel">
                            { this.props.shop.enablePromoCodes && this.props.pwSession.shopAttributes.promoCode ? this.renderPromoCode() : null }
                            { showPrepaymentOptions && this.props.UIContext.loaded && this.props.shop.prepaymentAmountForService && !this.props.pwSession.shop.prepaymentActive && cartTotals.prepaymentTotalAmount !== cartTotals.total ?
                                <Alert variant="pw-purple">
                                    <p><b>{ Pages.text( this.context.language, "shop.cart.prepaymentservices.title" ) }</b></p>
                                    <p>{ Pages.text( this.context.language, "shop.cart.prepaymentservices.description", { prepaymentServicePrice: DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.shop.prepaymentAmountForService ) } ) }</p>
                                    <ButtonGroup>
                                        <Button
                                            onClick={ () => {
                                                this.props.changeShopAttr( "prepaymentActive", true );
                                            } }
                                            variant="pw-purple"
                                        >
                                            <Icon name="star-half-dashed" color="white" /> Pagar sólo reserva ({ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.prepaymentTotalAmount ) })
                                        </Button>
                                    </ButtonGroup>
                                </Alert> : null }
                            { showPrepaymentOptions && this.props.pwSession.shop.prepaymentActive && this.props.UIContext.loaded ?
                                <Alert variant="pw-purple">
                                    <p><b>{ Pages.text( this.context.language, "shop.cart.prepaymentservices.active.title" ) }</b></p>
                                    <p>{ Pages.text( this.context.language, "shop.cart.prepaymentservices.active.description", { prepaymentServicePrice: DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.shop.prepaymentAmountForService ) } ) }</p>
                                    <ButtonGroup>
                                        <Button
                                            onClick={ () => {
                                                this.props.changeShopAttr( "prepaymentActive", false );
                                            } }
                                            variant="pw-purple"
                                        >
                                            <Icon name="cancel" color="white" /> Prefiero pagar el total
                                        </Button>
                                    </ButtonGroup>
                                </Alert> : null }
                            <Container>
                                { this.props.shop.shipping ?
                                    <Row>
                                        <Col>{ Pages.text( this.context.language, "shop.cart.shippingprice" ) }:</Col>
                                        <Col><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.shippingPrice ) }</b>{ inStorePickUp ? <b style={ { color: "green" } }> (Recogida en tienda)</b> : null }</Col>
                                    </Row>
                                    : null }
                                { this.props.shop.inStorePickUp ?
                                    <Row>
                                        <Col>
                                            <div className="pws_card pw_shop_in_store_pickup">
                                                <Form.Check
                                                    type="switch"
                                                    id="in-store-check"
                                                    checked={ inStorePickUp }
                                                    onChange={ () => {
                                                        this.props.changeShopAttr( "inStorePickUp", !this.props.pwSession.shop.inStorePickUp );
                                                    } }
                                                    label={ inStorePickUp ? "Desmarcar esta casilla si prefieres que te lo enviemos" : "Marcar esta casilla para recogida en tienda" }
                                                />
                                            </div>
                                        </Col>
                                    </Row> : null }

                                { this.props.shop.shipping && cartTotals.amountForFreeShipping ?
                                    <Row>
                                        <Col><span className="pw_shop_free_shipping_message">{ Pages.text( this.context.language, "shop.cart.missingforfreeshipping", { freeShippingMissing: DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.amountForFreeShipping ) } ) }</span></Col>
                                    </Row>
                                    : null }
                                { cartTotals.vatPercentages.map( vat => (
                                    <Row>
                                        <Col>{ this.props.shop.vatName } ({ DataUtils.formatIntegerPercentage( vat.percentage ) })</Col>
                                        <Col><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, vat.total ) }</b></Col>
                                    </Row>
                                ) ) }
                                { cartTotals.totalBeforeDiscount !== cartTotals.total ?
                                    <Row>
                                        <Col>Descuento:</Col>
                                        <Col><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.totalBeforeDiscount - cartTotals.total ) }</b></Col>
                                    </Row>
                                    : null }
                            </Container>
                        </div>
                        <hr />
                        <div className="shop_cart_totals_right_panel">
                            <Container>
                                <Row>
                                    <Col><b className="shop_cart_total_price">{ Pages.text( this.context.language, "shop.cart.total" ) }:</b></Col>
                                    <Col><b className="shop_cart_total_price">{ cartTotals.total === cartTotals.totalBeforeDiscount ? DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.total ) : <span><span style={ { textDecoration: "line-through" } }>{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.totalBeforeDiscount ) }</span><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.total ) }</b></span> }</b></Col>
                                </Row>
                                { showPrepaymentOptions && this.props.pwSession.shop.prepaymentActive ?
                                    <Row>
                                        <Col><b className="shop_cart_total_price">A pagar ahora:</b></Col>
                                        <Col><b className="shop_cart_total_price">{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.prepaymentTotalAmount ) }</b></Col>
                                    </Row> : null }
                            </Container>
                        </div>
                    </div>
                    <div className="shop_cart_buttons_top">
                        <ButtonGroup className="pw_fullwidth">
                            <Button href={ Pages.getPage( "shop" ).relativeUrl } variant="info"><IoIosArrowBack /> { Pages.text( this.context.language, "shop.continue.buying" ) }</Button>
                            { this.props.pwSession.shop.cartItems.length > 0 ? <Button onClick={ () => this.props.shopRemoveCart() } variant="danger"><Icon name="trash" color="white" /> { Pages.text( this.context.language, "shop.cart.emptybutton" ) }</Button> : null }
                            { this.props.pwSession.shop.cartItems.length > 0 ? <Button onClick={ () => this.props.shopCartCheckout() } variant="success" className="right">{ Pages.text( this.context.language, "shop.checkout.finalstep" ) }  <Icon name="nav-arrow-right" color="white" /></Button> : null }
                        </ButtonGroup>
                    </div>
                </Container>
                <GenericShopOffcanvasBooking calledFrom="shopcart" />
            </div>
        );
    }
}

ShopCart.contextType = WebContext;
ShopCart.serverFetch = null;
ShopCart.serverFetchType = {
    type: "data",
    modules: [
        { module: "shop", serverFetch: shopStateData, options: { excludeItems: false } },
        { module: "content", serverFetch: contentStateData }
    ]
};

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    shop: state.shop,
    content: state.content
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( ShopCart ) );
