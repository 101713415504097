/* eslint-disable complexity */
import React from "react";
import { Helmet } from "react-helmet";
import * as Pages from "../../../utils/Pages";
import WebContext from "../../../utils/WebContext";
import GenericDynamicContent from "../../../components/GenericDynamicContent";
import { apiFetchDynamicContent } from "../../../api";

class Home extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { content: null };
    }
    componentDidMount() {
        this.props.loadUIContext();
        apiFetchDynamicContent( "Page", "home" )
            .then( result => {
                this.setState( { content: result.dynamicContent } );
            } );
    }
    render() {
        const page = Pages.getPage( "home" );
        const lang = this.context.language;
        return (
            <div className={ Pages.getPageClassNames( page ) }>
                <Helmet>
                    <title>{ page.seoTitle }</title>
                    <meta name="description" content={ page.seoDescription } />
                    <meta name="keywords" content={ page.seoKeywords } />
                    <meta name="author" content={ page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ page.seoTitle } />
                    <meta name="DC.description" content={ page.seoDescription } />
                    <meta property="og:title" content={ page.seoTitle } />
                    <meta property="og:description" content={ page.seoDescription } />
                    <meta property="og:url" content={ page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ page.seoDescription } />
                    <meta name="twitter:title" content={ page.seoTitle } />
                </Helmet>
                <GenericDynamicContent items={ this.state.content } />
            </div>
        );
    }
}

Home.contextType = WebContext;
// Home.serverFetch = fetchData; // static declaration of data requirements

export default Home;
