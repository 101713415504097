/* eslint-disable complexity */
import React from "react";
import { connect } from "react-redux";
import { Container, Button, ButtonToolbar, Form, Alert, Ratio, Row, Col } from "react-bootstrap";
import { IoLogoWhatsapp, IoIosSend, IoIosMail, IoMdClose, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";
import ReactPlayer from "react-player";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import Icon from "../widgets/Icon";
import { loadUIContext } from "../../actions/UIContextActions";
import { contactSend, contactOnChange } from "../../actions/ContactActions";
import * as Pages from "../../utils/Pages";
import ExpandCollapse from "../widgets/ExpandCollapse";
import DomRefs from "../../domrefs";
import WebContext from "../../utils/WebContext";
import Styles from "../styles/Global";

class Footer extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {};
        this.state.floatingVideoDisplayed = false;
        this.state.floatingVideoClosed = false;
        this.state.floatingActionButtonDisplayed = false;
        this.state.floatingActionButtonClosed = false;
        this.cookies = new Cookies();
        this.state.acceptCookies = this.cookies.get( "acceptCookies" );
        this.selectedForm = null;
        this.floatingVideo = null;
        this.floatingActionButton = null;
        this.hideFooter = false;
        this.state.acceptAdultsOnly = false;
        this.page = Pages.getPageByPath( props.location.pathname );
        if ( Pages.contactForms ) {
            this.selectedForm = Pages.contactForms.find( ( form ) => props.location.pathname.match( new RegExp( form.relativeUrl ) ) );
        }
        if ( Pages.floatingVideos ) {
            this.floatingVideo = Pages.floatingVideos.find( ( video ) => {
                if ( video.exactUrl ) {
                    return props.location.pathname === video.relativeUrl;
                }
                return props.location.pathname.match( new RegExp( video.relativeUrl ) );
            } );
        }
        if ( Pages.floatingActionButtons ) {
            this.floatingActionButton = Pages.floatingActionButtons.find( ( button ) => {
                if ( button.exactUrl ) {
                    return props.location.pathname === button.relativeUrl;
                }
                return props.location.pathname.match( new RegExp( button.relativeUrl ) );
            } );
        }
        this.renderCustomForm = this.renderCustomForm.bind( this );
        this.renderFloatingVideo = this.renderFloatingVideo.bind( this );
        this.renderFloatingActionButton = this.renderFloatingActionButton.bind( this );
    }
    renderCustomForm() {
        const lang = this.context.language;
        let sendDisabled = false;
        if ( this.props.contact.sending || this.props.contact.reCaptchaToken === "" || !this.props.contact.privacyPolicyAccept || ( Pages.company.RGPDCommercialNotificationsCheck && !this.props.contact.commercialNotificationsAccept ) ) {
            sendDisabled = true;
        }
        this.selectedForm.fields.forEach( ( field ) => {
            if ( field.mandatory && this.props.contact[ field.id ] === "" ) {
                sendDisabled = true;
            }
        } );
        return (
            <Container fluid id={ this.selectedForm.id } className="pw_footer_container">
                <h2>{ Pages.text( lang, this.selectedForm.titleLanguageKey ) }</h2>
                <h3>{ Pages.text( lang, this.selectedForm.subTitleLanguageKey ) }</h3>
                { !this.props.contact.contactSent ?
                    <Form style={ Styles.Footer.ContactFormContainer }>
                        { this.selectedForm.fields.map( ( field ) => (
                            <Form.Group key={ field.id }>
                                <Form.Label>{ Pages.text( lang, field.labelLanguageKey ) }:</Form.Label>
                                <Form.Control
                                    value={ this.props.contact[ field.id ] }
                                    onChange={ ( e ) => {
                                        const mutation = {};
                                        mutation[ field.id ] = e.target.value;
                                        this.props.contactOnChange( mutation );
                                    } }
                                    as={ field.type === "textarea" ? "textarea" : undefined }
                                    rows={ field.rows }
                                />
                                { field.descriptionLanguageKey ?
                                    <Form.Text className="text-muted">
                                        { Pages.text( lang, field.descriptionLanguageKey ) }
                                    </Form.Text> : null }
                            </Form.Group> ) )
                        }
                        <ReCAPTCHA
                            sitekey={ Pages.config.reCaptchaSiteKey }
                            onChange={ ( token ) => this.props.contactOnChange( { reCaptchaToken: token } ) }
                        />
                        <Form.Check style={ { margin: "20px 0 10px 0" } }>
                            <Form.Check.Input type="checkbox" checked={ this.props.contact.privacyPolicyAccept } onChange={ () => this.props.contactOnChange( { privacyPolicyAccept: !this.props.contact.privacyPolicyAccept } ) } />
                            <Form.Check.Label><div dangerouslySetInnerHTML={ { __html: Pages.text( lang, "footer.contact.privacypolicy" ) } } /></Form.Check.Label>
                        </Form.Check>
                        <ButtonToolbar>
                            <Button
                                variant="pw-primary"
                                onClick={ () => {
                                    this.props.contactOnChange( { formId: this.selectedForm.id } );
                                    ReactGA.event( { category: "Contact", action: `${ this.selectedForm.formName } Click` } );
                                    this.props.contactSend();
                                } }
                                style={ Object.assign( Styles.Footer.ActionButton, { margin: "10px 2px" } ) }
                                disabled={ sendDisabled }
                            >
                                <IoIosSend size="1.25em" style={ Styles.Footer.ActionButtonIcon } />
                                { Pages.text( lang, this.selectedForm.successSendLanguageKey ) }
                            </Button>
                        </ButtonToolbar>
                    </Form>
                    :
                    <Alert variant="success" style={ Styles.Footer.SuccessMessageAlert }>
                        <Alert.Heading>{ Pages.text( lang, this.selectedForm.successTitleLanguageKey ) }</Alert.Heading>
                        <p>
                            { Pages.text( lang, this.selectedForm.successText1LanguageKey ) }.
                        </p>
                        <hr />
                        <p className="mb-0">
                            { Pages.text( lang, this.selectedForm.successText2LanguageKey ) }
                        </p>
                    </Alert>
                }
                <Container style={ Styles.Footer.CopyrightFooter } className="pw_footer_copyright">
                    { [ "privacy-policy", "terms-conditions", "cookies-policy" ].map( ( pageId ) => {
                        const page = Pages.getPage( pageId );
                        if ( !page.id ) {
                            return ( <span key={ `copyrightlink-${ pageId }` } /> );
                        }
                        return (
                            <span key={ `copyrightlink-${ page.id }` }><a href={ page.relativeUrl } style={ Styles.Footer.footerLink }>{page.menuText}</a> | </span>
                        );
                    } ) }
                    <span>{ Pages.text( lang, "footer.copyright.text1" ) }</span>
                    { !Pages.company.footerHideLink ?
                        <p style={ { fontSize: "12px" } }>by <a style={ Styles.Footer.footerLink } href="https://www.palmawebs.com" target="_blank" rel="noopener noreferrer">www.palmawebs.com</a></p>
                        : null
                    }
                </Container>
            </Container> );
    }
    renderFloatingActionButton() {
        if ( !this.floatingActionButton ) {
            return null;
        }
        return (
            <div className="pw_floating_action_button">
                <Button
                    variant="transparent"
                    size="sm"
                    href={ this.floatingActionButton.href }
                    className="floating_action_button_btn"
                    onClick={ () => {
                        ReactGA.event( { category: "Promo", action: "Floating Action Button Click" } );
                    } }
                >
                    <img src={ this.floatingActionButton.src } alt="Floating Action Button" />
                </Button>
                <Button
                    variant="transparent"
                    size="sm"
                    className="floating_action_button_close"
                    onClick={ () => {
                        this.setState( { floatingActionButtonClosed: true } );
                    } }
                >
                    <IoMdClose />
                </Button>
            </div>
        );
    }
    renderFloatingVideo() {
        if ( !this.floatingVideo ) {
            return null;
        }
        return (
            <div className="pw_floating_video">
                { this.floatingVideo.provider === "youtube" ? <iframe title="floating_video" src={ `${ this.floatingVideo.src }?autoplay=${ this.floatingVideo.disableAutoplay ? "0" : "1" }` } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowFullScreen /> : [] }
                { this.floatingVideo.provider === "vimeo" ?
                    <iframe
                        title="floating_video_vimeo"
                        src={ `${ this.floatingVideo.src }?autoplay=1&loop=1&title=0&byline=0&portrait=0` }
                        frameBorder="0"
                        allowFullScreen
                        allow="autoplay; fullscreen"
                    /> : null }
                { this.floatingVideo.provider === "video" ?
                    <Ratio aspectRatio="16x9">
                        <ReactPlayer
                            url={ this.floatingVideo.src }
                            width="100%"
                            height="100%"
                            playing
                            controls
                        />
                    </Ratio>
                    : null }
                <div className="bottom_toolbar">
                    { this.floatingVideo.sendMessage ?
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => {
                                window.scrollTo( 0, DomRefs.contactRef.current.offsetTop );
                                ReactGA.event( { category: "Promo", action: "Floating Video Click" } );
                            } }
                        >
                            <IoIosMail /> { Pages.text( this.context.language, "footer.floatingvideo.sendmessage" ) }
                        </Button> : [] }
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={ () => {
                            this.setState( { floatingVideoClosed: true } );
                        } }
                    >
                        <IoMdClose />
                    </Button>
                </div>
            </div>
        );
    }
    renderFooterColumns( config ) {
        return (
            <Container className="pw_footer_container pw_footer_columns" id="pw-footer">
                { config.showFloatingVideo ? this.renderFloatingVideo() : [] }
                { config.showFloatingActionButton ? this.renderFloatingActionButton() : [] }
                <div className="pw_contact_info">
                    <Row className="d-flex justify-content-between">
                        <Col xs="12" sm="5">
                            <h4>{ Pages.text( this.context.language, "footer.contactinfo.about.title" ) }</h4>
                            <div style={ {
                                width: "100%", height: 240, position: "relative", borderRadius: "20px", overflow: "hidden", marginBottom: "20px", border: "1px solid #fafafa"
                            } }
                            >
                                { this.props.UIContext.loaded ?
                                    <iframe title="google map" src={ `${ Pages.company.googleMapsEmbed }` } width="100%" height="100%" frameBorder="0" style={ { border: 0 } } allowFullScreen="" aria-hidden="false" />
                                    : null
                                }
                            </div>

                            { Pages.company.contactAddress ? <p><Icon name="pin-alt" /> { Pages.company.contactAddress }</p> : null }
                            { Pages.company.contactTimeInfo ? <p style={ { display: "flex" } }><Icon name="timer" /> <span dangerouslySetInnerHTML={ { __html: Pages.company.contactTimeInfo } } /></p> : null }
                            { Pages.company.contactBusInfo ? <p><Icon name="bus-stop" /> <span dangerouslySetInnerHTML={ { __html: Pages.company.contactBusInfo } } /> </p> : null }
                            <div className="pw_contact_info_social_icons d-flex justify-content-between">
                                { Pages.company.contactEmail ?
                                    <Button className="pw_contact_info_social_icon" variant="link" href={ `mailto: ${ Pages.company.contactEmail }` }>
                                        <img
                                            src="/static/social-icons-email.png"
                                            alt="Footer Email"
                                        />
                                        <span>Enviar Email</span>
                                    </Button> : null }
                                { Pages.company.whatsappContactURL ?
                                    <Button className="pw_contact_info_social_icon" variant="link" href={ Pages.company.whatsappContactURL } target="_blank">
                                        <img
                                            src="/static/social-icons-whatsapp.png"
                                            alt="Footer Whatsapp"
                                        />
                                        <span>WhatsApp</span>
                                    </Button> : null }
                                { Pages.company.phoneLink ?
                                    <Button className="pw_contact_info_social_icon" variant="link" href={ Pages.company.phoneLink }>
                                        <img
                                            src="/static/social-icons-phone.png"
                                            alt="Call"
                                        />
                                        <span>Llamar por teléfono</span>
                                    </Button> : null }
                                { Pages.company.citaLink ?
                                    <Button className="pw_contact_info_social_icon" variant="link" href={ Pages.company.citaLink }>
                                        <img
                                            src="/static/social-icons-cita.png"
                                            alt="Call"
                                        />
                                        <span>Reservar Cita</span>
                                    </Button> : null }
                                { Pages.company.instagramPageURL ?
                                    <Button className="pw_contact_info_social_icon" variant="link" href={ Pages.company.instagramPageURL } target="_blank">
                                        <img
                                            src="/static/social-icons-instagram.png"
                                            alt="Footer instagram"
                                        />
                                        <span>Instagram</span>
                                    </Button> : null }
                                { Pages.company.facebookPageURL ?
                                    <Button className="pw_contact_info_social_icon" variant="link" href={ Pages.company.facebookPageURL } target="_blank">
                                        <img
                                            src="/static/social-icons-fb.png"
                                            alt="Footer facebook"
                                        />
                                        <span>Facebook</span>
                                    </Button> : null }
                            </div>
                        </Col>
                        <Col xs="12" md="5">
                            <h4>{ Pages.text( this.context.language, "footer.contactinfo.form.title" ) }</h4>
                            { !this.props.contact.contactSent ?
                                <Form>
                                    <Form.Group style={ { marginBottom: "10px" } }>
                                        <Form.Control placeholder="Nombre Completo" value={ this.props.contact.contactName } onChange={ ( e ) => this.props.contactOnChange( { contactName: e.target.value } ) } />
                                    </Form.Group>
                                    <Form.Group style={ { marginBottom: "10px" } }>
                                        <Form.Control placeholder="Email" value={ this.props.contact.contactEmail } onChange={ ( e ) => this.props.contactOnChange( { contactEmail: e.target.value } ) } type="email" />
                                    </Form.Group>
                                    <Form.Group style={ { marginBottom: "10px" } }>
                                        <Form.Control placeholder="Teléfono" value={ this.props.contact.contactPhone } onChange={ ( e ) => this.props.contactOnChange( { contactPhone: e.target.value } ) } />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control placeholder="Escribe tu mensaje ..." value={ this.props.contact.contactMessage } onChange={ ( e ) => this.props.contactOnChange( { contactMessage: e.target.value } ) } as="textarea" rows="3" />
                                    </Form.Group>
                                    <ReCAPTCHA
                                        sitekey={ Pages.config.reCaptchaSiteKey }
                                        onChange={ ( token ) => this.props.contactOnChange( { reCaptchaToken: token } ) }
                                    />
                                    { Pages.company.RGPDCommercialNotificationsCheck ?
                                        <Form.Group controlId="footerCommercialAccept">
                                            <br />
                                            <Form.Check type="checkbox" checked={ this.props.contact.commercialNotificationsAccept } onChange={ () => this.props.contactOnChange( { commercialNotificationsAccept: !this.props.contact.commercialNotificationsAccept } ) } label={ Pages.text( this.context.language, "rgpd.footer.contact.acceptcommercial" ) } />
                                        </Form.Group> : null }
                                    <Form.Group controlId="footerPrivacyAccept">
                                        <Form.Check style={ { margin: "10px 0 10px 0" } }>
                                            <Form.Check.Input type="checkbox" checked={ this.props.contact.privacyPolicyAccept } onChange={ () => this.props.contactOnChange( { privacyPolicyAccept: !this.props.contact.privacyPolicyAccept } ) } />
                                            <Form.Check.Label><div dangerouslySetInnerHTML={ { __html: Pages.text( this.context.language, "footer.contact.privacypolicy" ) } } /></Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>
                                    <ButtonToolbar>
                                        <Button
                                            variant="pw-primary"
                                            onClick={ () => {
                                                this.props.contactOnChange( { formId: false } );
                                                ReactGA.event( { category: "User", action: "Enviar Consulta Click" } );
                                                this.props.contactSend();
                                            } }
                                            style={ Styles.Footer.ActionButton }
                                            disabled={ this.props.contact.sending || this.props.contact.reCaptchaToken === "" || !this.props.contact.privacyPolicyAccept || ( Pages.company.RGPDCommercialNotificationsCheck && !this.props.contact.commercialNotificationsAccept ) || this.props.contact.contactName === "" || this.props.contact.contactEmail === "" || this.props.contact.contactMessage === "" }
                                        >
                                            <IoIosSend size="1.25em" style={ Styles.Footer.ActionButtonIcon } />
                                            { Pages.text( this.context.language, "footer.contact.sendmessage" ) }
                                        </Button>
                                    </ButtonToolbar>
                                    { Pages.company.RGPDMessagesReady ?
                                        <div className="pw_rgpd_form_box">
                                            <ExpandCollapse
                                                key="footer-rgpd-expand"
                                                title={ <span>Cumplimos con el <b>RGPD</b> (Reglamento General de Protección de Datos). Clic aquí para más información acerca de este formulario.</span> }
                                                expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                                collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                            >
                                                <div>
                                                    <p><b>Responsable:</b> { Pages.company.lopdFullName }</p>
                                                    <p><b>Finalidad:</b> Poder atender y gestionar los diferentes pedidos, solicitudes, consultas y resolución de dudas de los usuarios, así como poder realizar estudios estadísticos que ayuden a mejorar el funcionamiento de la página web y a informar de forma puntual.</p>
                                                    <p><b>Legitimación:</b> Consentimiento del interesado.</p>
                                                    <p><b>Destinatarios:</b>
                                                        { Pages.company.RGPDAWSInfo ? <p><b>Amazon Web Services EMEA SARL</b>: Respecto al servicio de alojamiento de la web, usamos los servicios de Amazon Web Services, teniendo los datos alojados en centros de datos situados en la Unión Europea. Puedes consultar la política de privacidad de Amazon Web Services en el siguiente enlace <a href="https://aws.amazon.com/es/compliance/data-privacy-faq/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Privacidad de datos</a> o también puedes consultar su información acerca de RGPD en el siguiente enlace:<a href="https://aws.amazon.com/es/compliance/gdpr-center/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Reglamento General de Protección de Datos (RGPD)</a></p> : null }
                                                        No se comunicarán datos a otros destinatarios, salvo obligación legal. La información es tratada con la máxima confidencialidad, siendo alojados en un servidor seguro. Sus datos ingresados en este formulario se utilizarán únicamente para dar respuesta a sus consultas realizadas a través del mismo, así como para informarle de las novedades.
                                                    </p>
                                                    <p><b>Derechos:</b> Tienes derecho a acceder, rectificar y suprimir tus datos, derechos que puedes ejercer enviando un correo electrónico a <a href={ `mailto:${ Pages.company.lopdEmail }` }>{ Pages.company.lopdEmail }</a></p>
                                                    <p>Puedes consultar la información adicional y detallada sobre protección de datos en el apartado <a href={ Pages.getPage( "privacy-policy" ).relativeUrl } className="pw_legal_link">política de privacidad</a>.</p>
                                                </div>
                                            </ExpandCollapse>
                                        </div> : null }
                                </Form> :
                                <Alert variant="success" style={ Styles.Footer.SuccessMessageAlert }>
                                    <Alert.Heading>{ Pages.text( this.context.language, "footer.contact.successmessage.text1" ) }</Alert.Heading>
                                    <p>
                                        { Pages.text( this.context.language, "footer.contact.successmessage.text2" ) } <strong>{this.props.contact.contactName}: { this.props.contact.contactEmail } { this.props.contact.contactPhone }</strong>.
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        { Pages.text( this.context.language, "footer.contact.successmessage.text3", { companyName: Pages.company.name } ) }
                                    </p>
                                </Alert>
                            }
                        </Col>
                    </Row>
                </div>
                <Container className="pw_footer_copyright">
                    { [ "privacy-policy", "terms-conditions", "cookies-policy", "legal-warning" ].map( ( pageId ) => {
                        const page = Pages.getPage( pageId );
                        if ( !page.id ) {
                            return ( <span key={ `copyrightlink-${ pageId }` } /> );
                        }
                        return (
                            <span key={ `copyrightlink-${ page.id }` }><a href={ page.relativeUrl } style={ Styles.Footer.footerLink }>{page.menuText}</a> | </span>
                        );
                    } ) }
                    <span>{ Pages.text( this.context.language, "footer.copyright.text1" ) }</span>
                    { !Pages.company.footerHideLink ?
                        <p style={ { textAlign: "center" } }>by <a style={ Styles.Footer.footerLink } href="https://www.palmawebs.com" target="_blank" rel="noopener noreferrer">www.palmawebs.com</a></p>
                        : null
                    }
                </Container>
            </Container>
        );
    }
    renderFooterClassic( config ) {
        return (
            <Container fluid style={ Styles.Footer.FooterContainer } className="pw_footer_container" id="pw-footer">
                { config.showFloatingVideo ? this.renderFloatingVideo() : [] }
                { config.showFloatingActionButton ? this.renderFloatingActionButton() : [] }
                <h2 style={ Styles.Footer.SectionTitle } ref={ DomRefs.contactRef }>{ Pages.text( this.context.language, "footer.contact.text1" ) }</h2>
                <h3 style={ Styles.Footer.SectionSubTitle }>{ Pages.text( this.context.language, "footer.contact.text2" ) }</h3>
                { !this.props.contact.contactSent ?
                    <Form style={ Styles.Footer.ContactFormContainer }>
                        <Form.Group>
                            <Form.Label>{ Pages.text( this.context.language, "footer.contact.fullname" ) }:</Form.Label>
                            <Form.Control value={ this.props.contact.contactName } onChange={ ( e ) => this.props.contactOnChange( { contactName: e.target.value } ) } />
                            <Form.Text className="text-muted">
                                { Pages.text( this.context.language, "footer.contact.fullname.description" ) }
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( this.context.language, "footer.contact.email" ) }:</Form.Label>
                            <Form.Control value={ this.props.contact.contactEmail } onChange={ ( e ) => this.props.contactOnChange( { contactEmail: e.target.value } ) } type="email" />
                            <Form.Text className="text-muted">
                                { Pages.text( this.context.language, "footer.contact.email.description" ) }
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( this.context.language, "footer.contact.phone" ) }:</Form.Label>
                            <Form.Control value={ this.props.contact.contactPhone } onChange={ ( e ) => this.props.contactOnChange( { contactPhone: e.target.value } ) } />
                            <Form.Text className="text-muted">
                                { Pages.text( this.context.language, "footer.contact.phone.description" ) }
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( this.context.language, "footer.contact.message" ) }:</Form.Label>
                            <Form.Control value={ this.props.contact.contactMessage } onChange={ ( e ) => this.props.contactOnChange( { contactMessage: e.target.value } ) } as="textarea" rows="3" />
                        </Form.Group>
                        <ReCAPTCHA
                            sitekey={ Pages.config.reCaptchaSiteKey }
                            onChange={ ( token ) => this.props.contactOnChange( { reCaptchaToken: token } ) }
                        />
                        { Pages.company.RGPDCommercialNotificationsCheck ?
                            <Form.Group controlId="footerCommercialAccept">
                                <br />
                                <Form.Check type="checkbox" checked={ this.props.contact.commercialNotificationsAccept } onChange={ () => this.props.contactOnChange( { commercialNotificationsAccept: !this.props.contact.commercialNotificationsAccept } ) } label={ Pages.text( this.context.language, "rgpd.footer.contact.acceptcommercial" ) } />
                            </Form.Group> : null }
                        <Form.Group controlId="footerPrivacyAccept">
                            <Form.Check style={ { margin: "20px 0 10px 0" } }>
                                <Form.Check.Input type="checkbox" checked={ this.props.contact.privacyPolicyAccept } onChange={ () => this.props.contactOnChange( { privacyPolicyAccept: !this.props.contact.privacyPolicyAccept } ) } />
                                <Form.Check.Label><div dangerouslySetInnerHTML={ { __html: Pages.text( this.context.language, "footer.contact.privacypolicy" ) } } /></Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <ButtonToolbar>
                            <Button
                                variant="pw-primary"
                                onClick={ () => {
                                    this.props.contactOnChange( { formId: false } );
                                    ReactGA.event( { category: "User", action: "Enviar Consulta Click" } );
                                    this.props.contactSend();
                                } }
                                style={ Styles.Footer.ActionButton }
                                disabled={ this.props.contact.sending || this.props.contact.reCaptchaToken === "" || !this.props.contact.privacyPolicyAccept || ( Pages.company.RGPDCommercialNotificationsCheck && !this.props.contact.commercialNotificationsAccept ) || this.props.contact.contactName === "" || this.props.contact.contactEmail === "" || this.props.contact.contactMessage === "" }
                            >
                                <IoIosSend size="1.25em" style={ Styles.Footer.ActionButtonIcon } />
                                { Pages.text( this.context.language, "footer.contact.sendmessage" ) }
                            </Button>
                        </ButtonToolbar>
                        { Pages.company.RGPDMessagesReady ?
                            <div className="pw_rgpd_form_box">
                                <ExpandCollapse
                                    key="footer-rgpd-expand"
                                    title={ <span>Cumplimos con el <b>RGPD</b> (Reglamento General de Protección de Datos). Clic aquí para más información acerca de este formulario.</span> }
                                    expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                    collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                >
                                    <div>
                                        <p><b>Responsable:</b> { Pages.company.lopdFullName }</p>
                                        <p><b>Finalidad:</b> Poder atender y gestionar los diferentes pedidos, solicitudes, consultas y resolución de dudas de los usuarios, así como poder realizar estudios estadísticos que ayuden a mejorar el funcionamiento de la página web y a informar de forma puntual.</p>
                                        <p><b>Legitimación:</b> Consentimiento del interesado.</p>
                                        <p><b>Destinatarios:</b>
                                            { Pages.company.RGPDAWSInfo ? <p><b>Amazon Web Services EMEA SARL</b>: Respecto al servicio de alojamiento de la web, usamos los servicios de Amazon Web Services, teniendo los datos alojados en centros de datos situados en la Unión Europea. Puedes consultar la política de privacidad de Amazon Web Services en el siguiente enlace <a href="https://aws.amazon.com/es/compliance/data-privacy-faq/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Privacidad de datos</a> o también puedes consultar su información acerca de RGPD en el siguiente enlace:<a href="https://aws.amazon.com/es/compliance/gdpr-center/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Reglamento General de Protección de Datos (RGPD)</a></p> : null }
                                            No se comunicarán datos a otros destinatarios, salvo obligación legal. La información es tratada con la máxima confidencialidad, siendo alojados en un servidor seguro. Sus datos ingresados en este formulario se utilizarán únicamente para dar respuesta a sus consultas realizadas a través del mismo, así como para informarle de las novedades.
                                        </p>
                                        <p><b>Derechos:</b> Tienes derecho a acceder, rectificar y suprimir tus datos, derechos que puedes ejercer enviando un correo electrónico a <a href={ `mailto:${ Pages.company.lopdEmail }` }>{ Pages.company.lopdEmail }</a></p>
                                        <p>Puedes consultar la información adicional y detallada sobre protección de datos en el apartado <a href={ Pages.getPage( "privacy-policy" ).relativeUrl } className="pw_legal_link">política de privacidad</a>.</p>
                                    </div>
                                </ExpandCollapse>
                            </div> : null }
                    </Form> :
                    <Alert variant="success" style={ Styles.Footer.SuccessMessageAlert }>
                        <Alert.Heading>{ Pages.text( this.context.language, "footer.contact.successmessage.text1" ) }</Alert.Heading>
                        <p>
                            { Pages.text( this.context.language, "footer.contact.successmessage.text2" ) } <strong>{this.props.contact.contactName}: { this.props.contact.contactEmail } { this.props.contact.contactPhone }</strong>.
                        </p>
                        <hr />
                        <p className="mb-0">
                            { Pages.text( this.context.language, "footer.contact.successmessage.text3", { companyName: Pages.company.name } ) }
                        </p>
                    </Alert>
                }
                { ( Pages.company.contactEmail || Pages.company.whatsappContactURL ) && !Pages.company.footerHideContactButtons ?
                    <div>
                        <h3 style={ Styles.Footer.SectionSubTitle } className="pw_or_remember_contact"> { Pages.text( this.context.language, "footer.contact.orremember" ) } ...</h3>
                        <ButtonToolbar style={ Styles.Footer.ActionButtonsContainer }>
                            { Pages.company.contactEmail !== "" ?
                                <Button
                                    variant="outline-dark"
                                    href={ `mailto: ${ Pages.company.contactEmail }` }
                                    size="lg"
                                    style={ Styles.Footer.ActionButton }
                                >
                                    <IoIosMail size="1.25em" style={ Styles.Footer.ActionButtonIcon } />
                                    { Pages.text( this.context.language, "footer.contact.emailbutton" ) }
                                </Button> : []
                            }
                            { Pages.company.whatsappContactURL !== "" ?
                                <Button
                                    variant="outline-dark"
                                    href={ Pages.company.whatsappContactURL }
                                    size="lg"
                                    style={ Styles.Footer.ActionButton }
                                >
                                    <IoLogoWhatsapp size="1.25em" style={ Styles.Footer.ActionButtonIcon } />
                                    { Pages.text( this.context.language, "footer.contact.whatsapp" ) }
                                </Button> : []
                            }
                        </ButtonToolbar>
                    </div>
                    :
                    null
                }
                { !Pages.company.footerHideFacebook && Pages.company.facebookPageURL ?
                    <div style={ {
                        maxWidth: "1000px",
                        justifyContent: "center",
                        margin: "auto",
                        display: "flex",
                        flexWrap: "wrap"
                    } }
                    >
                        <div style={ { width: Pages.company.footerFacebookPageOptions ? null : "360px", textAlign: "center" } } className="pw_footer_facebook_container">
                            <ul style={ Styles.Footer.MenuItemsContainer }>
                                <li><a href={ Pages.company.facebookPageURL } onClick={ () => ReactGA.event( { category: "Social", action: "Facebook Page Link Click" } ) } target="_blank" rel="noopener noreferrer">{ Pages.text( this.context.language, "footer.contact.visitfacebook" ) }</a></li>
                            </ul>
                        </div>
                    </div> : []
                }
                { Pages.company.footerDisplaySocialIcons ?
                    <div className="pw_footer_social_icons">
                        { Pages.company.contactEmail ?
                            <Button className="pw_footer_social_icon" variant="link" href={ `mailto: ${ Pages.company.contactEmail }` } onClick={ () => ReactGA.event( { category: "Social", action: "Footer Email Icon Click" } ) }>
                                <img
                                    src="/static/social-icons-email.png"
                                    alt="Footer Email"
                                />
                            </Button> : null }
                        { Pages.company.whatsappContactURL ?
                            <Button className="pw_footer_social_icon" variant="link" href={ Pages.company.whatsappContactURL } target="_blank" onClick={ () => ReactGA.event( { category: "Social", action: "Footer Whatsapp Icon Click" } ) }>
                                <img
                                    src="/static/social-icons-whatsapp.png"
                                    alt="Footer Whatsapp"
                                />
                            </Button> : null }
                        { Pages.company.instagramPageURL ?
                            <Button className="pw_footer_social_icon" variant="link" href={ Pages.company.instagramPageURL } target="_blank" onClick={ () => ReactGA.event( { category: "Social", action: "Footer Instagram Icon Click" } ) }>
                                <img
                                    src="/static/social-icons-instagram.png"
                                    alt="Footer instagram"
                                />
                            </Button> : null }
                        { Pages.company.facebookPageURL ?
                            <Button className="pw_footer_social_icon" variant="link" href={ Pages.company.facebookPageURL } target="_blank" onClick={ () => ReactGA.event( { category: "Social", action: "Footer Facebook Icon Click" } ) }>
                                <img
                                    src="/static/social-icons-fb.png"
                                    alt="Footer facebook"
                                />
                            </Button> : null }
                    </div> : null }
                <Container style={ Styles.Footer.CopyrightFooter } className="pw_footer_copyright">
                    { [ "privacy-policy", "terms-conditions", "cookies-policy", "legal-warning" ].map( ( pageId ) => {
                        const page = Pages.getPage( pageId );
                        if ( !page.id ) {
                            return ( <span key={ `copyrightlink-${ pageId }` } /> );
                        }
                        return (
                            <span key={ `copyrightlink-${ page.id }` }><a href={ page.relativeUrl } style={ Styles.Footer.footerLink }>{page.menuText}</a> | </span>
                        );
                    } ) }
                    <span>{ Pages.text( this.context.language, "footer.copyright.text1" ) }</span>
                    { !Pages.company.footerHideLink ?
                        <p style={ { fontSize: "12px", textAlign: "center" } }>by <a style={ Styles.Footer.footerLink } href="https://www.palmawebs.com" target="_blank" rel="noopener noreferrer">www.palmawebs.com</a></p>
                        : null
                    }
                </Container>
            </Container>
        );
    }
    render() {
        let config = {
            showFloatingVideo: false,
            showFloatingActionButton: false
        };
        if ( !this.state.floatingVideoClosed && this.props.UIContext.loaded && ( ( this.floatingVideo && ( this.props.UIContext.scrollY >= this.floatingVideo.displayScrollY || this.floatingVideo.displayScrollY === 0 ) ) || this.state.floatingVideoDisplayed ) ) {
            config.showFloatingVideo = true;
            if ( !this.state.floatingVideoDisplayed ) {
                this.setState( { floatingVideoDisplayed: true } );
            }
        }
        if ( ( this.props.UIContext.acceptCookies || this.state.acceptCookies ) && !this.state.floatingActionButtonClosed && this.props.UIContext.loaded && ( ( this.floatingActionButton && ( this.props.UIContext.scrollY >= this.floatingActionButton.displayScrollY || this.floatingActionButton.displayScrollY === 0 ) ) || this.state.floatingActionButtonDisplayed ) ) {
            config.showFloatingActionButton = true;
            if ( !this.state.floatingActionButtonDisplayed ) {
                this.setState( { floatingActionButtonDisplayed: true } );
            }
        }
        if ( this.selectedForm ) {
            return this.renderCustomForm();
        }
        // shop has infinite scrolling
        if ( this.page && ( this.page.hideFooter || [ "shop", "login", "shop-cart", "shop-cart-checkout", "paymentlink", "salelink" ].includes( this.page.id ) ) ) {
            return null;
        }
        if ( Pages.company.footerType === "columns" ) {
            return this.renderFooterColumns( config );
        }
        return this.renderFooterClassic( config );
    }
}
Footer.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    contact: state.contact,
    UIContext: state.UIContext
} );

const mapDispatchToProps = {
    loadUIContext,
    contactSend,
    contactOnChange
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Footer ) );
