/* eslint-disable complexity */
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { IoIosArrowDropdown } from "react-icons/io";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Dropdown from "../widgets/Dropdown";
import Styles from "../styles/Global";
import * as Pages from "../../utils/Pages";
import { loadUIContext } from "../../actions/UIContextActions";
import WebContext from "../../utils/WebContext";

class NavBarAccount extends React.Component {
    constructor() {
        super();
        this.state = { navExpanded: false };
        this.toggleNav = this.toggleNav.bind( this );
        this.closeNav = this.closeNav.bind( this );
    }

    componentDidMount() {
        this.props.loadUIContext();
    }

    toggleNav( expanded ) {
        this.setState( { navExpanded: expanded } );
    }

    closeNav() {
        this.setState( { navExpanded: false } );
    }

    static renderMenuItem( menuItem ) {
        if ( menuItem.menuItems ) {
            return (
                <Dropdown
                    className="pw_navbar_menu_dropdown"
                    text={ menuItem.menuText }
                    items={ menuItem.menuItems.map( subItem => {
                        const item = {
                            key: `sub-${ subItem.id }`,
                            href: subItem.relativeUrl,
                            text: subItem.menuText
                        };
                        return item;
                    } ) }
                />
            );
        }
        return ( <Nav.Link key={ `menu-${ menuItem.id }` } style={ Styles.NavBar.MenuItem } href={ menuItem.relativeUrl }>{menuItem.menuText}</Nav.Link> );
    }
    render() {
        if ( Pages.company.hideNavBarAccount ) {
            return null;
        }
        const myCoursesUrl = Pages.getPage( "my-courses" ).relativeUrl;
        let myShopCartsUrl = Pages.getPage( "my-shop-carts" ).relativeUrl;
        if ( Pages.company.hideShopCartsInDashboard ) {
            myShopCartsUrl = null;
        }
        return (
            <Container fluid className="pw_navbar_account_container">
                <Navbar expand="lg" onToggle={ this.toggleNav } expanded={ this.state.navExpanded }>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        style={ Styles.NavBar.ResponsiveMenuToggle }
                    >
                        <IoIosArrowDropdown />
                        <span className="current">Principal</span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-right">
                        <Nav.Link key="nav-link-account" href={ Pages.company.dashboardURLRelative }>Principal</Nav.Link>
                        { myShopCartsUrl ? <Nav.Link key="nav-link-account" href={ myShopCartsUrl }>Mis compras</Nav.Link> : null }
                        { myCoursesUrl ? <Nav.Link key="nav-link-courses" href={ myCoursesUrl }>Mis cursos</Nav.Link> : null }
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        );
    }
}

NavBarAccount.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToProps = {
    loadUIContext
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( NavBarAccount ) );
