/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Helmet } from "react-helmet";
import { Container, Button, Row, Col, Form, Tab, Nav, Modal, Alert, Spinner, ButtonGroup, ButtonToolbar, Card, Badge } from "react-bootstrap";
import { injectStripe, Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IoMdCheckmark, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Icon from "./widgets/Icon";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import { shopStateData } from "../actions/ShopActions";
import { contentStateData } from "../actions/ContentActions";
import ExpandCollapse from "../components/widgets/ExpandCollapse";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import * as ShopUtils from "../utils/ShopUtils";
import * as DataValidations from "../utils/DataValidations";
import WebContext from "../utils/WebContext";
import PromoCodeCard from "./layout/PromoCodeCard";

const stripeButton = ( props ) => (
    <Button
        variant="success"
        disabled={ props.disabled || ( props.pwSession.shopAttributes && props.pwSession.shopAttributes.purchaseIsProcessing ) }
        onClick={ () => {
            let valid = true;
            const {
                userName, userEmail, userMobile, userAddress, userCity, userPostalCode, userProvince
            } = props.pwSession.shopAttributes;
            if ( props.shop.signupRequired ) {
                if ( !props.pwSession.loggedIn ) {
                    props.changeShopCartAttr( "errorMessage", "shop.validation.requiredaccount" );
                    valid = false;
                }
                if ( valid && !props.pwSession.userEmailVerified ) {
                    props.changeShopCartAttr( "errorMessage", "shop.validation.requiredemailverified" );
                    valid = false;
                }
            }
            if ( valid && ( !userName || !userEmail || !userMobile || !userAddress ) ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.empty" );
                valid = false;
            }
            if ( valid && props.shop.shipping && !DataUtils.getPostalCode( userPostalCode, props.shop.shippingValidProvincePostalCodes ) ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.invalidpostalcode" );
                valid = false;
            }
            if ( valid && props.shop.shipping && ( !userCity || !userPostalCode || !userProvince ) ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.empty" );
                valid = false;
            }
            if ( valid && !props.pwSession.acceptPrivacyPolicy ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.privacypolicyandterms" );
                valid = false;
            }
            if ( valid && !DataValidations.isValidEmail( userEmail ) ) {
                props.changeShopCartAttr( "errorMessage", "shop.validation.invalidemail" );
                valid = false;
            }
            if ( !props.validateCartCheckout() ) {
                valid = false;
            }
            if ( valid ) {
                props.processPurchaseRequest( { stripe: props.stripe } );
            }
        } }
    >
        { props.pwSession.shopAttributes && props.pwSession.shopAttributes.purchaseIsProcessing ? <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
        /> : null }
        { Pages.text( props.language, "shop.checkout.processpurchasebutton" ) }
    </Button>
);

const InjectedStripeButton = injectStripe( connect( ( state ) => ( { pwSession: state.pwSession, shop: state.shop } ), Object.assign( {}, SessionActions ) )( stripeButton ) );

class ShopCartCheckout extends React.Component {
    constructor( props ) {
        super( props );
        this.page = Pages.getPage( "shop-cart-checkout" );
        this.renderItems = this.renderItems.bind( this );
        this.renderSuccess = this.renderSuccess.bind( this );
        this.loadPagantis = this.loadPagantis.bind( this );
        this.renderProcessPurchaseButton = this.renderProcessPurchaseButton.bind( this );
        this.renderPromoCode = this.renderPromoCode.bind( this );
        this.renderPromoCodeForm = this.renderPromoCodeForm.bind( this );
        this.state = {
            stripe: null,
            forceManualPromoCode: false
        };
        this.pagantisLoaded = false;
        this.view = "checkout";
        this.trafficFrom = null;
        const tmpParams = new URLSearchParams( props.location.search );
        const tmpParamsKeys = Array.from( tmpParams.keys() );
        this.urlParams = {};

        for ( let i = 0; i < tmpParamsKeys.length; i += 1 ) {
            this.urlParams[ tmpParamsKeys[ i ] ] = tmpParams.get( tmpParamsKeys[ i ] );
            if ( tmpParamsKeys[ i ] === "view" ) {
                this.view = this.urlParams[ tmpParamsKeys[ i ] ];
            }
            if ( tmpParamsKeys[ i ] === "cid" ) {
                this.cid = this.urlParams[ tmpParamsKeys[ i ] ];
            }
            if ( tmpParamsKeys[ i ] === "trafficFrom" ) {
                this.trafficFrom = this.urlParams[ tmpParamsKeys[ i ] ];
            }
        }
        if ( this.view === "paymentSuccess" && !this.cid ) {
            this.view = "checkout";
        }
    }
    componentDidMount() {
        this.props.loadUIContext();
        if ( this.props.shop.defaultPaymentMethod ) {
            this.props.changeShopCartAttr( "paymentMethod", this.props.shop.defaultPaymentMethod );
        }
        if ( Pages.stripe && Pages.stripe.publicKey ) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState( { stripe: window.Stripe( Pages.stripe.publicKey ) } );
        }
        if ( this.view === "paymentSuccess" && this.cid ) {
            this.props.shopRemoveCart();
        }
    }
    componentDidUpdate() {
        let promoCodeAvailable = ShopUtils.getPromoCodeAvailable( this.props.content, this.props.pwSession );
        let promoCodeEnabled = this.props.pwSession.shopAttributes.promoCode;
        if ( !this.state.forceManualPromoCode && !this.props.pwSession.isRehydrating && promoCodeAvailable && !promoCodeEnabled ) {
            this.props.changeShopCartAttr( "promoCodeText", promoCodeAvailable.promoCodeCode );
            this.props.changeShopCartAttr( "promoCode", promoCodeAvailable );
        }
    }
    validateForm() {
        let result = true;
        const {
            userName, userEmail, userMobile, userAddress, userCity, userPostalCode, userProvince
        } = this.props.pwSession.shopAttributes;
        if ( this.props.shop.signupRequired ) {
            if ( !this.props.pwSession.loggedIn ) {
                this.props.changeShopCartAttr( "errorMessage", "shop.validation.requiredaccount" );
                result = false;
            }
            if ( result && !this.props.pwSession.userEmailVerified ) {
                this.props.changeShopCartAttr( "errorMessage", "shop.validation.requiredemailverified" );
                result = false;
            }
        }
        if ( result && ( !userName || !userEmail || !userMobile || !userAddress ) ) {
            this.props.changeShopCartAttr( "errorMessage", "shop.validation.empty" );
            result = false;
        }
        if ( result && this.props.shop.shipping && !DataUtils.getPostalCode( userPostalCode, this.props.shop.shippingValidProvincePostalCodes ) ) {
            this.props.changeShopCartAttr( "errorMessage", "shop.validation.invalidpostalcode" );
            result = false;
        }
        if ( result && this.props.shop.shipping && ( !userCity || !userPostalCode || !userProvince ) ) {
            this.props.changeShopCartAttr( "errorMessage", "shop.validation.empty" );
            result = false;
        }
        if ( result && !this.props.pwSession.acceptPrivacyPolicy ) {
            this.props.changeShopCartAttr( "errorMessage", "shop.validation.privacypolicyandterms" );
            result = false;
        }
        if ( result && !DataValidations.isValidEmail( userEmail ) ) {
            this.props.changeShopCartAttr( "errorMessage", "shop.validation.invalidemail" );
            result = false;
        }
        if ( !this.props.validateCartCheckout() ) {
            result = false;
        }
        return result;
    }
    loadPagantis( total ) {
        if ( this.pagantisLoaded ) {
            return;
        }
        window.pgSDK.simulator.init( {
            publicKey: Pages.pagantis.publicKey,
            selector: ".pagantisSimulator",
            type: window.pgSDK.simulator.types.CHECKOUT_PAGE,
            skin: window.pgSDK.simulator.skins.BLUE,
            itemAmount: `${ DataUtils.formatIntegerPriceTwoDecimals( total ) } Euros`,
            itemQuantity: "1",
            locale: "es",
            country: "es"
        } );
        this.pagantisLoaded = true;
    }

    renderCenteredModal() {
        if ( this.props.pwSession.shopAttributes.errorMessage === "" ) {
            return null;
        }
        return (
            <Modal
                show
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                variant="danger"
                className="shop_cart_checkout_modal"
            >
                <Modal.Body>
                    { Pages.text( this.context.language, this.props.pwSession.shopAttributes.errorMessage ) }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={ () => this.props.changeShopCartAttr( "errorMessage", "" ) }>{ Pages.text( this.context.language, "shop.validation.close" ) }</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderItems() {
        let promoCode = this.props.pwSession.shopAttributes.promoCode;
        return this.props.pwSession.shop.cartItems.map( tmpCartItem => {
            let cartItem = ShopUtils.applyCartItemOverrides( this.props.pwSession.shop.cartItems, tmpCartItem, promoCode );
            return (
                <div className="shop_cart_item">
                    <h5>{ cartItem.quantity } x { cartItem.title }</h5>
                    <p className="shop_cart_item_codigo">{ Pages.text( this.context.language, "shop.item.code" ) }: { cartItem.code }</p>
                    <div className="d-flex align-items-start">
                        <img width={ 128 } className="mr-3" src={ DataUtils.getPublicImageUrl( cartItem.imageTop ) } alt="Shopping Cart Item ..." />
                        <div>
                            <div className="shop_cart_item_panel">
                                <p className="shop_cart_item_label">{ Pages.text( this.context.language, "shop.item.unitprice" ) }:</p>
                                <span className="shop_cart_item_value">
                                    { !cartItem.cartItemDiscountAmountApplied ? DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.priceAmount ) : <span><span style={ { textDecoration: "line-through" } }>{ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.priceAmount ) }</span><b>{ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.cartItemUnitPriceAmount ) }</b></span> }
                                </span>
                            </div>
                            { cartItem.courseClasses && cartItem.courseClasses.length > 0 ?
                                <div className="shop_cart_item_panel">
                                    <p className="shop_cart_item_label">Fecha de inicio del curso:</p>
                                    { cartItem.courseClasses.filter( ( tmpItemClass ) => tmpItemClass.courseClassId === cartItem.courseClassIdSelected ).map( tmpCourseClass => (
                                        <span className="shop_cart_item_value">{ tmpCourseClass.courseClassText }</span>
                                    ) ) }
                                </div> : null }
                            { cartItem.isGift ?
                                <Alert variant="pw-purple">
                                    <p><b><Icon name="gift" /> Este item ha sido marcado como regalo</b>. { Pages.text( this.context.language, "shop.cart.giftcardservices.message" ) }</p>
                                </Alert> : null }
                            <div className="shop_cart_item_panel">
                                <p className="shop_cart_item_label">{ Pages.text( this.context.language, "shop.item.totalprice" ) }:</p>
                                <span className="shop_cart_item_value">{ !cartItem.cartItemDiscountAmountApplied ? DataUtils.formatIntegerPrice( cartItem.priceCurrency, ( cartItem.priceAmount * cartItem.quantity ) ) : <span><span style={ { textDecoration: "line-through" } }>{ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.cartItemTotalAmountBeforeDiscount ) }</span><b>{ DataUtils.formatIntegerPrice( cartItem.priceCurrency, cartItem.cartItemTotalAmount ) }</b></span> }</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } );
    }
    renderSuccess() {
        let isLandscape = true;
        const lang = this.context.language;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        let paymentMethodTextPrefix = "";
        if ( this.props.pwSession.shopAttributes.successPaymentMethod && this.props.pwSession.shopAttributes.successPaymentMethod === "paylater" ) {
            paymentMethodTextPrefix = "paylater.";
        }
        return (
            <div id="shop-cart" style={ { paddingTop: this.page.navBarCSSPosition === "fixed" && this.page.navBarPagePadding ? this.page.navBarPagePadding : 0 } }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                <Container>
                    <h2 className="pw_section_title">{ Pages.text( lang, `shop.checkout.success.${ paymentMethodTextPrefix }title` ) }</h2>
                    <Alert variant="success">
                        <Alert.Heading>{ Pages.text( lang, `shop.checkout.success.message.${ paymentMethodTextPrefix }title` ) }</Alert.Heading>
                        <p>
                            { Pages.text( lang, `shop.checkout.success.message.${ paymentMethodTextPrefix }paragraph` ) }
                        </p>
                        <hr />
                        <p className="mb-0">
                            { Pages.text( lang, `shop.checkout.success.message.${ paymentMethodTextPrefix }paragraph2` ) }
                        </p>
                    </Alert>
                </Container>
            </div>
        );
    }
    renderProcessPurchaseButton() {
        const forcePaymentMethod = ShopUtils.getForcePaymentMethod( this.props.pwSession.shop.cartItems, this.props.shop.forceServicesPaymentMethod );
        let selectedPaymentMethod = forcePaymentMethod || this.props.pwSession.shopAttributes.paymentMethod;
        if ( selectedPaymentMethod === "stripe" ) {
            return (
                <Elements>
                    <InjectedStripeButton
                        language={ this.context.language }
                    />
                </Elements>
            );
        }
        if ( selectedPaymentMethod === "redsys" ) {
            return (
                <form name="redsysform" id="redsysform" action="https://sis-t.redsys.es" method="POST">
                    <input type="hidden" id="Ds_SignatureVersion" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
                    <input type="hidden" id="Ds_MerchantParameters" name="Ds_MerchantParameters" value="" />
                    <input type="hidden" id="Ds_Signature" name="Ds_Signature" value="" />
                    { Pages.redsys && Pages.redsys.bizum ?
                        <Button
                            variant="success"
                            onClick={ ( e ) => {
                                e.preventDefault();
                                if ( this.validateForm() ) {
                                    this.props.processPurchaseRequest( null, { redsysPaymentMethod: "bizum" } );
                                }
                            } }
                        >
                            <Icon name="bizum" color="white" />
                            { Pages.text( this.context.language, "shop.checkout.processpaymentlink.bizum" ) }
                        </Button> : null }
                    <Button
                        variant="success"
                        onClick={ ( e ) => {
                            e.preventDefault();
                            if ( this.validateForm() ) {
                                this.props.processPurchaseRequest();
                            }
                        } }
                    >   <Icon name="credit-card" color="white" />&nbsp;
                        { Pages.text( this.context.language, "shop.checkout.paybycard" ) }
                    </Button>
                </form>
            );
        }
        return (
            <Button
                variant="success"
                onClick={ () => {
                    if ( this.validateForm() ) {
                        this.props.processPurchaseRequest( null, { forcePaymentMethod } );
                    }
                } }
            >
                { Pages.text( this.context.language, selectedPaymentMethod === "paylater" ? "shop.checkout.paylater.processpurchasebutton" : "shop.checkout.processpurchasebutton" ) } { selectedPaymentMethod === "paylater" ? <Icon name="check" color="white" /> : <Icon name="nav-arrow-right" color="white" /> }
            </Button>
        );
    }
    renderPromoCode() {
        let promoCode = this.props.pwSession.shopAttributes.promoCode;
        return (
            <Alert variant="success">
                <p>¡Super! Tienes activado el código <b>{ promoCode.promoCodeCode }</b></p>
                <PromoCodeCard promoCode={ promoCode } categories={ this.props.shop ? this.props.shop.categories : [] } />
                <ButtonGroup>
                    <Button
                        onClick={ () => {
                            this.setState( { forceManualPromoCode: true } );
                            this.props.changeShopCartAttr( "promoCode", null );
                            this.props.changeShopCartAttr( "promoCodeText", "" );
                        } }
                        variant="secondary"
                    >
                        <Icon name="cancel" color="white" /> Usar otro código
                    </Button>
                </ButtonGroup>
            </Alert> );
    }
    renderPromoCodeForm() {
        return (
            <Alert variant="pw-purple">
                <p><b>¿Tienes un código de descuento?</b></p>
                { this.props.pwSession.shopAttributes.promoCodeError ?
                    <Alert variant="danger">
                        { Pages.text( this.context.language, this.props.pwSession.shopAttributes.promoCodeError ) }
                    </Alert> : null }
                <Form.Group style={ { margin: "10px 0 10px 0" } }>
                    <Form.Control
                        value={ this.props.pwSession.shopAttributes.promoCodeText }
                        defaultValue="Ingresar código ..."
                        onClick={ ( e ) => e.target.select() }
                        onChange={ ( e ) => {
                            this.props.changeShopCartAttr( "promoCodeText", e.target.value.toUpperCase() );
                        } }
                    />
                </Form.Group>
                <ButtonGroup>
                    <Button
                        onClick={ () => {
                            this.props.validatePromoCode();
                        } }
                        variant="pw-purple"
                    >
                        { this.props.pwSession.shopAttributes.isLoadingPromoCode ? <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                        /> : null }
                        <Icon name="percentage" color="white" /> Comprobar y aplicar
                    </Button>
                </ButtonGroup>
            </Alert>
        );
    }
    render() {
        let isLandscape = true;
        const lang = this.context.language;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        let forcePaymentMethod = ShopUtils.getForcePaymentMethod( this.props.pwSession.shop.cartItems, this.props.shop.forceServicesPaymentMethod );
        const isOutsourcedCart = ShopUtils.isOutsourcedCart( this.props.pwSession.shop.cartItems );
        let promoCode = this.props.pwSession.shopAttributes.promoCode;
        let inStorePickUp = this.props.pwSession.shop.inStorePickUp;
        const cartTotals = ShopUtils.getCartTotals( this.props.pwSession.shop, this.props.shop.freeShippingAmount, this.props.shop.freeShippingBeforeVat, this.props.shop.prepaymentAmountForService, promoCode, inStorePickUp );
        if ( ( this.view === "paymentSuccess" && this.cid ) || this.props.pwSession.shopAttributes.success ) {
            return this.renderSuccess();
        }
        if ( this.props.UIContext.loaded && this.props.shop.paymentMethods.pagantis && this.props.shop.paymentMethods.pagantis.active && this.props.shop.defaultPaymentMethod === "pagantis" && cartTotals.total > 0 && window.pgSDK ) {
            setInterval( () => this.loadPagantis( cartTotals.total ), 3000 );
        }
        let requiredSignup = this.props.shop.signupRequired || false;
        this.props.pwSession.shop.cartItems.forEach( tmpCartItem => {
            if ( tmpCartItem.courseId ) {
                requiredSignup = true;
            }
        } );
        let loginText = Pages.text( this.context.language, "shop.checkout.login" );
        let signupText = Pages.text( this.context.language, "shop.checkout.signup" );
        if ( !loginText ) {
            loginText = Pages.text( this.context.language, "signup.navbar.login" );
        }
        if ( !signupText ) {
            signupText = Pages.text( this.context.language, "signup.navbar.signup" );
        }
        let showPrepaymentOptions = false;
        // address check
        if ( this.props.shop.shipping ) {
            let tmpPostalCode = DataUtils.getPostalCode( this.props.pwSession.shopAttributes.userPostalCode );
            let tmpUserProvince = "";
            if ( tmpPostalCode ) {
                tmpUserProvince = tmpPostalCode.state;
            }
            if ( this.props.pwSession.shopAttributes.userProvince !== tmpUserProvince ) {
                this.props.changeShopCartAttr( "userProvince", tmpUserProvince );
            }
        }
        if ( cartTotals.total > 0 ) {
            this.props.pwSession.shop.cartItems.forEach( cartItem => {
                if ( cartItem.type === "service" || cartItem.type === "bono" ) {
                    showPrepaymentOptions = true;
                }
            } );
        }
        let selectedPaymentMethodTab = this.props.shop.defaultPaymentMethod ? this.props.shop.defaultPaymentMethod : this.props.pwSession.shopAttributes.paymentMethod;
        if ( forcePaymentMethod ) {
            selectedPaymentMethodTab = forcePaymentMethod;
        }
        if ( isOutsourcedCart ) {
            // prepayment not allowed in this case
            if ( this.props.pwSession.shop.prepaymentActive ) {
                this.props.changeShopAttr( "prepaymentActive", false );
            }
        }
        return (
            <StripeProvider stripe={ this.state.stripe }>
                <div id="shop-cart" className={ Pages.getPageClassNames( this.page ) }>
                    <Helmet>
                        <title>{ this.page.seoTitle }</title>
                        <meta name="description" content={ this.page.seoDescription } />
                        <meta name="keywords" content={ this.page.seoKeywords } />
                        <meta name="author" content={ this.page.seoAuthor } />
                        <meta httpEquiv="content-Language" content={ lang } />
                        <meta name="robots" content="all" />
                        <meta name="rating" content="General" />
                        <meta name="language" content={ lang } />
                        <meta name="DC.title" content={ this.page.seoTitle } />
                        <meta name="DC.description" content={ this.page.seoDescription } />
                        <meta property="og:title" content={ this.page.seoTitle } />
                        <meta property="og:description" content={ this.page.seoDescription } />
                        <meta property="og:url" content={ this.page.url } />
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content={ Pages.company.name } />
                        <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:description" content={ this.page.seoDescription } />
                        <meta name="twitter:title" content={ this.page.seoTitle } />
                    </Helmet>
                    <Container>
                        <h2 className="pw_section_title">{ Pages.text( this.context.language, "shop.checkout.finalstep" ) }</h2>
                        <div className="shop_cart_checkout_customer">
                            { this.props.pwSession.loggedIn ?
                                <div style={ { marginBottom: "20px" } }>
                                    <h2 id="checkout_customer_font">1. { Pages.text( this.context.language, "shop.checkout.useraccount" ) }<span style={ { color: "green" } }><IoMdCheckmark /></span></h2>
                                    <p>{ Pages.text( this.context.language, "shop.checkout.alreadyloggedin" ) } <span style={ { color: "green" } }>{ this.props.pwSession.userEmail }</span></p>
                                    { !this.props.pwSession.userEmailVerified ? <Alert variant="warning"><p><span dangerouslySetInnerHTML={ { __html: Pages.text( this.context.language, "shop.checkout.pleaseverifyaccount" ) } } /> <Button variant="pw-primary" href={ this.page.relativeUrl }>{ Pages.text( this.context.language, "shop.checkout.clickhere" ) }</Button></p></Alert> : null }
                                </div>
                                :
                                <div style={ { marginBottom: "20px" } }>
                                    <h2 id="checkout_customer_font">1. { Pages.text( this.context.language, "shop.checkout.alreadyaccountquestion" ) }{ !requiredSignup ? " (opcional)" : "" }</h2>
                                    {
                                        this.props.pwSession.isRehydrating ?
                                            <p>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> { Pages.text( this.context.language, "shop.checkout.gettingdata" ) } ...
                                            </p> :
                                            <div>
                                                <p>{ requiredSignup ? "Uno de los items de compra requiere que tengas una cuenta de usuario." : "" }{ Pages.text( this.context.language, "shop.checkout.accessyouraccountsuggestion" ) }.</p>
                                                <div style={ { textAlign: "center" } }>
                                                    <ButtonToolbar>
                                                        <ButtonGroup>
                                                            <Button variant="pw-primary" href={ `${ Pages.getPage( "login" ).relativeUrl }?origin=${ this.page.id }&view=signup` }>{ signupText }</Button>
                                                        </ButtonGroup>
                                                        <ButtonGroup>
                                                            <Button variant="pw-secondary" href={ `${ Pages.getPage( "login" ).relativeUrl }?origin=${ this.page.id }&view=login` } style={ { marginLeft: "5px" } }>{ loginText }</Button>
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                </div>
                                            </div>
                                    }
                                </div>
                            }
                            <h2 id="checkout_customer_font">2. { Pages.text( this.context.language, "shop.checkout.customerformtitle" ) }:</h2>
                            { requiredSignup && !this.props.pwSession.loggedIn ? <p style={ { color: "red" } }>Antes de completar tus datos es necesario que crees tu cuenta de usuario. ¡Es muy sencillo!</p> : null }
                            { !requiredSignup || ( requiredSignup && this.props.pwSession.loggedIn ) ?
                                <div>
                                    <p>{ Pages.text( this.context.language, "shop.checkout.customerformtext" ) }:</p>
                                    <Row>
                                        <Col md="6">
                                            <Form.Label>{ Pages.text( this.context.language, "shop.checkout.fullname" ) }:</Form.Label>
                                            <Form.Control placeholder={ Pages.text( this.context.language, "shop.checkout.fullname" ) } value={ this.props.pwSession.shopAttributes.userName } onChange={ ( e ) => this.props.changeShopCartAttr( "userName", e.target.value ) } />
                                        </Col>
                                        <Col md="6">
                                            <Form.Label>{ Pages.text( this.context.language, "shop.checkout.email" ) }:</Form.Label>
                                            <Form.Control placeholder={ Pages.text( this.context.language, "shop.checkout.email" ) } value={ this.props.pwSession.shopAttributes.userEmail } onChange={ ( e ) => this.props.changeShopCartAttr( "userEmail", e.target.value ) } type="email" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Form.Label>{ Pages.text( this.context.language, "shop.checkout.mobile" ) }:</Form.Label>
                                            <Form.Control placeholder={ Pages.text( this.context.language, "shop.checkout.mobile" ) } value={ this.props.pwSession.shopAttributes.userMobile } onChange={ ( e ) => this.props.changeShopCartAttr( "userMobile", e.target.value ) } type="phone" />
                                        </Col>
                                        <Col md="6">
                                            <Form.Label>{ Pages.text( this.context.language, "shop.checkout.address" ) } / { Pages.text( this.context.language, "shop.checkout.onlyspain" ) } { this.props.shop.shippingValidProvincePostalCodes ? `(${ this.props.shop.shippingValidProvincePostalCodes.map( tmp => DataUtils.getProvincePostalCodeByPrefix( tmp ).state ).join( ", " ) })` : null }:</Form.Label>
                                            <Form.Control placeholder={ Pages.text( this.context.language, "shop.checkout.address" ) } value={ this.props.pwSession.shopAttributes.userAddress } onChange={ ( e ) => this.props.changeShopCartAttr( "userAddress", e.target.value ) } />
                                        </Col>
                                    </Row>
                                    { this.props.shop.shipping ?
                                        <Row>
                                            <Col md="6">
                                                <Form.Label>{ Pages.text( this.context.language, "shop.checkout.city" ) }:</Form.Label>
                                                <Form.Control placeholder={ Pages.text( this.context.language, "shop.checkout.city" ) } value={ this.props.pwSession.shopAttributes.userCity } onChange={ ( e ) => this.props.changeShopCartAttr( "userCity", e.target.value ) } />
                                            </Col>
                                            <Col md="6">
                                                <Form.Label>{ Pages.text( this.context.language, "shop.checkout.postalcode" ) }:</Form.Label>
                                                <Form.Control
                                                    placeholder={ Pages.text( this.context.language, "shop.checkout.postalcode" ) }
                                                    value={ this.props.pwSession.shopAttributes.userPostalCode }
                                                    onChange={ ( e ) => {
                                                        let postalCode = e.target.value;
                                                        let postalCodeResult = DataUtils.getPostalCode( postalCode, this.props.shop.shippingValidProvincePostalCodes );
                                                        this.props.changeShopCartAttr( "userPostalCode", postalCode );
                                                        if ( postalCodeResult ) {
                                                            this.props.changeShopCartAttr( "userProvince", postalCodeResult.state );
                                                        } else {
                                                            this.props.changeShopCartAttr( "userProvince", "" );
                                                        }
                                                    } }
                                                />
                                            </Col>
                                        </Row> : null }
                                    { this.props.shop.shipping ?
                                        <Row>
                                            <Col md="6">
                                                <Form.Label>{ Pages.text( this.context.language, "shop.checkout.state" ) }:</Form.Label>
                                                <Form.Control placeholder={ Pages.text( this.context.language, "shop.checkout.state" ) } disabled value={ this.props.pwSession.shopAttributes.userProvince } onChange={ ( e ) => this.props.changeShopCartAttr( "userProvince", e.target.value ) } />
                                            </Col>
                                            <Col md="6">
                                                <Form.Label>{ Pages.text( this.context.language, "shop.checkout.country" ) }:</Form.Label>
                                                <Form.Control placeholder="Country" value="España" disabled />
                                            </Col>
                                        </Row> : null }
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>{ Pages.text( this.context.language, "shop.checkout.comments" ) }:</Form.Label>
                                                <Form.Control value={ this.props.pwSession.shopAttributes.userComments } onChange={ ( e ) => this.props.changeShopCartAttr( "userComments", e.target.value ) } as="textarea" rows="3" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div> : null }
                        </div>
                        <div className="shop_cart_checkout_details">
                            <h2>3. { Pages.text( this.context.language, "shop.checkout.reviewpurchase" ) }:</h2>
                            <div className="shop_cart_items_container">
                                { this.renderItems() }
                            </div>
                            <div className="shop_cart_totals_bottom">
                                <div className="shop_cart_totals_right_panel">
                                    { this.props.shop.enablePromoCodes && !this.props.pwSession.shopAttributes.promoCode ? this.renderPromoCodeForm() : null }
                                    { this.props.shop.enablePromoCodes && this.props.pwSession.shopAttributes.promoCode ? this.renderPromoCode() : null }
                                    <Container>
                                        { this.props.shop.shipping ?
                                            <Row>
                                                <Col>{ Pages.text( this.context.language, "shop.cart.shippingprice" ) }:</Col>
                                                <Col><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.shippingPrice ) }</b>{ inStorePickUp ? <b style={ { color: "green" } }> (Recogida en tienda)</b> : null }</Col>
                                            </Row>
                                            : null }
                                        { this.props.shop.inStorePickUp ?
                                            <Row>
                                                <Col>
                                                    <div className="pws_card pw_shop_in_store_pickup">
                                                        <Form.Check
                                                            type="switch"
                                                            id="in-store-check"
                                                            checked={ inStorePickUp }
                                                            onChange={ () => {
                                                                this.props.changeShopAttr( "inStorePickUp", !this.props.pwSession.shop.inStorePickUp );
                                                            } }
                                                            label={ inStorePickUp ? "Desmarcar esta casilla si prefieres que te lo enviemos" : "Marcar esta casilla para recogida en tienda" }
                                                        />
                                                    </div>
                                                </Col>
                                            </Row> : null }
                                        { this.props.shop.shipping && cartTotals.amountForFreeShipping ?
                                            <Row>
                                                <Col><span className="pw_shop_free_shipping_message">{ Pages.text( this.context.language, "shop.cart.missingforfreeshipping", { freeShippingMissing: DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.amountForFreeShipping ) } ) }</span></Col>
                                            </Row>
                                            : null }
                                        { cartTotals.vatPercentages.map( vat => (
                                            <Row>
                                                <Col>{ this.props.shop.vatName} ({ DataUtils.formatIntegerPercentage( vat.percentage ) })</Col>
                                                <Col><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, vat.total ) }</b></Col>
                                            </Row>
                                        ) ) }
                                        { cartTotals.totalBeforeDiscount !== cartTotals.total ?
                                            <Row>
                                                <Col>Descuento:</Col>
                                                <Col><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.totalBeforeDiscount - cartTotals.total ) }</b></Col>
                                            </Row>
                                            : null }
                                    </Container>
                                </div>
                                <hr />
                                <div className="shop_cart_totals_right_panel">
                                    <Container>
                                        <Row>
                                            <Col><b className="shop_cart_total_price">{ Pages.text( this.context.language, "shop.checkout.reviewpurchase.total" ) }:</b></Col>
                                            <Col><b className="shop_cart_total_price">{ cartTotals.total === cartTotals.totalBeforeDiscount ? DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.total ) : <span><span style={ { textDecoration: "line-through" } }>{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.totalBeforeDiscount ) }</span><b>{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.total ) }</b></span> }</b></Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>
                        <div className="shop_cart_checkout_payment_method">
                            <h2>4. Forma de pago y confirmación</h2>
                            <div>
                                { !forcePaymentMethod ?
                                    <Tab.Container id="pw-payment-methods" defaultActiveKey={ selectedPaymentMethodTab }>
                                        <Row>
                                            <Col sm={ 3 }>
                                                <Nav variant="pills" className="flex-column">
                                                    { this.props.shop.paymentMethods.redsys && this.props.shop.paymentMethods.redsys.active ?
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                eventKey="redsys"
                                                                onClick={ () => {
                                                                    this.props.changeShopCartAttr( "paymentMethod", "redsys" );
                                                                } }
                                                            >
                                                                { Pages.text( this.context.language, "shop.purchase.paymentmethod.redsys" ) }
                                                            </Nav.Link>
                                                        </Nav.Item> : null }
                                                    { this.props.shop.paymentMethods.stripe && this.props.shop.paymentMethods.stripe.active ?
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                eventKey="stripe"
                                                                onClick={ () => {
                                                                    this.props.changeShopCartAttr( "paymentMethod", "stripe" );
                                                                } }
                                                            >
                                                                { Pages.text( this.context.language, "shop.purchase.paymentmethod.stripe" ) }
                                                            </Nav.Link>
                                                        </Nav.Item> : null }
                                                    { this.props.shop.paymentMethods.bankTransfer && this.props.shop.paymentMethods.bankTransfer.active ?
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                eventKey="banktransfer"
                                                                onClick={ () => {
                                                                    this.props.changeShopCartAttr( "paymentMethod", "banktransfer" );
                                                                } }
                                                            >
                                                                { Pages.text( this.context.language, "shop.checkout.banktransfer" ) }
                                                            </Nav.Link>
                                                        </Nav.Item> : null }
                                                    { this.props.shop.paymentMethods.payLater && this.props.shop.paymentMethods.payLater.active ?
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                eventKey="paylater"
                                                                onClick={ () => {
                                                                    this.props.changeShopCartAttr( "paymentMethod", "paylater" );
                                                                } }
                                                            >
                                                                { Pages.text( this.context.language, "shop.checkout.paylater" ) }
                                                            </Nav.Link>
                                                        </Nav.Item> : null }
                                                    { this.props.shop.paymentMethods.pagantis && this.props.shop.paymentMethods.pagantis.active ?
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                eventKey="pagantis"
                                                                onClick={ () => {
                                                                    this.loadPagantis( cartTotals.total );
                                                                    this.props.changeShopCartAttr( "paymentMethod", "pagantis" );
                                                                } }
                                                            >
                                                                { Pages.text( this.context.language, "shop.checkout.pagantis" ) }
                                                            </Nav.Link>
                                                        </Nav.Item> : null }
                                                </Nav>
                                            </Col>
                                            <Col sm={ 9 }>
                                                <Tab.Content>
                                                    { this.props.shop.paymentMethods.redsys && this.props.shop.paymentMethods.redsys.active ?
                                                        <Tab.Pane eventKey="redsys">
                                                            <h5>{ Pages.text( this.context.language, "shop.purchase.paymentmethod.redsys" ) }</h5>
                                                            <div>{ Pages.text( this.context.language, "shop.purchase.paymentmethod.redsys.description" ) }.</div>
                                                            <img src="/static/powered_by_redsys.png" width="125" style={ { marginTop: "10px" } } alt="Powered by Redsys" />
                                                        </Tab.Pane> : null
                                                    }
                                                    { this.props.shop.paymentMethods.stripe && this.props.shop.paymentMethods.stripe.active ?
                                                        <Tab.Pane eventKey="stripe">
                                                            <h5>{ Pages.text( this.context.language, "shop.purchase.paymentmethod.stripe" ) }</h5>
                                                            <div>{ Pages.text( this.context.language, "shop.purchase.paymentmethod.stripe.description" ) }.</div>
                                                            <img src="/static/powered_by_stripe.svg" width="125" style={ { marginTop: "10px" } } alt="Powered by Stripe" />
                                                        </Tab.Pane> : null
                                                    }
                                                    { this.props.shop.paymentMethods.bankTransfer && this.props.shop.paymentMethods.bankTransfer.active ?
                                                        <Tab.Pane eventKey="banktransfer">
                                                            <h5>{ Pages.text( this.context.language, "shop.checkout.banktransfer" ) }:</h5>
                                                            { Pages.text( this.context.language, "shop.checkout.banktransfertext" ) }
                                                        </Tab.Pane> : null }
                                                    { this.props.shop.paymentMethods.pagantis && this.props.shop.paymentMethods.pagantis.active ?
                                                        <Tab.Pane eventKey="pagantis">
                                                            <img src="/static/Pagantis_Logo_RGB.png" alt={ this.page.seoDefaultAlt } style={ { height: 50 } } />
                                                            <h5>{ Pages.text( this.context.language, "shop.checkout.pagantistitle" ) }</h5>
                                                            { Pages.text( this.context.language, "shop.checkout.pagantistext" ) }
                                                            <div className="pagantisSimulator" />
                                                        </Tab.Pane> : null }
                                                    { this.props.shop.paymentMethods.payLater && this.props.shop.paymentMethods.payLater.active ?
                                                        <Tab.Pane eventKey="paylater">
                                                            <h5>{ Pages.text( this.context.language, "shop.checkout.paylater" ) }:</h5>
                                                            { Pages.text( this.context.language, "shop.checkout.paylatertext" ) }
                                                        </Tab.Pane> : null }
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container> : null }
                            </div>
                            <div className="shop_cart_totals_bottom">
                                <h5>{ Pages.text( this.context.language, "shop.checkout.paymentmethod" ) }: { Pages.text( this.context.language, `shop.checkout.${ selectedPaymentMethodTab }` ) }</h5>
                                <hr />
                                <Row>
                                    <Col>
                                        <Form.Group controlId="shopCheckoutCommercialAccept">
                                            <Form.Check style={ { margin: "20px 0 10px 0" } }>
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    checked={ this.props.pwSession.webUserAcceptNewsletter }
                                                    onChange={ () => {
                                                        this.props.changeSessionAttr( "webUserAcceptNewsletter", !this.props.pwSession.webUserAcceptNewsletter );
                                                    } }
                                                />
                                                <Form.Check.Label><div dangerouslySetInnerHTML={ { __html: Pages.text( lang, "rgpd.footer.contact.acceptcommercial" ) } } /></Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>
                                        <Form.Group controlId="shopCheckoutPrivacyAccept">
                                            <Form.Check style={ { margin: "20px 0 10px 0" } }>
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    checked={ this.props.pwSession.acceptPrivacyPolicy }
                                                    onChange={ () => {
                                                        this.props.changeSessionAttr( "acceptPrivacyPolicy", !this.props.pwSession.acceptPrivacyPolicy );
                                                    } }
                                                />
                                                <Form.Check.Label><div dangerouslySetInnerHTML={ { __html: Pages.text( lang, "shop.checkout.accepttermsconditions" ) } } /></Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="shop_cart_totals_right_panel">
                                    { showPrepaymentOptions && this.props.pwSession.shop.prepaymentActive && this.props.UIContext.loaded ?
                                        <Alert variant="pw-purple">
                                            <p><b>{ Pages.text( this.context.language, "shop.cart.prepaymentservices.active.title" ) }</b></p>
                                            <p>{ Pages.text( this.context.language, "shop.cart.prepaymentservices.active.description", { prepaymentServicePrice: DataUtils.formatIntegerPrice( this.props.shop.currency, this.props.shop.prepaymentAmountForService ) } ) }</p>
                                            <ButtonGroup>
                                                <Button
                                                    onClick={ () => {
                                                        this.props.changeShopAttr( "prepaymentActive", false );
                                                    } }
                                                    variant="pw-purple"
                                                    size="sm"
                                                >
                                                    <Icon name="cancel" color="white" /> Prefiero pagar el total
                                                </Button>
                                            </ButtonGroup>
                                        </Alert> : null }
                                    <Container>
                                        <Row>
                                            <Col><b className="shop_cart_total_price">{ Pages.text( this.context.language, "shop.checkout.totalfinal" ) }:</b></Col>
                                            <Col><b className="shop_cart_total_price">{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.total ) }{ this.props.pwSession.shopAttributes.paymentMethod === "pagantis" ? "+ Intereses" : null }</b></Col>
                                        </Row>
                                        { showPrepaymentOptions && this.props.pwSession.shop.prepaymentActive ?
                                            <Row>
                                                <Col><b className="shop_cart_total_price">A pagar ahora:</b></Col>
                                                <Col><b className="shop_cart_total_price">{ DataUtils.formatIntegerPrice( this.props.shop.currency, cartTotals.prepaymentTotalAmount ) }</b></Col>
                                            </Row> : null }
                                    </Container>
                                </div>
                                <div className="shop_cart_buttons_top" style={ { clear: "both" } }>
                                    { forcePaymentMethod === "paylater" ? <p className="pw_force_payment_checkout_text">{ Pages.text( this.context.language, "shop.checkout.paylater.lasttext" ) }</p> : null }
                                    <ButtonGroup className="pw_fullwidth">
                                        <Button href={ Pages.getPage( "shop-cart" ).relativeUrl } variant="info"><Icon name="nav-arrow-left" color="white" /> { Pages.text( this.context.language, "shop.checkout.backcart" ) }</Button>
                                        { this.renderProcessPurchaseButton() }
                                    </ButtonGroup>
                                </div>
                            </div>
                            { this.renderCenteredModal() }
                            { Pages.company.RGPDMessagesReady ?
                                <div className="pw_rgpd_form_box">
                                    <ExpandCollapse
                                        key="footer-rgpd-expand"
                                        title={ <span>Cumplimos con el <b>RGPD</b> (Reglamento General de Protección de Datos). Clic aquí para más información acerca de este formulario.</span> }
                                        expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                        collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                    >
                                        <div>
                                            <p><b>Responsable:</b> { Pages.company.lopdFullName }</p>
                                            <p><b>Finalidad:</b> Poder gestionar tu compra, y las comunicaciones necesarias así como poder realizar estudios estadísticos que ayuden a mejorar el funcionamiento de la página web y a informar de forma puntual.</p>
                                            <p><b>Legitimación:</b> Consentimiento del interesado.</p>
                                            <p><b>Destinatarios:</b>
                                                { Pages.company.RGPDStripeInfo ? <p><b>Stripe Payments Europe Ltd</b>(“Stripe”, Pasarela de pagos): Al hacer clic en "Procesar compra" serás redirigido a nuestro proveedor de servicios de pagos <a href="https://stripe.com/" className="pw_legal_link" rel="noopener noreferrer" target="_blank">Stripe</a> responsable de las transacciones de pago, al que le cederemos tu email para el autocompletado en el formulario de pago. { Pages.company.lopdFullName } no dispone ni tratará los datos financieros y fiscales que usted proporcione a Stripe. Su tratamiento y seguridad se regirán por la política de privacidad de Stripe, que le recomendamos que usted consulte en su web <a href="https://stripe.com/es/privacy#translation" rel="noopener noreferrer" target="_blank" className="pw_legal_link">Stripe - Política de Privacidad</a>. También puede obtener más información acerca de Stripe y RGPD en el siguiente enlace <a href="https://stripe.com/es/guides/general-data-protection-regulation" rel="noopener noreferrer" target="_blank" className="pw_legal_link">Stripe - Información acerca de RGPD</a>.</p> : null }
                                                { Pages.company.RGPDAWSInfo ? <p><b>Amazon Web Services EMEA SARL</b>: Respecto al servicio de alojamiento de la web, usamos los servicios de Amazon Web Services, teniendo los datos alojados en centros de datos situados en la Unión Europea. Puedes consultar la política de privacidad de Amazon Web Services en el siguiente enlace <a href="https://aws.amazon.com/es/compliance/data-privacy-faq/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Privacidad de datos</a> o también puedes consultar su información acerca de RGPD en el siguiente enlace:<a href="https://aws.amazon.com/es/compliance/gdpr-center/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Reglamento General de Protección de Datos (RGPD)</a></p> : null }
                                                <p>La información de este formulario no será comunicada a otros destinatarios, salvo obligación legal. La información es tratada con la máxima confidencialidad, siendo alojados en un servidor seguro.</p>
                                            </p>
                                            <p><b>Derechos:</b> Tienes derecho a acceder, rectificar y suprimir tus datos, derechos que puedes ejercer enviando un correo electrónico a { Pages.company.lopdEmail }</p>
                                            <p>Puedes consultar la información adicional y detallada sobre protección de datos en el apartado <a href={ Pages.getPage( "privacy-policy" ).relativeUrl }>política de privacidad</a>.</p>
                                        </div>
                                    </ExpandCollapse>
                                </div> : null }
                        </div>
                    </Container>
                </div>
            </StripeProvider>
        );
    }
}
ShopCartCheckout.serverFetch = shopStateData;
ShopCartCheckout.contextType = WebContext;
ShopCartCheckout.serverFetchType = {
    type: "data",
    modules: [
        { module: "shop", serverFetch: shopStateData },
        { module: "content", serverFetch: contentStateData }
    ]
};

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    content: state.content,
    shop: state.shop
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( ShopCartCheckout ) );
