import { matchPath } from "react-router-dom";
import GenericPage from "../../components/GenericPage";
import GenericDashboard from "../../components/GenericDashboard";
import GenericShop from "../../components/GenericShop";
import GenericShopCart from "../../components/GenericShopCart";
import GenericShopCartCheckout from "../../components/GenericShopCartCheckout";
import GenericShopPurchase from "../../components/GenericShopPurchase";
import GenericMyShopCarts from "../../components/GenericMyShopCarts";
import GenericMyCourses from "../../components/GenericMyCourses";
// signup
import GenericSignup from "../../components/GenericSignup";

import Home from "../../components/Home";
import store from "../../store";

// eslint-disable-next-line no-undef
export const ENVIRONMENT = typeof WEBPACK_ENVIRONMENT === "undefined" ? process.env.NODE_ENV : WEBPACK_ENVIRONMENT;

export const PageComponents = {};
PageComponents.PrivacyPolicy = require( "./components/PrivacyPolicy" ).default;
PageComponents.ServiceConditions = require( "./components/ServiceConditions" ).default;
PageComponents.CookiesPolicy = require( "./components/CookiesPolicy" ).default;

export const getUrlBase = ( env ) => {
    if ( env === "development" ) {
        return "http://localhost:2048";
    }
    if ( env === "staging" ) {
        return "http://staging.liveworkflows.com";
    }
    return "https://liveworkflows.com";
};

export const company = {
    name: "Live Workflows",
    legalName: "Live Workflows",
    domain: "liveworkflows.com",
    lopdFullName: "Live Workflows",
    lopdNIF: "Y3139033F",
    lopdEmail: "info@liveworkflows.com",
    lopdPostalAddress: "Passatge Maneu 14",
    lopdPhone: "",
    facebookPageURL: "https://www.facebook.com/Palma-Webs-1096104697264758",
    instagramPageURL: "https://www.instagram.com/liveworkflows/",
    whatsappContactURL: "",
    googleFontsTag: "",
    adobeFontsTag: "<link rel=\"stylesheet\" href=\"https://use.typekit.net/kng6epm.css\">",
    googleAnalyticsTrackingID: "UA-143522464-1",
    facebookAPPID: "348655195810991",
    contactEmail: "info@liveworkflows.com",
    managerURLRelative: "/liveworkflows",
    googleSignInClientID: "751791303968-57gt7p9jpj3hlfss9vgu0rfjea9f22of.apps.googleusercontent.com",
    baseURL: getUrlBase( ENVIRONMENT ),
    smartlookCode: null,
    cliengoScriptUrl: "",
    ZohoChatSalesIQCode: null,
    facebookPixelId: "343799503255870",
    dashboardURLRelative: "/dashboard",
    modSignupEnabled: true,
    shopCart: false,
    modSignupFacebookEnabled: false,
    dashboardModules: [ "welcome", "questions", "myplan" ],
    adultsOnlyPage: false,
    footerHideFacebook: true,
    footerDisplaySocialIcons: true,
    footerHideContactButtons: true,
    googleTagManagerId: null,
    RGPDCommercialNotificationsCheck: true,
    RGPDSignupCommercialNotificationsCheck: true,
    RGPDStripeInfo: true,
    RGPDAWSInfo: true,
    RGPDMessagesReady: true,
    inMaintenance: false,
    showSliderAnimatedBackground: true,
    stopBookingUnavailable: true
};

export const gtag = null;
export const stripe = null;
export const pagantis = null;

export const config = {
    reCaptchaSiteKey: "6LeqmKoUAAAAAPBWBD8aQmVM9XIc4qNQgsjGHdFJ"
};

export const NavBar = {
    type: "offcanvas",
    showShopCategories: false,
    showShopCategoriesMainGroups: false,
    showShopSearch: false,
    hideShopSearchInPages: [ "home" ],
    topNavBar: {
        enabled: true,
        instagramLink: "https://www.instagram.com/liveworkflows/",
        facebookLink: company.facebookPageURL,
        cartLink: "/carrito",
        cartText: "Mi carrito"
    },
    customMenuItems: []
};

export const all = [
    {
        path: "(/|/en/|/es/)",
        relativeUrl: "/",
        relativeUrlEn: "/en",
        url: "https://liveworkflows.com",
        component: Home,
        exact: true,
        id: "home",
        menuText: "Inicio",
        menuTextEn: "Home",
        menuHidden: true,
        title: "Inicio",
        titleEn: "Home",
        subTitle: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        subTitleEn: "Live Workflows | Expert-designed digital solutions for expanding businesses",
        breadcrumb: [],
        seoTitle: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoTitleEn: "Live Workflows | Expert-designed digital solutions for expanding businesses",
        seoDescription: "En Live Workflows creamos publicaciones con análisis de flujos de trabajo y cómo se pueden automatizar eficientemente",
        seoKeywords: "Workflows automations, automatizacion de flujos de trabajo, soluciones digitales, digital solutions, plataformas, platforms, apps",
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        embeds: [],
        navBarCSSPosition: "fixed",
        gallery: {}
    },
    {
        path: "/desarrollo-web-palma/politica-privacidad",
        relativeUrl: "/desarrollo-web-palma/politica-privacidad",
        url: "https://liveworkflows.com/desarrollo-web-palma/politica-privacidad",
        exact: true,
        component: GenericPage,
        childComponentFile: "PrivacyPolicy",
        id: "privacy-policy",
        menuText: "Política de privacidad",
        menuTextEn: "Privacy Policy",
        menuHidden: true,
        title: "Política de privacidad",
        subTitle: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        breadcrumb: [ "home", "privacy-policy" ],
        seoTitle: "Política de privacidad",
        seoDescription: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        seoKeywords: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "/desarrollo-web-palma/condiciones-servicio",
        relativeUrl: "/desarrollo-web-palma/condiciones-servicio",
        url: "https://liveworkflows.com/desarrollo-web-palma/condiciones-servicio",
        exact: true,
        component: GenericPage,
        childComponentFile: "ServiceConditions",
        id: "terms-conditions",
        menuText: "Términos y Condiciones Generales",
        menuTextEn: "Terms and General Conditions",
        menuHidden: true,
        title: "Términos y Condiciones Generales",
        subTitle: `${ company.legalName } informa a continuación los Términos y Condiciones Generales de los servicios que ofrece desde su sitio web ${ company.domain }`,
        breadcrumb: [ "home", "terms-conditions" ],
        seoTitle: "Condiciones de servicio",
        seoDescription: "",
        seoKeywords: "",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "/desarrollo-web-palma/politica-cookies",
        relativeUrl: "/desarrollo-web-palma/politica-cookies",
        url: "https://liveworkflows.com/desarrollo-web-palma/politica-cookies",
        exact: true,
        component: GenericPage,
        childComponentFile: "CookiesPolicy",
        id: "cookies-policy",
        menuText: "Política de Cookies",
        menuTextEn: "Cookies Policy",
        menuHidden: true,
        title: "Política de Cookies",
        subTitle: "",
        breadcrumb: [ "home", "cookies-policy" ],
        seoTitle: "",
        seoDescription: "",
        seoKeywords: "",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "(/dashboard)",
        relativeUrl: "/dashboard",
        url: "https://liveworkflows.com/dashboard",
        exact: true,
        component: GenericDashboard,
        id: "dashboard",
        menuText: null,
        menuTextEn: null,
        menuHidden: true,
        title: "Dashboard",
        subTitle: "Datos generales de mi cuenta",
        seoTitle: "Live Workflows | Soluciones digitales",
        breadcrumb: [ "home", "dashboard" ],
        gallery: {},
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitale diseñadas por expertos para negocios en expansión."
    },
    {
        path: "(/mis-cursos)",
        relativeUrl: "/mis-cursos",
        url: "https://liveworkflows.com/mis-cursos",
        exact: true,
        component: GenericMyCourses,
        id: "my-courses",
        menuText: null,
        menuTextEn: null,
        menuHidden: true,
        title: "Mis cursos",
        subTitle: "",
        seoTitle: "Mis cursos",
        seoDescription: "Mis cursos",
        seoKeywords: "Mis cursos",
        breadcrumb: null,
        gallery: {},
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitale diseñadas por expertos para negocios en expansión."
    },
    {
        path: "(/mis-compras)",
        relativeUrl: "/mis-compras",
        url: "https://liveworkflows.com/mis-compras",
        exact: true,
        component: GenericMyShopCarts,
        id: "my-shop-carts",
        menuText: null,
        menuTextEn: null,
        menuHidden: true,
        title: "Mis compras",
        subTitle: "",
        seoTitle: "Mis compras",
        seoDescription: "Mis compras",
        seoKeywords: "Mis compras",
        breadcrumb: null,
        gallery: {},
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitale diseñadas por expertos para negocios en expansión."
    },
    {
        path: "(/desarrollo-web-palma/plan-premium|/plan-web-premium-acceder|/web-premium/.*)",
        relativeUrl: "/plan-web-premium-acceder",
        url: "https://liveworkflows.com/plan-web-premium-acceder",
        exact: true,
        component: GenericSignup,
        topDynamicContent: null,
        id: "login",
        menuText: "Mi cuenta",
        menuTextEn: null,
        menuHidden: true,
        title: null,
        subTitle: null,
        breadcrumb: [],
        seoTitle: "Live Workflows | Soluciones digitales",
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        embeds: [],
        gallery: {}
    },
    {
        path: "(/compras.*)",
        relativeUrl: "/compras",
        url: "https://liveworkflows.com/compras",
        exact: true,
        active: company.shopCart,
        component: GenericShop,
        childComponentFile: "Shop",
        id: "shop",
        menuText: "COMPRAS",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: [],
        seoTitle: "Compra de bonos de servicios",
        seoDescription: "Compra de bonos de servicios",
        seoKeywords: "Compra de bonos de servicios",
        embeds: [],
        gallery: {},
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        navBarCSSPosition: "fixed"
    },
    {
        path: "(/carrito)",
        relativeUrl: "/carrito",
        url: "https://liveworkflows.com/carrito",
        exact: true,
        component: GenericShopCart,
        childComponentFile: "ShopCart",
        id: "shop-cart",
        menuText: "Carrito",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: [],
        seoTitle: "Compra de bonos de servicios",
        seoDescription: "Compra de bonos de servicios",
        seoKeywords: "Compra de bonos de servicios",
        embeds: [],
        gallery: {},
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        navBarCSSPosition: "fixed"
    },
    {
        path: "(/compra/detalle.*)",
        relativeUrl: "/compra/detalle",
        url: "https://liveworkflows.com/compra/detalle",
        exact: true,
        component: GenericShopPurchase,
        id: "shop-purchase",
        menuText: "Detalle de Compra",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: [],
        seoTitle: "Compra de bonos de servicios",
        seoDescription: "Compra de bonos de servicios",
        seoKeywords: "Compra de bonos de servicios",
        embeds: [],
        gallery: {},
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        navBarCSSPosition: "fixed"
    },
    {
        path: "(/carrito/checkout)",
        relativeUrl: "/carrito/checkout",
        url: "https://liveworkflows.com/carrito/checkout",
        exact: true,
        component: GenericShopCartCheckout,
        childComponentFile: "ShopCartCheckout",
        id: "shop-cart-checkout",
        menuText: "Carrito Checkout",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: [],
        seoTitle: "Compra de bonos de servicios",
        seoDescription: "Compra de bonos de servicios",
        seoKeywords: "Compra de bonos de servicios",
        embeds: [],
        gallery: {},
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        navBarCSSPosition: "fixed"
    },
    {
        path: "(/p/.*)",
        relativeUrl: "/p",
        url: "https://liveworkflows.com/p",
        component: GenericPage,
        id: "pages",
        menuText: "",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: [],
        embeds: [],
        seoTitle: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoTitleEn: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDescription: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoKeywords: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoAuthor: "Live Workflows | Soluciones digitales diseñadas por expertos para negocios en expansión",
        seoDefaultAlt: "Live Workflows | Soluciones digitale diseñadas por expertos para negocios en expansión."
    }
];
const getPagePropByLang = ( page, lang, prop ) => {
    const langProp = `${ prop }${ lang.charAt( 0 ).toUpperCase() + lang.slice( 1 ) }`;
    if ( typeof page[ langProp ] !== "undefined" && page[ langProp ] ) {
        return page[ langProp ];
    }
    return page[ prop ];
};

const applyPageOverwrites = ( page ) => {
    let result = page;
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            result = Object.assign( {}, page, { title: getPagePropByLang( page, lang, "title" ) } );
            Object.keys( page ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( page, lang, key );
            } );
        }
    }
    return result;
};

export const getPage = ( id ) => {
    let result = all.find( ( page ) => page.id === id );
    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

const texts = {
    es: {
        "navbar.title": "",
        "footer.contact.text1": "¿Necesitas más información?",
        "footer.contact.text2": "Coméntame brevemente tu idea y te contestaré a la brevedad 👍",
        "footer.contact.fullname": "Nombre Completo",
        "footer.contact.fullname.description": "Escribe tu nombre completo para poder dirigirme a ti correctamente.",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "Escribe aquí tu email para que podamos contestar a la brevedad.",
        "footer.contact.phone": "Teléfono",
        "footer.contact.phone.description": "Escribe aquí tu teléfono si prefieres que te contactemos por Whatsapp o una llamada.",
        "footer.contact.message": "Mensaje",
        "footer.contact.privacypolicy": `He leído y acepto la <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Política de privacidad</a>${ company.footerTermsConditions ? `<span> y los <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span>` : "" }`,
        "footer.contact.sendmessage": "Enviar consulta",
        "footer.contact.orremember": "O recuerda que también puede contactarnos a través de",
        "footer.contact.emailbutton": "Correo electrónico",
        "footer.contact.whatsapp": "Enviar un WhatsApp",
        "footer.contact.visitfacebook": "Visítanos en Facebook",
        "footer.contact.successmessage.text1": "Gracias por ponerte en contacto con nosotros.",
        "footer.contact.successmessage.text2": "Hemos recibido tu mensaje y te responderemos a la brevedad a",
        "footer.contact.successmessage.text3": "En {companyName} nos tomamos muy en serio las consultas de nuestros usuarios. Muchas gracias por confiar en nosotros.",
        "footer.copyright.text1": "Copyright Live Workflows - Sitios Web y Marketing",
        "footer.contactinfo.form.title": "Déjanos tu mensaje",
        "footer.contactinfo.about.title": "Nuestro centro",
        "rgpd.footer.contact.acceptcommercial": "Acepto recibir comunicaciones comerciales",
        "rgpd.generic.validation.acceptcommercial": "Debes aceptar recibir comunicaciones comerciales para poder continuar",
        "navbar.search": "Buscar",
        // news
        "news.categories": "Categorías",
        "news.viewmore": "Ver más",
        // customs
        "home.cover.title": "Sitios Web y Marketing",
        "home.cover.subtitle": "para autónomos",
        "home.cover.paragraph1": "¿Tienes mucho trabajo?",
        "home.cover.paragraph2": "Deja que nuestros expertos trabajen en tu sitio web y tu presencia en internet para que tu negocio mejore mientras tú sigues con el resto.",
        "home.cover.button": "Comenzar con mi nueva web",
        "home.plan.title": "¿Te gustaría que trabajemos juntos?",
        "home.plan.paragraph": "Sabemos que necesitas que nos encarguemos de casi todo ... ¡por eso tenemos un plan!",
        "home.plan.premium.text1": "Nuestro plan inicial, con el que tendrás tu nueva web, además de una ",
        "home.plan.premium.text2": "gran cantidad de beneficios",
        "home.plan.premium.text3": "Creamos o rediseñamos ",
        "home.plan.premium.text4": "tu sitio web optimizado para móviles y ordenadores",
        "home.plan.premium.text5": "en nuestra plataforma",
        "home.plan.premium.text6": "Posicionamiento en buscadores",
        "home.plan.premium.text7": "Estrategia alineada con tus",
        "home.plan.premium.text8": "Redes Sociales ",
        "home.plan.premium.text9": "Actualizaciones y ",
        "home.plan.premium.text10": "mejoras técnicas periódicas incluídas",
        "home.plan.premium.text11": "Alojamiento en servidores incluído",
        "home.plan.premium.text12": "",
        "home.plan.premium.text13": "Actualizaciones de texto, imágenes y video",
        "home.plan.premium.text14": "incluídas (Máx. 4 al mes)",
        "home.plan.premium.text15": "Dominio incluído",
        "home.plan.premium.text16": "El precio más competitivo por tu web y presencia en internet ¡gestionada por expertos!",
        "home.plan.premium.text17": "423.50 EUR/anual",
        "home.plan.premium.text18": "* IVA INCLUÍDO",
        "home.plan.premium.text19": "La oferta no incluye cambios que necesiten programación ni adaptaciones que no se en este listado 👆. Contáctanos para más info.",
        "home.plan.premium.button": "¡Clic para comenzar!",
        "home.customers.text1": "Algunos de nuestros trabajos",
        "home.customers.text2": "Los autónomos nos elijen para el diseño de sus sitios web, SEO, gestión de redes sociales, logos y estrategia digital. Porque gracias a nuestro trabajo, mejora su imagen, su cartera de clientes y tienen la posibilidad de compartir en internet sus novedades con contenidos de calidad y auténticos.",
        "home.customers.text3": "Caso de éxito",
        "home.customers.text4": "Si buscas <b><i>&quot;Peluquería canina en Mallorca&quot;</i></b>, <b>Pichichus</b> sale entre los primeros resultados, sin pagar por publicidad a Google. Luego cuando un usuario accede a <a href=\"https://www.pichichus.com\" target=\"_blank\" rel=\"noopener noreferrer\" >www.pichichus.com</a>, se encuentra con una web muy chula, creativa y con el estilo de Pichichus para llamar y agendar una cita con solo un clic.",
        "home.customers.button": "Comenzar con mi nueva web",
        // mg
        "mg.generic.validation.empty": "Debes completar todos los datos para continuar",
        "mg.generic.validation.invalidemail": "El email ingresado no es válido",
        "mg.generic.validation.emailalreadyexists": "El email ingresado ya se encuentra en uso",
        "mg.customers.status.active": "Cliente",
        "mg.customers.status.inactive": "Desactivado",
        "mg.invoices.status.editing": "Editando",
        "mg.invoices.status.sent": "Enviada",
        "mg.invoices.status.paid": "Pagada",
        "mg.courses.status.editing": "En edición",
        "mg.courses.status.active": "Publicado",
        "mg.courses.status.inactive": "Desactivado",
        "mg.team.status.active": "Activo",
        "mg.team.status.inactive": "Desactivado",
        "mg.generic.validation.giftcard.existingcode": "El código de tarjeta ya ha sido utilizado antes. Modifique el código para continuar.",
        // signup & login
        "signup.form.title": "Completa con tus datos para crear una cuenta",
        "signup.form.subtitle": "",
        "signup.form.facebook.login.error": "Hubo un error en la autenticación a través de Facebook. Por favor, vuelve a intentarlo o completa el formulario de registro.",
        "signup.form.readandacceptpolicy": "He leído y acepto la",
        "signup.form.privacypolicy": "Política de privacidad",
        "signup.form.signupbutton": "Crear mi cuenta",
        "signup.form.backloginbutton": "Acceder a mi cuenta",
        "signup.form.separator.text": "¿Ya tienes cuenta?",
        "signup.validation.emailalreadyexists": "Ya existe una cuenta vinculada al email ingresado. Por favor, accede a tu cuenta o recupera tu clave.",
        "signup.validation.passwordstrength": "La clave debe contener al menos 6 caracteres e incluir al menos una letra y un número. Se recomienda usar símbolos y mayúsculas para mayor seguridad.",
        "signup.password.hinttext": "Mínimo 6 caracteres, con al menos una letra y un número",
        "signup.form.facebookbutton": "Continuar con Facebook",
        "signup.validation.facebook.sessionexpired": "Se ha producido un error al conectarse con Facebook, por favor, vuelva a intentarlo.",
        "signup.success.title": "Accede a tu email para confirmar tu cuenta",
        "signup.success.text": "Te hemos enviado un correo a {email} para validar tu dirección de email. Revisa tu correo y verifica tu cuenta para poder continuar.",
        "signup.login.email.notverified": "Tienes pendiente la verificación de tu cuenta de correo. Por favor, accede a tu correo y continúa la verificación de tu cuenta. Muchas gracias",
        "signup.login.invalid.credentials": "El usuario y clave ingresados son incorrectos",
        "login.form.title": "Accede a tu cuenta",
        "login.form.subtitle": "",
        "login.form.loginbutton": "Acceder a mi cuenta",
        "login.form.separator.text": "¿No tienes cuenta?",
        "login.form.signupbutton": "Crear una cuenta",
        "checkout.form.selectplan": "Seleccionar plan",
        "checkout.form.selectplan.text": "Debes seleccionar un plan para comenzar",
        "checkout.form.selectplan.selector": "Seleccionar ...",
        "checkout.validation.useralreadysubscribed": "Ya te encuentras dado de alta en este Plan.",
        "checkout.form.title": "",
        "generic.validation.close": "cerrar",
        "generic.validation.empty": "Complete todos los datos del formulario para poder continuar. Muchas gracias.",
        "generic.validation.recaptcha": "Marque la casilla \"No soy un robot\" para poder continuar.",
        "generic.validation.privacypolicy": "Debe aceptar la Política de Privacidad para continuar.",
        "generic.validation.invalidemail": "Por favor compruebe que su dirección de email es correcta. Al parecer no tiene un formato válido.",
        "generic.validation.passwordmismatch": "La confirmación de la clave no coincide. Compruebe que las claves coinciden exactamente.",
        "generic.server.error": "Hubo un error en la conexión con el servidor.",
        "signup.emailverified.success": "¡Enhorabuena! ¡Ya puedes utilizar tu cuenta!",
        "signup.navbar.login": "Acceder",
        "signup.navbar.signup": "Crear cuenta",
        "signup.navbar.dashboard": "Mi cuenta",
        "signup.navbar.logout": "Cerrar",
        "checkout.currentproduct.content.title": "Datos de mi plan actual",
        "checkout.currentproduct.content.subtitle": "¡Enhorabuena! Actualmente te encuentras suscrito a nuestro plan",
        "checkout.currentproduct.yourplan": "Tu plan actual",
        "checkout.currentproduct.yourplan.text": "Actualmente te encuentras suscrito al siguiente plan:",
        "signup.form.signuppassword.title": "Ingresa una clave",
        "footer.floatingvideo.sendmessage": "Déjame un mensaje",
        "signup.form.passwordconfirmation": "Confirmar Clave",
        "signup.form.password": "Clave",
        "signup.resetpassword.title": "Crea una nueva clave de acceso",
        "signup.resetpassword.subtitle": "Escribe tu nueva clave de acceso",
        "signup.form.newpasswordconfirmation": "Confirmar nueva clave",
        "signup.form.newpassword": "Nueva clave",
        "signup.form.newpassword.save": "Guardar cambios",
        "signup.forgotpassword.title": "¿Has olvidado tu contraseña?",
        "signup.forgotpassword.subtitle": "Escribe el email de tu cuenta para recuperarla",
        "signup.forgotpassword.emailnotexists": "El email que has ingresado no pertenece a una cuenta de usuario.",
        "signup.forgotpassword.pleasecheckemail": "Por favor, revisa tu correo electrónico. Te hemos enviado un enlace con el que podrás crear una nueva clave de acceso.",
        "signup.forgotpassword.processbutton": "Recuperar clave",
        "signup.login.newpasswordsaved": "Tu nueva clave ha sido guardada. Ingresa tu email para acceder.",
        "signup.form.forgotpasswordlink": "¿Has olvidado tu contraseña?",
        "signup.emailverified.textclicktocontinue": "Haz clic en el siguiente enlace para continuar",
        "signup.emailverified.continue": "Continuar",
        "shop.checkout.login": "Acceder a mi cuenta",
        "shop.checkout.signup": "Crear cuenta",
        // shop
        "shop.validation.empty": "Complete todos los datos del formulario para poder continuar. Muchas gracias.",
        "shop.validation.recaptcha": "Marque la casilla \"No soy un robot\" para poder continuar.",
        "shop.validation.privacypolicyandterms": "Debe aceptar la Política de Privacidad y los Términos y Condiciones para continuar.",
        "shop.validation.invalidemail": "Por favor compruebe que su dirección de email es correcta. Al parecer no tiene un formato válido.",
        "shop.validation.emptycart": "Su carrito se encuentra vacío o su sesión ha expirado.",
        "shop.validation.notexistingcartitem": "No se ha podido procesar su carrito porque uno de los productos seleccionados ha dejado de estar disponible. Por favor, vuelva a intentarlo más tarde. Muchas gracias.",
        "shop.validation.modifiedpricecartitem": "No se ha podido procesar su carrito porque el precio de uno de los productos seleccionados ha sido modificado. Por favor, vuelva a intentarlo más tarde. Muchas gracias.",
        "shop.validation.expiredcartitem": "No se ha podido procesar su carrito porque su sesión ha expirado",
        "shop.validation.unavailableitem": "No se ha podido procesar su carrito porque su sesión ha expirado y uno de los items ya no se encuentra disponible.",
        "shop.validation.invalidrequest": "No se ha podido procesar su carrito porque su sesión ha expirado y su petición es inválida.",
        "shop.validation.maxquantityperpurchase": "No se ha prodido procesar su carrito porque uno de los items ya no se encuentra disponible y se ha excedido la cantidad posible de compra.",
        "shop.validation.genericservererror": "No se ha podido procesar su carrito debido a un error de comunicación con el servidor. Por favor, vuelva a intentarlo más tarde. Muchas gracias.",
        "shop.validation.requiredaccount": "Para continuar con la compra es necesario que crees tu cuenta de usuario o accedas en caso de que ya tengas una. ¡Es muy rápido y fácil!",
        "shop.validation.requiredemailverified": "Para continuar con la compra es necesario que accedas a tu correo electrónico y confirmes tu cuenta con el enlace que te hemos enviado.",
        "shop.validation.close": "Cerrar",
        "shop.checkout.success.title": "Gracias por su compra",
        "shop.checkout.success.message.title": "¡Tu compra ha sido procesada exitosamente!",
        "shop.checkout.success.message.paragraph": "Te hemos enviado un email con toda la información acerca de tu compra. Revisa tu correo y recuerda que si has hecho la compra accediendo con una cuenta de usuario, puedes acceder desde \"Mi cuenta\" para más información.",
        "shop.checkout.success.message.paragraph2": "Muchas gracias",
        "shop.item.code": "Código",
        "shop.item.unitprice": "Precio unitario",
        "shop.item.totalprice": "Precio total",
        "shop.checkout.finalstep": "Pasar por caja",
        "shop.checkout.backcart": "Volver al carrito",
        "shop.checkout.customerformtitle": "Datos de cliente",
        "shop.checkout.customerformtext": "Complete el siguiente formulario con sus datos",
        "shop.checkout.fullname": "Nombre Completo",
        "shop.checkout.email": "Email",
        "shop.checkout.mobile": "Teléfono Móvil",
        "shop.checkout.address": "Dirección",
        "shop.checkout.comments": "Comentarios",
        "shop.checkout.accepttermsconditions": `He leído y acepto la <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Política de privacidad</a>${ company.footerTermsConditions ? `<span> y los <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span>` : "" }`,
        "shop.checkout.reviewpurchase": "Revisa el detalle de la compra",
        "shop.checkout.reviewpurchase.subtotal": "Subtotal",
        "shop.checkout.included": "incluído",
        "shop.checkout.reviewpurchase.total": "Total",
        "shop.checkout.paymentmethod": "Forma de pago",
        "shop.checkout.banktransfer": "Transferencia Bancaria",
        "shop.checkout.banktransfertext": "Al realizar una compra por transferencia bancaria, recibirás un email con tu código de compra con el que podrás acceder a consultar el estado y los datos para realizar la transferencia bancaria",
        "shop.checkout.totalfinal": "Total",
        "shop.checkout.processpurchasebutton": "Procesar Compra",
        "shop.checkout.processpaymentlink": "Pagar ahora",
        "shop.checkout.paybycard": "Pagar con tarjeta",
        "shop.purchase.codenotfound": "El código de compra ingresado y el email no son correctos.",
        "shop.purchase.details.title": "Detalle de compra",
        "shop.purchase.details.subtitle": "Completa la siguiente información para consultar los detalles de tu compra",
        "shop.purchase.details.button": "Consultar detalle",
        "shop.purchase.details.field.code.label": "Ingresa el código de tu compra",
        "shop.purchase.details.field.email.label": "Email con el que has hecho tu compra",
        "shop.purchase.details.customertitle": "Datos de cliente",
        "shop.purchase.details.itemstitle": "Detalle de la compra",
        "shop.purchase.details.statustitle": "Estado de su compra",
        "shop.purchase.details.paymentmethod": "Forma de pago",
        "shop.purchase.details.status.pagantis.started.title": "El usuario no ha finalizado el pago a través de Pagantis",
        "shop.purchase.details.status.pagantis.started.text": "En este intento, el usuario ha iniciado el carrito, pero no ha finalizado con el proceso de pago en Pagantis",
        "shop.purchase.details.status.pagantis.started.footertext": "En caso de no finalizar el pago a través de Pagantis en el plazo de 48 hs anularemos este carrito",
        "shop.purchase.details.status.banktransfer.pending.title": "Pago por transferencia pendiente de confirmar",
        "shop.purchase.details.status.banktransfer.pending.text": "Para completar su compra debe realizar la siguiente transferencia bancaria en un plazo de 24hs:",
        "shop.purchase.details.status.banktransfer.pending.concept": "Ingrese su Email como concepto de transferencia",
        "shop.purchase.details.status.confirmed.title": "Su compra ha sido confirmada por nuestro equipo",
        "shop.purchase.details.status.confirmed.text": `Hemos verificado el pago y su compra se encuentra confirmada. Muchas gracias por confiar en ${ company.name }`,
        "shop.purchase.details.status.banktransfer.pending.footertext": "En caso de haber realizado la transferencia, puedes enviarnos el comprobante para agilizar el proceso de verificación.",
        "shop.purchase.status.started": "Pendiente",
        "shop.purchase.status.confirmed": "Confirmada",
        "shop.purchase.status.canceled": "Cancelada",
        "shop.purchase.paymentmethod.banktransfer": "Transferencia Bancaria",
        "shop.purchase.paymentmethod.pagantis": "Pagantis - Financiación",
        "shop.purchase.paymentmethod.stripe": "Pago con tarjeta vía Stripe",
        "shop.purchase.details.status.canceled.title": "Su compra ha sido cancelada",
        "shop.purchase.details.status.canceled.text": "Nuestro equipo ha marcado esta compra como cancelada. Los motivos pueden ser varios, como la falta del pago correspondiente o debido a un error por parte del usuario.",
        "shop.purchase.confirmed.title": `Su compra ha sido confirmada por nuestro equipo de ${ company.name }`,
        "shop.purchase.confirmed.subtitle": "Por favor, conserve el comprobante de compra adjunto.",
        "shop.purchase.confirmed.details.button.p": "Recuerde que también puede consultar todos los detalles de su compra accediendo a su cuenta:",
        "shop.purchase.confirmed.codetext": "El código de referencia de su compra es",
        "shop.purchase.button.view.details": "Ver detalle",
        "shop.purchase.thanks.paylater.title": "Muchas gracias por su reserva",
        "shop.purchase.email.started.paylater.subject": `Muchas gracias por su reserva en ${ company.name }`,
        "shop.purchase.details.button.p": "Puede acceder al detalle de su compra haciendo clic en el siguiente enlace:",
        "shop.purchase.thanks.title": "Muchas gracias por su compra",
        "shop.purchase.thanks.subtitle": "Por favor, conserve la siguiente información",
        "shop.purchase.email.confirmed.subject": `Comprobante de compra de ${ company.name }`,
        "shop.purchase.email.started.subject": `Muchas gracias por su compra en ${ company.name }`,
        "shop.purchase.order.pdf.title": `Comprobante de compra de ${ company.name }`,
        "shop.checkout.pagantis": "Pago en Cuotas",
        "shop.checkout.pagantistitle": "Simulador de cuota",
        "shop.checkout.pagantistext": "Puedes usar el siguiente simulador seleccionando el valor de cuota que desea pagar:",
        "shop.checkout.stripe": "Pago con tarjeta vía Stripe",
        "shop.checkout.stripetitle": "Pago con tarjeta",
        "shop.checkout.stripetext": "Pago con tarjeta de forma segura a través de Stripe",
        "shop.shorttext.viewmore": "...ver más",
        "shop.item.mycart.view": "Ver mi carrito",
        "shop.item.mycart.already": "En mi carrito",
        "shop.item.mycart.add": "Agregar a mi carrito",
        "shop.items.loadmore": "Cargar más productos",
        "shop.continue.buying": "Seguir comprando",
        "shop.navbar.top.mycart": "Mi carrito",
        "shop.search.noresults": "No se han encontrado resultados para",
        "shop.cart.unitprice": "Precio Unitario",
        "shop.cart.productcode": "Código",
        "shop.cart.totalprice": "Precio Total",
        "shop.cart.removefromcart": "Quitar del carrito",
        "shop.cart.contenttitle": "Contenido del carrito",
        "shop.cart.emptybutton": "Vaciar carrito",
        "shop.cart.yourcartisempty": "Su carrito se encuentra vacío",
        "shop.cart.subtotal": "Subtotal",
        "shop.cart.shippingprice": "Gastos de envío",
        "shop.cart.missingforfreeshipping": "Faltan {freeShippingMissing} para envío gratuito",
        "shop.cart.total": "Total",
        "shop.cart.quantity": "Cantidad",
        "shop.cart.markasgift": "Marcar como regalo",
        "shop.cart.giftcardservices.message": "Los items que marques como regalo serán incluídos en una tarjeta que podrás compartir. Sólo puedes crear una tarjeta regalo con tratamientos por compra.",
        "shop.checkout.useraccount": "Cuenta de usuario",
        "shop.checkout.alreadyloggedin": "Ya te encuentras registrado y has accedido con tu cuenta",
        "shop.checkout.pleaseverifyaccount": "Tu cuenta de correo no ha sido verificada mediante el enlace que te hemos enviado.<br />Por favor, <b>accede a tu correo y completa la verificación</b>.</p><p><b>¿Ya has verificado tu correo?</b>",
        "shop.checkout.clickhere": "Clic aquí",
        "shop.checkout.alreadyaccountquestion": "¿Tienes cuenta de usuario?",
        "shop.checkout.gettingdata": "Obteniendo datos",
        "shop.checkout.accessyouraccountsuggestion": "Accede si ya tienes una cuenta o crea una nueva para mantener el registro de tus compras",
        "shop.purchase.paymentmethod.stripe.description": "Paga de forma segura mediante Stripe, la plataforma de pagos de confianza de millones de empresas y usuarios",
        "shop.checkout.city": "Ciudad",
        "shop.checkout.state": "Provincia",
        "shop.checkout.country": "País (Sólo España)",
        "shop.checkout.onlyspain": "Sólo España",
        "shop.validation.invalidpostalcode": "El código postal no es válido",
        "shop.checkout.postalcode": "Código Postal",
        "shop.checkout.spain": "España",
        "shop.item.giftcardservices.title": "Tarjeta Regalo con Servicios",
        "shop.item.giftcardservices.description": "¿Quieres regalar una tarjeta con servicios incluídos? ¡Es muy fácil! Simplemente añade al carrito los servicios que quieras regalar y luego, en tu carrito, márcalos como regalo y te enviaremos una tarjeta que podrás compartir.",
        "shop.item.giftcardamount.title": "Tarjeta Regalo con Saldo",
        "shop.item.giftcardamount.description": "¿Quieres regalar una tarjeta con saldo? ¡Es muy fácil! Añade este ítem a tu carrito y desde allí podrás modificar el saldo para que luego te enviemos una tarjeta que podrás compartir.",
        "shop.checkout.paylater.lasttext": "A pagar el total el día de tu cita.",
        "shop.checkout.paybysavedcard": "Pagar con tarjeta guardada",
        "shop.checkout.paybyanothercard": "Pagar con otra tarjeta",
        // locations
        "locations.search.noresults": "No se han encontrado resultados para",
        "dashboard.locations.title": "Tus lugares en el mapa",
        "dashboard.sales.text.noresults": "No hay ventas recientes para mostrar.",
        "dashboard.locations.text.nolocations": "Por el momento no tienes lugares vinculados a tu cuenta. En caso de que aún no nos hayas enviado los datos de tu comercio, servicio o lugar, nos pondremos en contacto contigo vía email a la brevedad. Muchas gracias",
        "dashboard.locations.text": "Tienes vinculados los siguientes lugares del mapa:",
        // dashboard
        "dashboard.bienvenida.title": "Bienvenid@ a tu Dashboard",
        "dashboard.bienvenida.text": "Desde tu Dashboard podrás acceder de forma directa a toda la información de tus pedidos, servicios contratados como también a nuestras novedades exclusivas para clientes.",
        "dashboard.questions.title": "¿Preguntas?",
        "dashboard.questions.text": "No dudes en enviarnos un y te responderemos a la brevedad.",
        "dashboard.myplan.title": "Mi Plan Actual",
        "dashboard.myplan.text": "",
        "dashboard.myplan.text.noproducts": "Actualmente no tienes contratado ningún plan.",
        "dashboard.myplan.bajalink": "Solicitar baja",
        "generic.server.error.changedonserver": "Se han realizado cambios en el item. Por favor, refresque el navegador y vuelva a intentarlo."
    },
    en: {
        "navbar.title": "",
        "footer.contact.text1": "Any further questions?",
        "footer.contact.text2": "Please do not hesitate to contact us, tell us about your business or project and We will reply as soon as possible.",
        "footer.contact.fullname": "Full name",
        "footer.contact.fullname.description": "Please enter your full name",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "Enter your email to reply as soon as possible.",
        "footer.contact.phone": "Phone",
        "footer.contact.phone.description": "Enter your phone and we can contact you via Whatsapp or a call.",
        "footer.contact.message": "Message",
        "footer.contact.privacypolicy": `I have read and accept the <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Privacy Policy</a>${ company.footerTermsConditions ? `<span> and the <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>` : "" }`,
        "footer.contact.sendmessage": "Send message",
        "footer.contact.orremember": "Or remember you can also send us a message via",
        "footer.contact.emailbutton": "Email",
        "footer.contact.whatsapp": "Send WhatsApp",
        "footer.contact.visitfacebook": "Visit us on Facebook",
        "footer.contact.successmessage.text1": "Thank you to contact us",
        "footer.contact.successmessage.text2": "Your message has been successfully sent and we´ll reply you as soon as possible.",
        "footer.contact.successmessage.text3": "At {companyName} we work hard to reply our customers messages in a professional way. Thank you for your patience. ",
        "footer.copyright.text1": "Copyright Live Workflows - Sitios Web y Marketing",
        // news
        "news.categories": "Categories",
        "news.viewmore": "View more",
        // customs
        "home.cover.title": "Websites & Marketing",
        "home.cover.subtitle": "for small business",
        "home.cover.paragraph1": "Working really hard?",
        "home.cover.paragraph2": "Let our experts work on your website, SEO and brand, to grow your bussiness while you continue working on other things.",
        "home.plan.title": "Plan for small business",
        "home.cover.button": "Let´s start with my website",
        "home.plan.paragraph": "We know you need us to solve everything for a very good price ... that's why we have a plan!",
        "home.plan.premium.text1": "Our perfect plan designed for small business that include ",
        "home.plan.premium.text2": "a lot of benefits",
        "home.plan.premium.text3": "We create or redesign ",
        "home.plan.premium.text4": "your website optimized for mobile desktop devices",
        "home.plan.premium.text5": "in our platform",
        "home.plan.premium.text6": "Search Engine Optimization",
        "home.plan.premium.text7": "Integrations with  ",
        "home.plan.premium.text8": "Social Networks",
        "home.plan.premium.text9": "Upgrades and ",
        "home.plan.premium.text10": "improvements in a proactive way",
        "home.plan.premium.text11": "Hosting, consulting and maintenance",
        "home.plan.premium.text12": "by experts",
        "home.plan.premium.text13": "Texts, images and video updates",
        "home.plan.premium.text14": "included",
        "home.plan.premium.text15": "Domain included during the first year",
        "home.plan.premium.text16": "The best price for your website, seo, and your online branding managed by experts to grow your business",
        "home.plan.premium.text17": "423.50 EUR/year",
        "home.plan.premium.text18": "* including taxes",
        "home.plan.premium.text19": "Migration support from/to Wordpress included",
        "home.plan.premium.button": "Click Here to Start!",
        "home.customers.text1": "Success Stories",
        "home.customers.text2": "We are the small business's choice because improve how their brands look in internet, enlarging their client portfolio, and being able to share their news at their website with quality and really nice content.",
        "home.customers.text3": "Success story",
        "home.customers.text4": "By searching <b><i>&quot;Peluquería canina en Mallorca&quot;</i></b>, <b>Pichichus</b> is one of the first results at Google, without paying for that. Then, when a user visits <a href=\"https://www.pichichus.com\" target=\"_blank\" rel=\"noopener noreferrer\" >www.pichichus.com</a>, they see a very nice and attractive website to call them by just clicking the main action button.",
        "home.customers.button": "Let´s start with my website",
        // signup & login
        "signup.form.title": "Complete the data to signup",
        "signup.form.subtitle": "",
        "signup.form.facebook.login.error": "Hubo un error en la autenticación a través de Facebook. Por favor, vuelve a intentarlo o completa el formulario de registro.",
        "signup.form.readandacceptpolicy": "I've read and accept",
        "signup.form.privacypolicy": "Privacy Policy",
        "signup.form.signupbutton": "Create account",
        "signup.form.backloginbutton": "Access my account",
        "signup.form.separator.text": "¿Already have an account?",
        "signup.validation.emailalreadyexists": "The email is already in use. Forgot your password?",
        "signup.validation.passwordstrength": "The password is too short and must contain letters and numbers.",
        "signup.password.hinttext": "Min 6 chars, using letters and numbers",
        "signup.form.facebookbutton": "Continue with Facebook",
        "signup.validation.facebook.sessionexpired": "Se ha producido un error al conectarse con Facebook, por favor, vuelva a intentarlo.",
        "signup.success.title": "Please verify your account from your inbox",
        "signup.success.text": "We've sent you an email to {email} in order to verify your account. Please open your inbox and continue the verification process before continue.",
        "signup.login.email.notverified": "Please verify your account. Access your inbox and continue the verification process.",
        "signup.login.invalid.credentials": "Invalid email and password",
        "login.form.title": "Access your account",
        "login.form.subtitle": "",
        "login.form.loginbutton": "Access my account",
        "login.form.separator.text": "¿Need an account?",
        "login.form.signupbutton": "Create an account",
        "checkout.form.selectplan": "¿Plan Anual o Mensual?",
        "checkout.form.selectplan.text": "Selecciona si prefieres pagar mensual o anualmente (Ahorro anual de 50 EUR)",
        "checkout.form.selectplan.selector": "Seleccione Plan Mensual o Anual...",
        "checkout.validation.useralreadysubscribed": "Ya te encuentras dado de alta en este Plan.",
        "checkout.form.title": "",
        "generic.validation.close": "close",
        "generic.validation.empty": "Please complete all the data to continue.",
        "generic.validation.recaptcha": "Check the \"Anti-bot\" challenge to continue.",
        "generic.validation.privacypolicy": "You must accept Privacy Policy before continue.",
        "generic.validation.invalidemail": "Invalid email",
        "generic.validation.passwordmismatch": "Password confirmation doesn't match.",
        "generic.server.error": "Connection error while sending the data to the server.",
        "signup.emailverified.success": "¡Your acount is ready!",
        "signup.navbar.login": "Access",
        "signup.navbar.signup": "Create account",
        "signup.navbar.dashboard": "My account",
        "signup.navbar.logout": "Close",
        "checkout.currentproduct.content.title": "Datos de mi plan actual",
        "checkout.currentproduct.content.subtitle": "¡Enhorabuena! Actualmente te encuentras suscrito a nuestro plan",
        "checkout.currentproduct.yourplan": "Tu plan actual",
        "checkout.currentproduct.yourplan.text": "Actualmente te encuentras suscrito al siguiente plan:",
        "signup.form.signuppassword.title": "Enter password",
        "footer.floatingvideo.sendmessage": "Send message",
        "signup.form.passwordconfirmation": "Confirm Password",
        "signup.form.password": "Password",
        "signup.resetpassword.title": "Reset your password",
        "signup.resetpassword.subtitle": "Enter your new password",
        "signup.form.newpasswordconfirmation": "Confirm your new password",
        "signup.form.newpassword": "New password",
        "signup.form.newpassword.save": "Save changes",
        "signup.forgotpassword.title": "Forgot your password?",
        "signup.forgotpassword.subtitle": "Enter your email account to reset your password",
        "signup.forgotpassword.emailnotexists": "Invalid email.",
        "signup.forgotpassword.pleasecheckemail": "Please check your email. We've sent you a link to reset your password.",
        "signup.forgotpassword.processbutton": "Reset Password",
        "signup.login.newpasswordsaved": "Your new password has been saved. Enter your email to access your account.",
        "signup.form.forgotpasswordlink": "Forgot your password?",
        "signup.emailverified.textclicktocontinue": "Click the following link to continue",
        "signup.emailverified.continue": "Continue",
        // shop
        "shop.validation.empty": "Please, complete all fields to continue",
        "shop.validation.recaptcha": "Check the \"Anti-bot\" field to continue",
        "shop.validation.privacypolicyandterms": "Please accept Privacy Policy and Terms and Conditions to continue.",
        "shop.validation.invalidemail": "Please enter a valid email",
        "shop.validation.emptycart": "Your cart is empty or your session has expired.",
        "shop.validation.notexistingcartitem": "One of your cart items is not available. Please empty your cart and try again.",
        "shop.validation.modifiedpricecartitem": "We couldn't process your cart because the price of an item has changed. Please empty your cart and try again.",
        "shop.validation.expiredcartitem": "Your session has expired. Please empty your cart and try again.",
        "shop.validation.unavailableitem": "One of the items is not available. Please, empty your cart and try again.",
        "shop.validation.invalidrequest": "Your session has expired. Please empty your cart and try again.",
        "shop.validation.maxquantityperpurchase": "We couldn't process your cart because one of the items is not available due to stock limits. Please empty your cart and try again later.",
        "shop.validation.genericservererror": "It looks like we couldn't connect to the server. Maybe internet connection issues?",
        "shop.validation.requiredaccount": "Please signup and create an account to continue",
        "shop.validation.requiredemailverified": "Please verify your account. We've sent you an email to continue the verification process.",
        "shop.validation.close": "Close",
        "shop.checkout.success.title": "Thanks for your purchase",
        "shop.checkout.success.message.title": "¡Your purchase has been successfully processed!",
        "shop.checkout.success.message.paragraph": "We've sent you an email with your purchase information. Please, check your inbox and remember if you were logged in you can get more info from \"My account\".",
        "shop.checkout.success.message.paragraph2": "Many thanks",
        "shop.item.code": "Code",
        "shop.item.unitprice": "Unit Price",
        "shop.item.totalprice": "Total Price",
        "shop.checkout.finalstep": "Checkout",
        "shop.checkout.backcart": "Back to your cart",
        "shop.checkout.customerformtitle": "Customer Info",
        "shop.checkout.customerformtext": "Please complete with your data",
        "shop.checkout.fullname": "Fullname",
        "shop.checkout.email": "Email",
        "shop.checkout.mobile": "Mobile",
        "shop.checkout.address": "Address",
        "shop.checkout.comments": "Comments",
        "shop.checkout.accepttermsconditions": `I´ve read and accept <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Privacy Policy</a>${ company.footerTermsConditions ? `<span> and <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>` : "" }`,
        "shop.checkout.reviewpurchase": "Review your purchase info",
        "shop.checkout.reviewpurchase.subtotal": "Subtotal",
        "shop.checkout.included": "included",
        "shop.checkout.reviewpurchase.total": "Total",
        "shop.checkout.paymentmethod": "Payment Method",
        "shop.checkout.banktransfer": "Wire Transfer",
        "shop.checkout.banktransfertext": "When purchasing by Wire Transfer, you will get an email with your purchase code and the data to proceed with the wire transfer.",
        "shop.checkout.totalfinal": "Total",
        "shop.checkout.processpurchasebutton": "Process Purchase",
        "shop.purchase.codenotfound": "Invalid purchase code and email.",
        "shop.purchase.details.title": "Purchase details",
        "shop.purchase.details.subtitle": "Complete the following information to get your purchase details",
        "shop.purchase.details.button": "Get info",
        "shop.purchase.details.field.code.label": "Enter your purchase code",
        "shop.purchase.details.field.email.label": "Your email",
        "shop.purchase.details.customertitle": "Customer Info",
        "shop.purchase.details.itemstitle": "Purchase details",
        "shop.purchase.details.statustitle": "Purchase status",
        "shop.purchase.details.paymentmethod": "Payment Method",
        "shop.purchase.details.status.pagantis.started.title": "Pending Pagantis payment",
        "shop.purchase.details.status.pagantis.started.text": "En este intento, el usuario ha iniciado el carrito, pero no ha finalizado con el proceso de pago en Pagantis",
        "shop.purchase.details.status.pagantis.started.footertext": "En caso de no finalizar el pago a través de Pagantis en el plazo de 48 hs anularemos este carrito",
        "shop.purchase.details.status.banktransfer.pending.title": "The wire transfer payment need to be confirmed by our team",
        "shop.purchase.details.status.banktransfer.pending.text": "To complete your purchase you need to do the following wire transfer before 24hs:",
        "shop.purchase.details.status.banktransfer.pending.concept": "Enter your email at the moment of the wire transfer",
        "shop.purchase.details.status.confirmed.title": "Your purchase has been confimed by our team",
        "shop.purchase.details.status.confirmed.text": `We´ve verified your payment and your purchase is confirmed. Many thanks. ${ company.name }`,
        "shop.purchase.details.status.banktransfer.pending.footertext": "If you already did the required wire transfer, please send us the the details to be able to confirm your purchase as soon as posible.",
        "shop.purchase.status.started": "Pending",
        "shop.purchase.status.confirmed": "Confirmed",
        "shop.purchase.status.canceled": "Canceled",
        "shop.purchase.paymentmethod.banktransfer": "Wire Transfer",
        "shop.purchase.paymentmethod.pagantis": "Pagantis",
        "shop.purchase.paymentmethod.stripe": "Pay by card via Stripe",
        "shop.purchase.details.status.canceled.title": "Your purchase has been canceled",
        "shop.purchase.details.status.canceled.text": "Your purchase has been canceled by our team.",
        "shop.purchase.confirmed.title": `Your purchas has been confirmed by our team. ${ company.name }`,
        "shop.purchase.confirmed.subtitle": "Please, keep the attached purchase document.",
        "shop.purchase.confirmed.details.button.p": "Remember you can access to your purchase information from \"My account\":",
        "shop.purchase.confirmed.codetext": "Your purchase code is",
        "shop.purchase.button.view.details": "Access my account",
        "shop.purchase.details.button.p": "You can get your purchase details by clicking the link:",
        "shop.purchase.thanks.title": "Thank you for your purchase",
        "shop.purchase.thanks.subtitle": "Please, keep the following information",
        "shop.purchase.email.confirmed.subject": `Purchase details from ${ company.name }`,
        "shop.purchase.email.started.subject": `Thanks for your purchase. ${ company.name }`,
        "shop.purchase.order.pdf.title": `Purchase details from ${ company.name }`,
        "shop.checkout.pagantis": "Pago en Cuotas",
        "shop.checkout.pagantistitle": "Simulador de cuota",
        "shop.checkout.pagantistext": "Puedes usar el siguiente simulador seleccionando el valor de cuota que desea pagar:",
        "shop.checkout.stripe": "Pay by card via Stripe",
        "shop.checkout.stripetitle": "Card Payment",
        "shop.checkout.stripetext": "Card payment via Stripe",
        "shop.shorttext.viewmore": "...view more",
        "shop.item.mycart.view": "My cart",
        "shop.item.mycart.already": "Already in cart",
        "shop.item.mycart.add": "Add to cart",
        "shop.items.loadmore": "Load more products",
        "shop.continue.buying": "Continue shopping",
        "shop.navbar.top.mycart": "My cart",
        "shop.search.noresults": "No results found for",
        "shop.cart.unitprice": "Unit Price",
        "shop.cart.productcode": "Code",
        "shop.cart.totalprice": "Total Price",
        "shop.cart.removefromcart": "Remove from cart",
        "shop.cart.contenttitle": "Cart Items",
        "shop.cart.emptybutton": "Empty your Cart",
        "shop.cart.yourcartisempty": "Your Cart is empty",
        "shop.cart.subtotal": "Subtotal",
        "shop.cart.shippingprice": "Shipping Price",
        "shop.cart.missingforfreeshipping": "{freeShippingMissing} left for free shipping",
        "shop.cart.total": "Total",
        "shop.cart.quantity": "Quantity",
        "shop.checkout.useraccount": "Your account",
        "shop.checkout.alreadyloggedin": "You are already logged in and registered",
        "shop.checkout.pleaseverifyaccount": "Your account is pending to be verified from your email account.<br />Please, <b>check your email and complete the verification process before continue</b>.</p><p><b>¿Already verified?</b>",
        "shop.checkout.clickhere": "Clic here",
        "shop.checkout.alreadyaccountquestion": "Already have an account?",
        "shop.checkout.gettingdata": "Getting data",
        "shop.checkout.accessyouraccountsuggestion": "Please access your account or signup in order to keep your purchase data history",
        "shop.purchase.paymentmethod.stripe.description": "Pay by card viar Stripe, the international powerfull platform for payments transactions",
        "shop.checkout.city": "City",
        "shop.checkout.state": "State",
        "shop.checkout.postalcode": "Postal Code",
        "shop.checkout.spain": "Spain",
        "shop.checkout.country": "Country (Only Spain)",
        "shop.checkout.onlyspain": "Only Spain",
        "shop.validation.invalidpostalcode": "Invalid postal code"
    }
};

export const defaultLanguage = "es";
export const availableLanguages = [ "es", "en" ];

// GENERIC
export const getPageByPath = ( path ) => {
    let result = all.find( ( page ) => {
        if ( page.path === path ) {
            return page;
        }
        if ( matchPath( path, page ) ) {
            return page;
        }
        return null;
    } );

    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

export const manager = {};
manager.getRelativeUrl = ( relative ) => `${ company.managerURLRelative }/${ relative }`;

export const text = ( lang, id, replacements ) => {
    let result = "";
    if ( texts[ lang ] && texts[ lang ][ id ] ) {
        result = texts[ lang ][ id ];
    } else if ( texts.es && texts.es[ id ] ) {
        result = texts.es[ id ];
    }
    if ( typeof replacements !== "undefined" ) {
        Object.keys( replacements ).forEach( key => {
            result = result.replace( new RegExp( `{${ key }}`, "g" ), replacements[ key ] );
        } );
    }
    return result;
};

export const getLanguageName = ( lang ) => {
    switch ( lang ) {
        case "en":
            return "English";
        case "es":
            return "Español";
        default:
            return "Español";
    }
};

export const getLangByUrlPath = ( path ) => {
    let result = defaultLanguage;
    availableLanguages.forEach( ( lang ) => {
        if ( path.indexOf( `/${ lang }/` ) > -1 ) {
            result = lang;
        }
    } );
    return result;
};

export const applyShopOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const applyNewsOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const contactForms = null;
// Example:
// export const floatingVideos = [
//     {
//         relativeUrl: "/",
//         exactUrl: true,
//         src: "https://www.youtube.com/embed/5MTYDYkGxP4",
//         provider: "youtube",
//         sendMessage: true,
//         displayScrollY: 0,
//         disableAutoplay: true
//     }
// ];
export const floatingVideos = null;
export const emailTemplates = {
    theme: {
        logoBackgroundColor: "#0e0826",
        logoUrl: `${ company.baseURL }/static/logo-rectangle.png`,
        thanksBackgroundColor: "#F8F8F8",
        buttonBackgroundColor: "#fbc965",
        thanksTitleColor: "#333333",
        buttonTextColor: "black",
        purchaseDetailsUrl: `${ company.baseURL }/shop/purchase`,
        successBackgroundColor: "#333333",
        successTextColor: "#333333",
        defaultFont: "Arial",
        defaultFontColor: "#333333",
        purchaseThanksTitle: "Muchas gracias por su compra",
        purchaseThanksSubTitle: "Por favor, conserve la siguiente información.",
        footerText: `Para cualquier información no dude en contactarnos a través de nuestra web ${ company.baseURL }`
    }
};

export const floatingActionButtons = null;
export const redsys = {
    bizum: false,
    redsysSaveCard: false
};
