import React from "react";
import { Button, Spinner, Card, Row, Col, ButtonToolbar, Ratio, ButtonGroup, Image } from "react-bootstrap";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { IoIosArrowBack, IoIosCheckboxOutline } from "react-icons/io";
import { FaFileDownload, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import NavBarAccount from "./layout/NavBarAccount";
import * as SessionActions from "../actions/SessionActions";
import * as CheckoutActions from "../actions/CheckoutActions";
import * as DataUtils from "../utils/DataUtils";
import * as Pages from "../utils/Pages";
import WebContext from "../utils/WebContext";
import { loadUIContext } from "../actions/UIContextActions";

class GenericMyCourses extends React.Component {
    constructor ( props ) {
        super( props );
        this.renderCoursesList = this.renderCoursesList.bind( this );
        this.renderCourseContent = this.renderCourseContent.bind( this );
        this.page = Pages.getPageByPath( props.match.path );
        this.isLoading = true;
        this.urlParams = {};
        const tmpParams = new URLSearchParams( props.location.search );
        const tmpParamsKeys = Array.from( tmpParams.keys() );
        for ( let i = 0; i < tmpParamsKeys.length; i += 1 ) {
            this.urlParams[ tmpParamsKeys[ i ] ] = tmpParams.get( tmpParamsKeys[ i ] );
        }
        let currentView = "courses";
        if ( this.urlParams.courseId ) {
            currentView = "courseContent";
        }
        this.state = {
            view: currentView, selectedCourseId: this.urlParams.courseId ? this.urlParams.courseId : null, selectedCourseClassId: this.urlParams.courseClassId ? this.urlParams.courseClassId : null, selectedCourseClassStepId: null, toggleSideBar: false
        };
        DataUtils.checkToken( props.history );
    }
    renderCourseContent() {
        const course = this.props.pwSession.courses.find( tmpCourse => tmpCourse.courseId === this.state.selectedCourseId );
        if ( this.state.view !== "courseContent" || !course ) {
            return null;
        }
        const courseClass = course.courseClasses.find( tmpCourseClass => tmpCourseClass.courseClassId === this.state.selectedCourseClassId );
        const courseClassWebUser = courseClass.courseClassWebUsers[ 0 ];
        return (
            <div>
                <div className="pw_course_content_top">
                    <div className="d-flex align-items-start">
                        <img
                            width={ 64 }
                            height={ 64 }
                            className="mr-3"
                            src={ `/content/public/${ course.courseImageCard }` }
                            alt="Generic content"
                        />
                        <div>
                            <h4>{ course.courseTitle }</h4>
                            <ButtonGroup>
                                <Button size="sm" variant="outline-secondary" onClick={ () => this.setState( { view: "courses" } ) }><IoIosArrowBack /> Volver a cursos</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
                <div className={ `d-flex pw_sidebar_container_wraper${ this.state.toggleSideBar ? " toggled" : null }` }>
                    <div className="pw_sidebar_wrapper">
                        <div className="list-group list-group-flush">
                            <button type="button" className="list-group-item list-group-item-action" onClick={ () => this.setState( { toggleSideBar: !this.state.toggleSideBar } ) }><FaAngleDoubleLeft /></button>
                            { course.courseSteps.map( ( courseStep, courseStepIndex ) => {
                                const userCourseClassStep = courseClassWebUser.courseClassUserSteps.find( tmpUserStep => tmpUserStep.courseClassUserStepStepId === courseStep.courseStepId );
                                let courseStepIsDone = false;
                                if ( userCourseClassStep && userCourseClassStep.courseClassUserStepStatus === "done" ) {
                                    courseStepIsDone = true;
                                }
                                let selectedStep = false;
                                if ( courseStepIndex === 0 && !this.state.selectedCourseClassStepId ) {
                                    selectedStep = true;
                                } else if ( this.state.selectedCourseClassStepId === courseStep.courseStepId ) {
                                    selectedStep = true;
                                }
                                return ( <button type="button" onClick={ () => this.setState( { selectedCourseClassStepId: courseStep.courseStepId } ) } className={ `list-group-item list-group-item-action${ selectedStep ? " selected" : "" } ${ courseStepIsDone ? " success" : "" }` }>{ courseStepIndex + 1 }. { courseStep.courseStepTitle }</button> );
                            } ) }
                        </div>
                    </div>
                    <div className="pw_sidebar_page_content_wrapper">
                        <Button variant="pw-primary" size="sm" className="pw_sidebar_toggle" onClick={ () => this.setState( { toggleSideBar: !this.state.toggleSideBar } ) } ><FaAngleDoubleRight /> </Button>
                        <div className="pw_course_content_container">
                            { course.courseSteps
                                .filter( ( tmpCourseStep, tmpCourseStepIndex ) => tmpCourseStep.courseStepId === this.state.selectedCourseClassStepId || ( !this.state.selectedCourseClassStepId && tmpCourseStepIndex === 0 ) )
                                .map( courseStep => {
                                    const userCourseClassStep = courseClassWebUser.courseClassUserSteps.find( ( tmpUserStep ) => tmpUserStep.courseClassUserStepStepId === courseStep.courseStepId );
                                    let courseStepIsDone = false;
                                    if ( userCourseClassStep && userCourseClassStep.courseClassUserStepStatus === "done" ) {
                                        courseStepIsDone = true;
                                    }
                                    return (
                                        <Card className={ courseStepIsDone ? "success" : null }>
                                            <Card.Header>{ courseStep.courseStepTitle }</Card.Header>
                                            <Card.Body>
                                                <p>{ courseStep.courseStepShortText }</p>
                                                { course.courseStepsContents
                                                    .filter( tmpContent => tmpContent.courseStepContentStepId === courseStep.courseStepId )
                                                    .map( tmpContent => {
                                                        if ( tmpContent.courseStepContentType === "video" ) {
                                                            return (
                                                                <div className="pw_course_content_video_container">
                                                                    <Card.Subtitle>{ tmpContent.courseStepContentTitle }</Card.Subtitle>
                                                                    <p>{ tmpContent.courseStepContentShortText }</p>
                                                                    <Ratio aspectRatio="16x9">
                                                                        <ReactPlayer
                                                                            url={ tmpContent.courseStepContentUrl }
                                                                            width="100%"
                                                                            height="100%"
                                                                            controls
                                                                        />
                                                                    </Ratio>
                                                                </div> );
                                                        }
                                                        if ( tmpContent.courseStepContentType === "document" ) {
                                                            return (
                                                                <div className="pw_course_content_document_container">
                                                                    <Card.Subtitle>{ tmpContent.courseStepContentTitle }</Card.Subtitle>
                                                                    <p>{ tmpContent.courseStepContentShortText }</p>
                                                                    <p><Button href={ DataUtils.getProtectedDocumentURL( tmpContent.courseStepContentFileName ) } variant="pw-primary"><FaFileDownload /> Ver documento</Button></p>
                                                                </div> );
                                                        }
                                                        return null;
                                                    } ) }
                                            </Card.Body>
                                            { courseStepIsDone ?
                                                <Card.Footer>
                                                    <h5>¡Enhorabuena! Has finalizado <b>{ courseStep.courseStepTitle }</b></h5>
                                                </Card.Footer>
                                                :
                                                <Card.Footer>
                                                    <h5>¿Has finalizado <b>{ courseStep.courseStepTitle }</b>? </h5>
                                                    <ButtonToolbar>
                                                        <ButtonGroup>
                                                            <Button
                                                                variant="success"
                                                                onClick={ () => {
                                                                    this.props.courseClassStepDone( {
                                                                        courseClassUserStepClassId: this.state.selectedCourseClassId,
                                                                        courseClassUserStepStepId: courseStep.courseStepId,
                                                                        courseClassUserStepStatus: "done",
                                                                        courseStep,
                                                                        course,
                                                                        webUser: { webUserFullName: this.props.pwSession.userName, webUserEmail: this.props.pwSession.userEmail, webUserMobile: this.props.pwSession.userMobile }
                                                                    } );
                                                                } }
                                                            ><IoIosCheckboxOutline /> Sí, he finalizado este paso
                                                            </Button>
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                </Card.Footer>
                                            }

                                        </Card>
                                    );
                                } ) }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    renderCoursesList() {
        if ( this.state.view !== "courses" ) {
            return null;
        }
        return (
            <div className="pw_courses_list_container">
                { this.props.pwSession.courses.map( course => (
                    <Card>
                        <Card.Header>{ course.courseTitle }</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs="4" md="2"><Image src={ `/content/public/${ course.courseImageCard }` } fluid /></Col>
                                <Col>
                                    <p><span>{ course.courseShortText }</span></p>
                                    { course.courseClasses.map( courseClass => (
                                        <p><b>{ DataUtils.formatDateNice( courseClass.courseClassDatetimeStart ) }</b> al <b>{ DataUtils.formatDateNice( courseClass.courseClassDatetimeEnd ) }</b></p>
                                    ) ) }
                                    <ButtonToolbar>
                                        <ButtonGroup className="float-right">
                                            <Button
                                                variant="pw-primary"
                                                href={ `/mis-cursos?courseId=${ course.courseId }&courseClassId=${ course.courseClasses[ 0 ].courseClassId }` }
                                            >
                                                Acceder al contenido
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                ) ) }
            </div>
        );
    }
    renderLoading() {
        if ( !this.isLoading ) {
            return null;
        }
        return (
            <div>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }

    render() {
        if ( !this.props.UIContext.loaded || this.props.pwSession.isRehydrating ) {
            return this.renderLoading();
        }
        return (
            <div id="pw_courses" className={ Pages.getPageClassNames( this.page ) }>
                <NavBarAccount />
                { this.renderCoursesList() }
                { this.renderCourseContent() }
            </div>
        );
    }
}

GenericMyCourses.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    checkout: state.checkout
} );

const mapDispatchToProps = Object.assign( {}, SessionActions, CheckoutActions, loadUIContext );
export default connect( mapStateToProps, mapDispatchToProps )( withRouter( GenericMyCourses ) );
