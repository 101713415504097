/* eslint-disable class-methods-use-this */
import React from "react";
import { Button, Form, Card, Alert, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { IoIosArrowForward, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { withRouter } from "react-router-dom";
import GenericDynamicContent from "../components/GenericDynamicContent";
import * as SessionActions from "../actions/SessionActions";
import Styles from "./styles/Global";
import { isValidEmail } from "../utils/DataValidations";
import { base64Decode, getToken } from "../utils/DataUtils";
import * as Pages from "../utils/Pages";
import WebContext from "../utils/WebContext";
import ExpandCollapse from "../components/widgets/ExpandCollapse";
import { loadUIContext } from "../actions/UIContextActions";

const recaptchaRef = React.createRef();

class GenericSignup extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = {
            acceptForgotPrivacyPolicy: false,
            pwd: "",
            confirmPwd: "",
            emailVerifiedRedirectTo: null
        };
        this.renderSignup = this.renderSignup.bind( this );
        this.renderLogin = this.renderLogin.bind( this );
        this.renderTopComponent = this.renderTopComponent.bind( this );
        this.facebookResponse = this.facebookResponse.bind( this );
        this.facebookError = this.facebookError.bind( this );
        this.isValidSignupForm = this.isValidSignupForm.bind( this );
        this.signupSubmit = this.signupSubmit.bind( this );
        this.loginSubmit = this.loginSubmit.bind( this );
        this.forgotSubmit = this.forgotSubmit.bind( this );
        this.renderSignupSuccess = this.renderSignupSuccess.bind( this );
        this.renderSignupPassword = this.renderSignupPassword.bind( this );
        this.renderFacebookButton = this.renderFacebookButton.bind( this );
        this.checkEmailVerified = this.checkEmailVerified.bind( this );
        this.renderForgotPassword = this.renderForgotPassword.bind( this );
        this.renderResetPassword = this.renderResetPassword.bind( this );
        this.page = Pages.getPageByPath( props.match.path );
        this.urlParams = {};
        this.origin = null;
        this.emailVerifiedIntervalId = null;
        const tmpParams = new URLSearchParams( props.location.search );
        const tmpParamsKeys = Array.from( tmpParams.keys() );
        this.fc = null;
        for ( let i = 0; i < tmpParamsKeys.length; i += 1 ) {
            this.urlParams[ tmpParamsKeys[ i ] ] = tmpParams.get( tmpParamsKeys[ i ] );
            if ( tmpParamsKeys[ i ] === "e" ) {
                this.urlParams[ tmpParamsKeys[ i ] ] = base64Decode( this.urlParams[ tmpParamsKeys[ i ] ] );
            }
            if ( tmpParamsKeys[ i ] === "origin" ) {
                this.origin = this.urlParams[ tmpParamsKeys[ i ] ];
            }
            if ( tmpParamsKeys[ i ] === "fc" ) {
                this.fc = this.urlParams[ tmpParamsKeys[ i ] ];
            }
        }
        if ( getToken() && !this.origin ) {
            props.history.push( `${ Pages.company.dashboardURLRelative }` );
        }
    }
    componentDidMount() {
        this.props.loadUIContext();
        if ( this.urlParams.e && isValidEmail( this.urlParams.e ) ) {
            this.props.changeSignupAttr( "view", "login" );
            this.props.changeSessionAttr( "userEmail", this.urlParams.e );
        }
        if ( this.urlParams.view ) {
            this.props.changeSignupAttr( "view", this.urlParams.view );
        }
    }
    facebookResponse ( data, view ) {
        this.props.initializeFacebookSession( data );
        if ( view === "signuppassword" ) {
            this.signupSubmit( "facebook" );
        } else {
            this.loginSubmit( "facebook" );
        }
    }

    facebookError ( error ) {
        this.props.loginError( error, "facebookLoginError" );
    }

    isValidSignupForm () {
        if ( this.props.pwSession.userEmail === "" || this.state.pwd === "" || this.state.confirmPwd === "" ) {
            this.props.changeSignupAttr( "errorMessage", "generic.validation.empty" );
            return false;
        }
        if ( !isValidEmail( this.props.pwSession.userEmail ) ) {
            this.props.changeSignupAttr( "errorMessage", "generic.validation.invalidemail" );
            return false;
        }
        if ( this.state.pwd !== this.state.confirmPwd ) {
            this.props.changeSignupAttr( "errorMessage", "generic.validation.passwordmismatch" );
            return false;
        }
        const pwdRegexp = /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/;
        if ( !this.state.pwd.match( pwdRegexp ) ) {
            this.props.changeSignupAttr( "errorMessage", "signup.validation.passwordstrength" );
            return false;
        }
        if ( this.props.pwSession.reCaptchaTokenNewClient === "" ) {
            // this.props.changeSignupAttr( "errorMessage", "generic.validation.recaptcha" );
            // return false;
        }
        if ( !this.props.pwSession.acceptPrivacyPolicy ) {
            this.props.changeSignupAttr( "errorMessage", "generic.validation.privacypolicy" );
            return false;
        }
        return true;
    }
    isValidResetForm () {
        if ( this.state.pwd === "" || this.state.confirmPwd === "" ) {
            this.props.changeSignupAttr( "errorMessage", "generic.validation.empty" );
            return false;
        }
        if ( this.state.pwd !== this.state.confirmPwd ) {
            this.props.changeSignupAttr( "errorMessage", "generic.validation.passwordmismatch" );
            return false;
        }
        const pwdRegexp = /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/;
        if ( !this.state.pwd.match( pwdRegexp ) ) {
            this.props.changeSignupAttr( "errorMessage", "signup.validation.passwordstrength" );
            return false;
        }
        if ( this.props.pwSession.reCaptchaTokenNewClient === "" ) {
            // this.props.changeSignupAttr( "errorMessage", "generic.validation.recaptcha" );
            // return false;
        }
        return true;
    }
    isValidLoginForm () {
        if ( this.props.pwSession.userEmail === "" || this.state.pwd === "" ) {
            this.props.changeSignupAttr( "errorMessage", "generic.validation.empty" );
            return false;
        }
        if ( !isValidEmail( this.props.pwSession.userEmail ) ) {
            this.props.changeSignupAttr( "errorMessage", "generic.validation.invalidemail" );
            return false;
        }
        const pwdRegexp = /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/;
        if ( !this.state.pwd.match( pwdRegexp ) ) {
            this.props.changeSignupAttr( "errorMessage", "signup.validation.passwordstrength" );
            return false;
        }
        if ( this.props.pwSession.reCaptchaTokenNewClient === "" ) {
            // this.props.changeSignupAttr( "errorMessage", "generic.validation.recaptcha" );
            // return false;
        }
        return true;
    }
    signupSubmit( authProvider ) {
        this.props.changeSessionAttr( "authProvider", authProvider );
        if ( this.isValidSignupForm() ) {
            this.props.signupRequest( this.props.history, {
                webUserEmail: this.props.pwSession.userEmail,
                webUserAcceptNewsletter: this.props.pwSession.webUserAcceptNewsletter,
                acceptPrivacyPolicy: this.props.pwSession.acceptPrivacyPolicy,
                webUserPwd: this.state.pwd,
                reCaptchaTokenNewClient: this.props.pwSession.reCaptchaTokenNewClient,
                authProvider,
                fbSession: this.props.pwSession.fbSession,
                origin: this.origin
            } );
        }
    }
    loginSubmit( authProvider ) {
        this.props.changeSessionAttr( "authProvider", authProvider );
        if ( authProvider === "facebook" || this.isValidLoginForm() ) {
            this.props.loginRequest( this.props.history, {
                webUserEmail: this.props.pwSession.userEmail,
                webUserPwd: this.state.pwd,
                reCaptchaTokenNewClient: this.props.pwSession.reCaptchaTokenNewClient,
                authProvider,
                fbSession: this.props.pwSession.fbSession,
                origin: this.origin
            } );
        }
    }
    forgotSubmit() {
        if ( this.props.pwSession.userEmail === "" ) {
            return;
        }
        if ( !this.state.acceptForgotPrivacyPolicy ) {
            return;
        }
        if ( !isValidEmail( this.props.pwSession.userEmail ) ) {
            return;
        }
        if ( this.props.pwSession.reCaptchaTokenNewClient === "" ) {
            return;
        }
        this.props.forgotRequest( this.props.history, { webUserEmail: this.props.pwSession.userEmail, reCaptchaTokenNewClient: this.props.pwSession.reCaptchaTokenNewClient } );
    }
    resetSubmit() {
        if ( this.isValidResetForm() ) {
            this.props.resetRequest( this.props.history, { webUserPwd: this.state.pwd, reCaptchaTokenNewClient: this.props.pwSession.reCaptchaTokenNewClient, fc: this.fc } );
        }
    }
    checkEmailVerified() {
        if ( this.props.pwSession.userEmailVerified ) {
            if ( this.emailVerifiedIntervalId ) {
                clearInterval( this.emailVerifiedIntervalId );
            }
            if ( this.origin ) {
                this.setState( { emailVerifiedRedirectTo: Pages.getPage( this.origin ).relativeUrl } );
            } else {
                this.setState( { emailVerifiedRedirectTo: Pages.getPage( "dashboard" ).relativeUrl } );
            }
        } else {
            this.props.sessionRehydrateFromServer();
        }
    }
    renderTopComponent() {
        if ( !this.page.topComponent ) {
            return null;
        }
        const TopComponent = Pages.PageComponents[ this.page.topComponent ];
        return ( <TopComponent /> );
    }
    renderBottomComponent() {
        if ( !this.page.bottomComponent ) {
            return null;
        }
        const BottomComponent = Pages.PageComponents[ this.page.bottomComponent ];
        return ( <BottomComponent /> );
    }
    renderBottomDynamicContent() {
        if ( !this.page.bottomDynamicContent ) {
            return null;
        }
        return ( <GenericDynamicContent items={ this.page.bottomDynamicContent } /> );
    }
    renderTopDynamicContent() {
        if ( !this.page.topDynamicContent ) {
            return null;
        }
        return ( <GenericDynamicContent items={ this.page.topDynamicContent } /> );
    }
    renderCenteredModal() {
        if ( this.props.pwSession.signup.errorMessage === "" ) {
            return null;
        }
        return (
            <Modal
                show
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                variant="danger"
                className="pw_generic_error_modal"
            >
                <Modal.Body>
                    { Pages.text( this.context.language, this.props.pwSession.signup.errorMessage ) }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={ () => this.props.changeSignupAttr( "errorMessage", "" ) }>{ Pages.text( this.context.language, "generic.validation.close" ) }</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    renderSignupPassword() {
        const lang = this.context.language;
        if ( this.props.pwSession.signup.view !== "signuppassword" ) {
            return null;
        }
        return (
            <Card className="pw_signup_form">
                <Card.Body>
                    <Card.Title style={ Object.assign(
                        {},
                        Styles.GenericPage.SectionTitle, {
                            fontSize: "1.5em",
                            marginBottom: 0,
                            paddingBottom: "4px"
                        }
                    ) }
                    >
                        { Pages.text( lang, "signup.form.signuppassword.title" ) }
                    </Card.Title>
                    <Form>
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control disabled value={ this.props.pwSession.userEmail } onChange={ ( e ) => this.props.changeSessionAttr( "userEmail", e.target.value ) } type="email" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( lang, "signup.form.password" ) }:</Form.Label>
                            <Form.Control value={ this.state.pwd } onChange={ ( e ) => this.setState( { pwd: e.target.value } ) } type="password" />
                            <Form.Text className="text-muted">{ Pages.text( lang, "signup.password.hinttext" ) }</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( lang, "signup.form.passwordconfirmation" ) }:</Form.Label>
                            <Form.Control value={ this.state.confirmPwd } onChange={ ( e ) => this.setState( { confirmPwd: e.target.value } ) } type="password" />
                        </Form.Group>
                        <ReCAPTCHA
                            ref={ recaptchaRef }
                            sitekey={ Pages.config.reCaptchaSiteKey }
                            onChange={ ( token ) => this.props.changeSessionAttr( "reCaptchaTokenNewClient", token ) }
                        />
                        <Form.Check style={ { margin: "20px 0 10px 0" } }>
                            <Form.Check.Input
                                type="checkbox"
                                onClick={ () => {
                                    this.props.changeSessionAttr( "acceptPrivacyPolicy", !this.props.pwSession.acceptPrivacyPolicy );
                                } }
                            />
                            <Form.Check.Label>{ Pages.text( lang, "signup.form.readandacceptpolicy" ) } <a href={ Pages.getPage( "privacy-policy" ).relativeUrl } target="_blank" rel="noopener noreferrer">{ Pages.text( lang, "signup.form.privacypolicy" ) }</a></Form.Check.Label>
                        </Form.Check>
                        <Button onClick={ () => this.signupSubmit( this.props.pwSession.authProvider ) } disabled={ this.props.pwSession.signup.isLoading } variant="pw-primary">{ Pages.text( lang, "signup.form.signupbutton" ) }</Button>
                        <hr className="pw_hr_text" data-content={ Pages.text( lang, "signup.form.separator.text" ) } />
                        <div style={ { textAlign: "center" } }>
                            <Button onClick={ () => this.props.changeSignupAttr( "view", "login" ) } variant="secondary" style={ { marginRight: "5px" } }>{ Pages.text( lang, "signup.form.backloginbutton" ) }</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
    renderSignup() {
        const lang = this.context.language;
        if ( this.props.pwSession.signup.view !== "signup" ) {
            return null;
        }
        return (
            <Card className="pw_signup_form">
                <Card.Body>
                    <Card.Title style={ Object.assign(
                        {},
                        Styles.GenericPage.SectionTitle,
                        {
                            fontSize: "1.5em",
                            marginBottom: 0,
                            paddingBottom: "4px"
                        }
                    ) }
                    >
                        { Pages.text( lang, "signup.form.title" ) }
                    </Card.Title>
                    <Card.Text style={ { fontSize: "0.9em", fontStyle: "italic" } }>{ Pages.text( lang, "signup.form.subtitle" ) }</Card.Text>
                    { this.props.pwSession.loginErrorType === "facebookLoginError" ?
                        <Alert variant="danger">
                            { Pages.text( lang, "signup.form.facebook.login.error" ) }
                        </Alert>
                        : []
                    }
                    <Form>
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control value={ this.props.pwSession.userEmail } onChange={ ( e ) => this.props.changeSessionAttr( "userEmail", e.target.value ) } type="email" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( lang, "signup.form.password" ) }:</Form.Label>
                            <Form.Control value={ this.state.pwd } onChange={ ( e ) => this.setState( { pwd: e.target.value } ) } type="password" />
                            <Form.Text className="text-muted">{ Pages.text( lang, "signup.password.hinttext" ) }</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( lang, "signup.form.passwordconfirmation" ) }:</Form.Label>
                            <Form.Control value={ this.state.confirmPwd } onChange={ ( e ) => this.setState( { confirmPwd: e.target.value } ) } type="password" />
                        </Form.Group>
                        <ReCAPTCHA
                            ref={ recaptchaRef }
                            sitekey={ Pages.config.reCaptchaSiteKey }
                            onChange={ ( token ) => this.props.changeSessionAttr( "reCaptchaTokenNewClient", token ) }
                        />
                        { Pages.company.RGPDSignupCommercialNotificationsCheck ?
                            <Form.Group controlId="signupCommercialAccept">
                                <br />
                                <Form.Check type="checkbox" checked={ this.props.pwSession.webUserAcceptNewsletter } onChange={ () => this.props.changeSessionAttr( "webUserAcceptNewsletter", !this.props.pwSession.webUserAcceptNewsletter ) } label={ Pages.text( lang, "rgpd.footer.contact.acceptcommercial" ) } />
                            </Form.Group> : null }
                        <Form.Group controlId="signupPrivacyAccept">
                            <Form.Check style={ { margin: "20px 0 10px 0" } }>
                                <Form.Check.Input
                                    type="checkbox"
                                    checked={ this.props.pwSession.acceptPrivacyPolicy }
                                    onClick={ () => {
                                        this.props.changeSessionAttr( "acceptPrivacyPolicy", !this.props.pwSession.acceptPrivacyPolicy );
                                    } }
                                />
                                <Form.Check.Label>{ Pages.text( lang, "signup.form.readandacceptpolicy" ) } <a href={ Pages.getPage( "privacy-policy" ).relativeUrl } className="pw_legal_link" target="_blank" rel="noopener noreferrer">{ Pages.text( lang, "signup.form.privacypolicy" ) }</a></Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <Button onClick={ () => this.signupSubmit( "palmawebs" ) } disabled={ this.props.pwSession.signup.isLoading } variant="pw-primary">{ Pages.text( lang, "signup.form.signupbutton" ) }</Button>
                        { this.renderFacebookButton( "signup" ) }
                        { Pages.company.RGPDMessagesReady ?
                            <div className="pw_rgpd_form_box">
                                <ExpandCollapse
                                    key="footer-rgpd-expand"
                                    title={ <span>Cumplimos con el <b>RGPD</b> (Reglamento General de Protección de Datos). Clic aquí para más información acerca de este formulario.</span> }
                                    expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                    collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                >
                                    <div>
                                        <p><b>Responsable:</b> { Pages.company.lopdFullName }</p>
                                        <p><b>Finalidad:</b> Poder identificar a cada usuario de forma segura, mediante verificación a través de email, clave y confirmación a través de email (también conocido como doble opt-in) y así poder gestionar los diferentes pedidos, solicitudes, consultas y llevar un registro de los servicios vinculados a la cuenta del usuario.</p>
                                        <p><b>Legitimación:</b> Consentimiento del interesado.</p>
                                        <p><b>Destinatarios:</b>
                                            { Pages.company.RGPDAWSInfo ? <p><b>Amazon Web Services EMEA SARL</b>: Respecto al servicio de alojamiento de la web, usamos los servicios de Amazon Web Services, teniendo los datos alojados en centros de datos situados en la Unión Europea. Puedes consultar la política de privacidad de Amazon Web Services en el siguiente enlace <a href="https://aws.amazon.com/es/compliance/data-privacy-faq/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Privacidad de datos</a> o también puedes consultar su información acerca de RGPD en el siguiente enlace:<a href="https://aws.amazon.com/es/compliance/gdpr-center/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Reglamento General de Protección de Datos (RGPD)</a></p> : null }
                                            No se comunicarán datos a otros destinatarios, salvo obligación legal. La información es tratada con la máxima confidencialidad, siendo alojados en un servidor seguro. Tu dirección de correo electrónico se utilizará únicamente para dar respuesta a sus consultas realizadas a través de los formularios existentes en el sitio web, así como para informarle de las novedades.
                                        </p>
                                        <p><b>Derechos:</b> Tienes derecho a acceder, rectificar y suprimir tus datos, derechos que puedes ejercer enviando un correo electrónico a { Pages.company.lopdEmail }</p>
                                        <p>Puedes consultar la información adicional y detallada sobre protección de datos en el apartado <a href={ Pages.getPage( "privacy-policy" ).relativeUrl }>política de privacidad</a>.</p>
                                    </div>
                                </ExpandCollapse>
                            </div> : null }
                        <hr className="pw_hr_text" data-content={ Pages.text( lang, "signup.form.separator.text" ) } />
                        <div style={ { textAlign: "center" } }>
                            <Button onClick={ () => this.props.changeSignupAttr( "view", "login" ) } variant="secondary" style={ { marginRight: "5px" } }>{ Pages.text( lang, "signup.form.backloginbutton" ) }</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
    renderSignupSuccess() {
        if ( this.props.pwSession.signup.view !== "signupsuccess" ) {
            return null;
        }
        if ( !this.emailVerifiedIntervalId ) {
            this.emailVerifiedIntervalId = setInterval( this.checkEmailVerified, 5000 );
        }
        let btnDisabled = true;
        let btnText = "Verifica tu correo ...";
        if ( this.state.emailVerifiedRedirectTo ) {
            btnDisabled = false;
            btnText = "¡Correo verificado! Continuar";
        }
        return (
            <Card className="pw_signup_form">
                <Alert variant="success">
                    <Alert.Heading>{ Pages.text( this.context.language, "signup.success.title" ) }</Alert.Heading>
                    <p>
                        { Pages.text( this.context.language, "signup.success.text", { email: this.props.pwSession.userEmail } ) }
                    </p>

                    <Button variant="primary" href={ this.state.emailVerifiedRedirectTo } disabled={ btnDisabled }>
                        { btnDisabled ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : null }
                        <span>{ btnText }</span>
                    </Button>
                </Alert>
            </Card>
        );
    }
    renderFacebookButton() {
        return null;
    }
    renderResetPassword() {
        const lang = this.context.language;
        if ( this.props.pwSession.signup.view !== "reset" ) {
            return null;
        }
        return (
            <Card className="pw_login_form">
                <Card.Body>
                    <Card.Title style={ Object.assign(
                        {},
                        Styles.GenericPage.SectionTitle,
                        {
                            fontSize: "1.5em",
                            marginBottom: 0,
                            paddingBottom: "4px"
                        }
                    ) }
                    >
                        { Pages.text( lang, "signup.resetpassword.title" ) }
                    </Card.Title>
                    <Card.Text style={ { fontSize: "0.9em" } }>{ Pages.text( lang, "signup.resetpassword.subtitle" ) }</Card.Text>
                    <Form>
                        <Form.Group>
                            <Form.Label>{ Pages.text( lang, "signup.form.newpassword" ) }:</Form.Label>
                            <Form.Control value={ this.state.pwd } onChange={ ( e ) => this.setState( { pwd: e.target.value } ) } type="password" />
                            <Form.Text className="text-muted">{ Pages.text( lang, "signup.password.hinttext" ) }</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( lang, "signup.form.newpasswordconfirmation" ) }:</Form.Label>
                            <Form.Control value={ this.state.confirmPwd } onChange={ ( e ) => this.setState( { confirmPwd: e.target.value } ) } type="password" />
                        </Form.Group>
                        <ReCAPTCHA
                            ref={ recaptchaRef }
                            sitekey={ Pages.config.reCaptchaSiteKey }
                            onChange={ ( token ) => this.props.changeSessionAttr( "reCaptchaTokenNewClient", token ) }
                        />
                        <br />
                        <Button variant="pw-primary" onClick={ () => this.resetSubmit() }>{ Pages.text( lang, "signup.form.newpassword.save" ) }</Button>
                        <hr className="pw_hr_text" data-content={ Pages.text( lang, "signup.form.separator.text" ) } />
                        <div style={ { textAlign: "center" } }>
                            <Button onClick={ () => this.props.changeSignupAttr( "view", "login" ) } variant="secondary" style={ { marginRight: "5px" } }>{ Pages.text( lang, "signup.form.backloginbutton" ) }</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
    renderForgotPassword() {
        const lang = this.context.language;
        if ( this.props.pwSession.signup.view !== "forgot" ) {
            return null;
        }
        return (
            <Card className="pw_login_form">
                <Card.Body>
                    <Card.Title style={ Object.assign(
                        {},
                        Styles.GenericPage.SectionTitle,
                        {
                            fontSize: "1.5em",
                            marginBottom: 0,
                            paddingBottom: "4px"
                        }
                    ) }
                    >
                        { Pages.text( lang, "signup.forgotpassword.title" ) }
                    </Card.Title>
                    <Card.Text style={ { fontSize: "0.9em" } }>{ Pages.text( lang, "signup.forgotpassword.subtitle" ) }</Card.Text>
                    { this.props.pwSession.loginErrorType === "forgotPassword" ?
                        <Alert variant="danger">
                            { Pages.text( lang, "signup.forgotpassword.emailnotexists" ) }
                        </Alert>
                        : []
                    }
                    { this.props.pwSession.forgotSent ?
                        <Alert variant="success">
                            { Pages.text( lang, "signup.forgotpassword.pleasecheckemail" ) }
                        </Alert>
                        : []
                    }
                    <Form>
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control value={ this.props.pwSession.userEmail } onChange={ ( e ) => this.props.changeSessionAttr( "userEmail", e.target.value ) } type="email" />
                        </Form.Group>
                        <ReCAPTCHA
                            ref={ recaptchaRef }
                            sitekey={ Pages.config.reCaptchaSiteKey }
                            onChange={ ( token ) => this.props.changeSessionAttr( "reCaptchaTokenNewClient", token ) }
                        />
                        <Form.Group controlId="forgotPrivacyAccept">
                            <Form.Check style={ { margin: "20px 0 10px 0" } }>
                                <Form.Check.Input type="checkbox" onClick={ () => this.setState( { acceptForgotPrivacyPolicy: !this.state.acceptForgotPrivacyPolicy } ) } />
                                <Form.Check.Label>{ Pages.text( lang, "signup.form.readandacceptpolicy" ) } <a href={ Pages.getPage( "privacy-policy" ).relativeUrl } className="pw_legal_link" target="_blank" rel="noopener noreferrer">{ Pages.text( lang, "signup.form.privacypolicy" ) }</a></Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <br />
                        <Button variant="pw-primary" onClick={ () => this.forgotSubmit() }>{ Pages.text( lang, "signup.forgotpassword.processbutton" ) }</Button>
                        { Pages.company.RGPDMessagesReady ?
                            <div className="pw_rgpd_form_box">
                                <ExpandCollapse
                                    key="footer-rgpd-expand"
                                    title={ <span>Cumplimos con el <b>RGPD</b> (Reglamento General de Protección de Datos). Clic aquí para más información acerca de este formulario.</span> }
                                    expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                    collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                >
                                    <div>
                                        <p><b>Responsable:</b> { Pages.company.lopdFullName }</p>
                                        <p><b>Finalidad:</b> Poder dar al usuario la posibilidad de restaurar su clave en caso de olvido de forma segura a través de su correo electrónico.</p>
                                        <p><b>Legitimación:</b> Consentimiento del interesado.</p>
                                        <p><b>Destinatarios:</b>
                                            { Pages.company.RGPDAWSInfo ? <p><b>Amazon Web Services EMEA SARL</b>: Respecto al servicio de alojamiento de la web, usamos los servicios de Amazon Web Services, teniendo los datos alojados en centros de datos situados en la Unión Europea. Puedes consultar la política de privacidad de Amazon Web Services en el siguiente enlace <a href="https://aws.amazon.com/es/compliance/data-privacy-faq/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Privacidad de datos</a> o también puedes consultar su información acerca de RGPD en el siguiente enlace:<a href="https://aws.amazon.com/es/compliance/gdpr-center/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Reglamento General de Protección de Datos (RGPD)</a></p> : null }
                                            No se comunicarán datos a otros destinatarios, salvo obligación legal. La información es tratada con la máxima confidencialidad, siendo utilizada para consulta en un servidor seguro. Tu dirección de correo electrónico se utilizará únicamente para dar respuesta a sus consultas realizadas a través de los formularios existentes en el sitio web.
                                        </p>
                                        <p><b>Derechos:</b> Tienes derecho a acceder, rectificar y suprimir tus datos, derechos que puedes ejercer enviando un correo electrónico a { Pages.company.lopdEmail }</p>
                                        <p>Puedes consultar la información adicional y detallada sobre protección de datos en el apartado <a href={ Pages.getPage( "privacy-policy" ).relativeUrl }>política de privacidad</a>.</p>
                                    </div>
                                </ExpandCollapse>
                            </div> : null }
                        <hr className="pw_hr_text" data-content={ Pages.text( lang, "signup.form.separator.text" ) } />
                        <div style={ { textAlign: "center" } }>
                            <Button onClick={ () => this.props.changeSignupAttr( "view", "login" ) } variant="secondary" style={ { marginRight: "5px" } }>{ Pages.text( lang, "signup.form.backloginbutton" ) }</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
    renderLogin() {
        const lang = this.context.language;
        if ( this.props.pwSession.signup.view !== "login" ) {
            return null;
        }
        return (
            <Card className="pw_login_form">
                <Card.Body>
                    { Pages.text( lang, "login.form.title" ) ?
                        <Card.Title style={ Object.assign(
                            {},
                            Styles.GenericPage.SectionTitle,
                            {
                                fontSize: "1.5em",
                                marginBottom: 0,
                                paddingBottom: "4px"
                            }
                        ) }
                        >
                            { Pages.text( lang, "login.form.title" ) }
                        </Card.Title> : null
                    }
                    { Pages.text( lang, "login.form.subtitle" ) ? <Card.Text style={ { fontSize: "0.9em", fontStyle: "italic" } }>{ Pages.text( lang, "login.form.subtitle" ) }</Card.Text> : null }
                    { this.props.pwSession.loginErrorType === "facebookLoginError" ?
                        <Alert variant="danger">
                            { Pages.text( lang, "signup.form.facebook.login.error" ) }
                        </Alert>
                        : []
                    }
                    { this.props.pwSession.resetDone ?
                        <Alert variant="success">
                            { Pages.text( lang, "signup.login.newpasswordsaved" ) }
                        </Alert>
                        : []
                    }
                    <Form>
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control value={ this.props.pwSession.userEmail } onChange={ ( e ) => this.props.changeSessionAttr( "userEmail", e.target.value ) } type="email" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{ Pages.text( lang, "signup.form.password" ) }:</Form.Label>
                            <Form.Control value={ this.state.pwd } onChange={ ( e ) => this.setState( { pwd: e.target.value } ) } type="password" />
                        </Form.Group>
                        <ReCAPTCHA
                            ref={ recaptchaRef }
                            sitekey={ Pages.config.reCaptchaSiteKey }
                            onChange={ ( token ) => this.props.changeSessionAttr( "reCaptchaTokenNewClient", token ) }
                        />
                        <br />
                        <Button variant="pw-primary" onClick={ () => this.loginSubmit( "palmawebs" ) }>{ Pages.text( lang, "login.form.loginbutton" ) }</Button>
                        { this.renderFacebookButton( "login" ) }
                        { Pages.company.RGPDMessagesReady ?
                            <div className="pw_rgpd_form_box">
                                <ExpandCollapse
                                    key="footer-rgpd-expand"
                                    title={ <span>Cumplimos con el <b>RGPD</b> (Reglamento General de Protección de Datos). Clic aquí para más información acerca de este formulario.</span> }
                                    expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                    collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                >
                                    <div>
                                        <p><b>Responsable:</b> { Pages.company.lopdFullName }</p>
                                        <p><b>Finalidad:</b> Poder identificar a cada usuario de forma segura a través del uso de sus credenciales de acceso (Email y Clave de usuario) y así poder gestionar los diferentes pedidos, solicitudes, consultas y llevar un registro de los servicios vinculados a la cuenta del usuario.</p>
                                        <p><b>Legitimación:</b> Consentimiento del interesado al momento de crear su cuenta.</p>
                                        <p><b>Destinatarios:</b>
                                            { Pages.company.RGPDAWSInfo ? <p><b>Amazon Web Services EMEA SARL</b>: Respecto al servicio de alojamiento de la web, usamos los servicios de Amazon Web Services, teniendo los datos alojados en centros de datos situados en la Unión Europea. Puedes consultar la política de privacidad de Amazon Web Services en el siguiente enlace <a href="https://aws.amazon.com/es/compliance/data-privacy-faq/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Privacidad de datos</a> o también puedes consultar su información acerca de RGPD en el siguiente enlace:<a href="https://aws.amazon.com/es/compliance/gdpr-center/" rel="noopener noreferrer" target="_blank" className="pw_legal_link">AWS - Reglamento General de Protección de Datos (RGPD)</a></p> : null }
                                            No se comunicarán datos a terceros, salvo obligación legal. La información es tratada con la máxima confidencialidad, siendo utilizada para verificar tu cuenta en un servidor seguro. Tu dirección de correo electrónico se utilizará únicamente para dar respuesta a sus consultas realizadas a través de los formularios existentes en el sitio web.
                                        </p>
                                        <p><b>Derechos:</b> Tienes derecho a acceder, rectificar y suprimir tus datos, derechos que puedes ejercer enviando un correo electrónico a { Pages.company.lopdEmail }</p>
                                        <p>Puedes consultar la información adicional y detallada sobre protección de datos en el apartado <a href={ Pages.getPage( "privacy-policy" ).relativeUrl }>política de privacidad</a>.</p>
                                    </div>
                                </ExpandCollapse>
                            </div> : null }
                        <br />
                        <Button variant="outline-secondary" onClick={ () => this.props.changeSignupAttr( "view", "forgot" ) }>{ Pages.text( lang, "signup.form.forgotpasswordlink" ) }</Button>
                        <hr className="pw_hr_text" data-content={ Pages.text( lang, "login.form.separator.text" ) } />
                        <div style={ { textAlign: "center" } }>
                            <Button onClick={ () => this.props.changeSignupAttr( "view", "signup" ) } variant="secondary" style={ { marginRight: "5px" } }>{ Pages.text( lang, "login.form.signupbutton" ) }</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
    renderEmailVerified() {
        if ( this.props.pwSession.signup.view !== "emailverified" ) {
            return null;
        }
        if ( this.urlParams.verified && this.urlParams.e ) {
            let redirectTo = null;
            if ( this.origin ) {
                const redirectToPage = Pages.getPage( this.origin );
                if ( redirectToPage ) {
                    redirectTo = redirectToPage.relativeUrl;
                    // check if cart has items in this browser before display the redirect
                    if ( this.origin === "shop-cart-checkout" && this.props.pwSession.shop.cartItems && this.props.pwSession.shop.cartItems.length === 0 ) {
                        redirectTo = null;
                    }
                }
            }
            return (
                <Card className="pw_login_form">
                    <Card.Body>
                        <Alert variant="success">
                            <Alert.Heading>{ Pages.text( this.context.language, "signup.emailverified.success" ) }</Alert.Heading>
                            { redirectTo ?
                                <div>
                                    <p>
                                        { Pages.text( this.context.language, "signup.emailverified.textclicktocontinue" ) } ...
                                    </p>
                                    <p>
                                        <Button variant="primary" href={ redirectTo }>
                                            <IoIosArrowForward style={ { color: "white" } } /> <span>{ Pages.text( this.context.language, "signup.emailverified.continue" ) }</span>
                                        </Button>
                                    </p>
                                </div>
                                : null }
                        </Alert>
                    </Card.Body>
                </Card>
            );
        }
        return null;
    }
    render() {
        return (
            <div id="pw_signup_login" className={ Pages.getPageClassNames( this.page ) }>
                { this.page.title === "" ? null : <h1 style={ Styles.GenericPage.SectionTitle }>{ this.page.title }</h1> }
                { this.page.subTitle === "" ? null : <h3 style={ Styles.GenericPage.SectionSubTitle }>{ this.page.subTitle }</h3> }
                { this.renderTopComponent() }
                { this.renderTopDynamicContent() }
                { this.renderEmailVerified() }
                { this.renderSignup() }
                { this.renderLogin() }
                { this.renderSignupPassword() }
                { this.renderSignupSuccess() }
                { this.renderForgotPassword() }
                { this.renderResetPassword() }
                { this.renderBottomComponent() }
                { this.renderBottomDynamicContent() }
                { this.renderCenteredModal() }
            </div>
        );
    }
}

GenericSignup.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToProps = Object.assign( {}, SessionActions, { loadUIContext } );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( GenericSignup ) );
